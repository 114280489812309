import axiosInstance from "../../../api/axiosConfig";
import { logout } from "../../../api/services/authServices";

const getCardsAndGoalStats = async (category='U13') => {
  let url = `stats/per-game/${category}`;
  try {
    return await axiosInstance.get(url);
  } catch (error) {
    if (error.response.status === 401) {
      return logout();
    }
    throw new Error(error);
  }
};
const getLastResultStats = async (category='U13') => {
  let url = `stats/latest-results/${category}`;
  try {
    return await axiosInstance.get(url);
  } catch (error) {
    if (error.response.status === 401) {
      return logout();
    }
    throw new Error(error);
  }
};
const getGoalScorersForTeamStats = async (category='U13') => {
  let url = `stats/team-goalscorers/${category}`;
  try {
    return await axiosInstance.get(url);
  } catch (error) {
    if (error.response.status === 401) {
      return logout();
    }
    throw new Error(error);
  }
};
const getPointHistoryStats = async (category='U13') => {
  let url = `stats/points-history/${category}`;
  try {
    return await axiosInstance.get(url);
  } catch (error) {
    console.log("Point History Error: ", error);
    if (error.response.status === 401) {
      return logout();
    }
    throw new Error(error);
  }
};
const getGamesHistoryStats = async (category='U13') => {
  let url = `stats/games-history/${category}`;
  try {
    return await axiosInstance.get(url);
  } catch (error) {
    if (error.response.status === 401) {
      return logout();
    }
    throw new Error(error);
  }
};

export {
  getCardsAndGoalStats,
  getLastResultStats,
  getGoalScorersForTeamStats,
  getPointHistoryStats,
  getGamesHistoryStats
};

import React, { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { Alert } from "@mui/material";
import {
  validateEmail,
  validatePassword,
} from "../../utils/validation/validation";
import LanguageSelects from "../selects/LanguageSelects";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const Login = () => {
  const { t } = useTranslation();
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [displayPassword, setDisplayPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError(t("MESSAGES_ERROR.EMAIL_INVALID"));
      return;
    }
    // if (!validatePassword(password)) {
    //   setError(t("MESSAGES_ERROR.PASSWORD_INVALID"));
    //   return;
    // }
    try {
      await login(email, password);
      navigate("/matches");
    } catch (error) {
      setError(t("MESSAGES_ERROR." + error?.message));
    }
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center bg-gray-100"
      style={{
        backgroundImage: "url('../../assets/images/arbiters.png')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        className="bg-white p-8 rounded-2xl shadow-lg w-full max-w-md"
        style={{ boxShadow: "0 4px 16px gray, 0 1px 3px gray" }}
      >
        <div className="flex justify-center mb-8">
          <img
            src="../../assets/images/logos/myteamConnect.png"
            alt="Logo"
            className="h-16"
          />
        </div>
        <form onSubmit={handleSubmit}>
          {error && (
            <Alert
              severity="error"
              style={{
                marginBottom: 20,
              }}
            >
              {error}
            </Alert>
          )}
          <div className="mb-4">
            <label
              className="block font-myteam  text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              {t("EMAIL")}
            </label>
            <input
              type="email"
              style={{
                backgroundColor: "#fafafa",
              }}
              id="email"
              className="shadow font-myteam appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder={t("EMAIL_PLACEHOLDER")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label
              className="block font-myteam text-gray-700 text-sm font-bold mb-2"
              htmlFor="password"
            >
              {t("PASSWORD")}
            </label>
            <div
              style={{
                position: "relative",
              }}
            >
              <input
                type={!displayPassword ? "password" : "text"}
                id="password"
                style={{
                  backgroundColor: "#fafafa",
                }}
                className="shadow font-myteam appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                placeholder={t("PASSWORD_PLACEHOLDER")}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div
                style={{
                  position: "absolute",
                  right: 10,
                  top: 5,
                  cursor: "pointer",
                }}
              >
                {displayPassword ? (
                  <VisibilityOffIcon
                    color="primary"
                    onClick={() => setDisplayPassword(!displayPassword)}
                  />
                ) : (
                  <VisibilityIcon
                    color="primary"
                    onClick={() => setDisplayPassword(!displayPassword)}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="bg-blue-500 font-myteam hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              {t("SE_CONNECTER")}
            </button>
            <LanguageSelects />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;

import {
  ADD_EVENT,
  ADD_EVENT_TO_STATE,
  DELETE_EVENT,
  REMOVE_EVENT_FROM_STATE,
  SEND_SERVER_FAILED,
  SEND_SERVER_REQUEST,
  SEND_SERVER_SUCCESS,
  REMOVE_ENVENTS,
  ADD_EVENTS_HISTORY
} from "../actions/matchEventsActions";
import { loadStateFromLocalStorage } from "../selectors/matchSelectors";

const initialState = {
  events: [],
  loading: false,
  error: null,
};

const persistedState = loadStateFromLocalStorage("matchEventsSatate");

const matchEventsReducer = (state = persistedState || initialState, action) => {
  switch (action.type) {
    case REMOVE_ENVENTS:
      return {
        events: [],
        loading: false,
        error: null,
      };
    case ADD_EVENT:
      return {
        ...state,
        events: [...state.events, action.payload],
      };
    case ADD_EVENTS_HISTORY:
      return {
        ...state,
        events: action.payload,
      };
    case DELETE_EVENT:
      return {
        ...state,
        events: state.events.filter((event) => event == action.payload),
      };
    case ADD_EVENT_TO_STATE: {
      const { eventName, ...rest } = action.payload;

      if (eventName === "match_stats") {
        return {
          ...state,
          events: [
            ...state.events.filter(
              (event) => event.eventName !== "match_stats"
            ),
            action.payload,
          ],
        };
      } else {
        return {
          ...state,
          events: [...state.events, action.payload],
        };
      }
    }

    // case ADD_EVENT_TO_STATE:
    //   return {
    //     ...state,
    //     events: [...state.events, action.payload],
    //   };

    case REMOVE_EVENT_FROM_STATE:
      return {
        ...state,
        events: state?.events.filter((event) => event != action.payload),
      };
    case SEND_SERVER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SEND_SERVER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SEND_SERVER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default matchEventsReducer;

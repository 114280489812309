import React, { useContext } from 'react';
import LineupTable from '../DataTables/LineupTable';
import { MobileContext } from '../../context/isMobileContext';

export default function Overview({ events, match }) {
    const isMobile = useContext(MobileContext);

    const teamHomeEvent = events.find(event => event.equipe_id === match?.equipeHome?.id);
    const teamAwayEvent = events.find(event => event.equipe_id === match?.equipeAway?.id);

    return (
        <div className="my-5">
            <div className={`flex justify-center ${isMobile ? "flex-wrap" : ""}`}>
                <div className={`mx-2 ${isMobile ? "flex-wrap w-full" : "w-1/2"}`}>
                    <LineupTable 
                        players={teamHomeEvent?.ListPlayer || []}
                        substitutes={teamHomeEvent?.ListReplacment || []}
                        team={match?.equipeHome} 
                    />
                </div>
                <div className={`mx-2 ${isMobile ? "flex-wrap w-full" : "w-1/2"}`}>
                    <LineupTable 
                        players={teamAwayEvent?.ListPlayer || []}
                        substitutes={teamAwayEvent?.ListReplacment || []}
                        team={match?.equipeAway} 
                    />
                </div>
            </div>
        </div>
    );
}

import axiosInstance from "../axiosConfig";

const API_BASE_URL = process.env.REACT_APP_API_URL;
const API_VERSION = process.env.REACT_APP_API_VERSION;
const MATCH_ENDPOINT = process.env.REACT_APP_API_ENDPOINT_MATCH;
const EVENTS_ENDPOINT = `${API_BASE_URL}${API_VERSION}${MATCH_ENDPOINT}add-events`;

const matchEventService = {
  addEvent: async (eventData) => {
    try {
      const response = await axiosInstance.post(EVENTS_ENDPOINT, eventData);
      //console.log(response.data)
      const addedEvent = await response.data;
      return addedEvent;
    } catch (error) {
      throw new Error(error?.message);
    }
  },

};

export default matchEventService;

import React, { useContext, useEffect, useState } from "react";
import BasicTabs from "../layouts/BasicTabs";
import Rencontre from "../sections/signateurTabs/Rencontre";
import Compositions from "../sections/signateurTabs/Compositions";
import Signatures from "../sections/signateurTabs/Signatures";
import GameHistory from "../sections/signateurTabs/GameHistory";
import { useSelector } from "react-redux";
import matchEventService from "../../api/services/matchEventService";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";

export default function SignateurAfterMatch({
  setOpen,
  t,
  teamRecevant,
  signatures,
  handleSignatureUpdate,
  teamVisiteuse,
  matchId,
  isArbiture,
  type,
  match,
  equipeAwayScoore,
  equipeHomeScoore,
}) {
  const navigate = useNavigate();
  const events = useSelector((state) => state.matchEventsReducer.events);
  const [activeTab, setActiveTab] = useState(0);
  const [allSigned, setAllSigned] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(AuthContext);
  const isTeamHome = user?.equipe_id == teamRecevant?.id;
  const matchHasArtbiter = useSelector(
    (state) => state.matchReducer.matchHasArtbiter
  );

  const tabs = [
    {
      label: t("RENCONTRE"),
      content: (
        <Rencontre
          match={match}
          equipeAwayScoore={equipeAwayScoore}
          equipeHomeScoore={equipeHomeScoore}
          t={t}
          setActiveTab={setActiveTab}
        />
      ),
    },
    {
      label: t("COMPOSITIONS"),
      content: (
        <Compositions
          teamRecevant={teamRecevant}
          teamVisiteuse={teamVisiteuse}
        />
      ),
    },
    {
      label: t("FAITE_DU_JEU"),
      content: <GameHistory />,
    },
    {
      label: t("SIGNATURES"),
      content: (
        <Signatures
          setOpen={setOpen}
          t={t}
          teamRecevant={teamRecevant}
          signatures={signatures}
          handleSignatureUpdate={handleSignatureUpdate}
          matchId={matchId}
          teamVisiteuse={teamVisiteuse}
          isArbiture={isArbiture}
          type={type}
          matchHasArtbiter={matchHasArtbiter}
          isTeamHome={isTeamHome}
        />
      ),
    },
  ];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (
      (signatures?.teamRecevant &&
        signatures?.arbiture &&
        signatures?.teamVisiteuse) ||
      (signatures?.arbiture && isTeamHome && !matchHasArtbiter)
    ) {
      setAllSigned(true);
    }
  }, [signatures]);

  const handleSubmit = async () => {
    const getPeriod = (minute, extraTime) => {
      if (minute <= 45 || (minute === 45 && extraTime > 0)) {
        return "1";
      } else {
        return "2";
      }
    };

    const matchStatsEvent = events.find(
      (event) => event.eventName === "match_stats"
    );

    const matchEventData = {
      match: matchId,
      carts: events
        .filter((event) => event.eventName === "discipline")
        .map((event) => ({
          type: event.cardDiscipline,
          minute: event.RegularTime,
          period: getPeriod(event.RegularTime, event.extraTime),
          extra_time: event.extraTime,
          joueur_id: event.pers[0].id,
          description: event.description || "",
          motif_id: event.Motif,
          equipe: event?.team.id,
        })),
      blessures: events
        .filter((event) => event.eventName === "injury")
        .map((event) => ({
          joueur_id: event.injuredPlayer.id,
          minute: event.RegularTime,
          period: getPeriod(event.RegularTime, event.extraTime),
          extra_time: event.extraTime,
          equipe: event?.team.id,
          description: "",
          reason: event?.reason,
          position: event?.position,
        })),
      changements: events
        .filter((event) => event.eventName === "subs")
        .map((event) => ({
          joueur_id_in: event?.playerIn.id,
          joueur_id_out: event?.playerOut.id,
          minute: event?.RegularTime,
          period: getPeriod(event?.RegularTime, event.extraTime),
          extra_time: event?.extraTime,
          equipe: event?.team.id,
        })),
      buts: events
        .filter((event) => event.eventName === "goalScorer")
        .map((event) => ({
          joueur_id: event.goalScorer?.id,
          joueur_assist_id: event.assistPlayer?.id,
          minute: event.RegularTime,
          period: getPeriod(event.RegularTime, event.extraTime),
          extra_time: event.extraTime,
          equipe: event?.team.id,
          type: event.type,
        })),
      status: matchStatsEvent ? matchStatsEvent.status : {},
      match_stats: matchStatsEvent ? matchStatsEvent.match_stats : {},
      man_of_match: matchStatsEvent?.man_of_match
        ? {
            joueur_id: matchStatsEvent.man_of_match?.id,
            equipe_id: matchStatsEvent?.man_of_match?.teamId,
          }
        : {},
      equipeHomeId: teamRecevant?.id,
      equipeAwayId: teamVisiteuse?.id,
    };
    try {
      await matchEventService.addEvent(matchEventData);
      const variant = "success";
      enqueueSnackbar(t("MATCH_FORM_SENT"), { variant });
      navigate("/matches");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <button
          className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-400 focus:outline-none transition duration-300"
          onClick={() => setOpen(false)}
        >
          {t("CLOSE")}
        </button>
        {allSigned && (
          <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-400 focus:outline-none transition duration-300"
          >
            {t("SEND_FORM_MATCH")}
          </button>
        )}
      </div>
      <BasicTabs
        tabs={tabs}
        onTabChange={handleTabChange}
        activeTab={activeTab}
      />
    </div>
  );
}

import axiosInstance from "../axiosConfig";

export const fetchReservesTemplate = async (category, type) => {
  try {
    const response = await axiosInstance.get(
      `${
        process.env.REACT_APP_API_ENDPOINT_RESERVE
      }load-reserves?category=${category}${type && "&type=" + type}`
    );
    return response?.data;
  } catch (error) {
    throw new Error("Failed to fetch reserves template");
  }
};
export const createReservesTemplate = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_API_ENDPOINT_RESERVE}create`,
      payload
    );
    return response?.status;
  } catch (error) {
    throw new Error("Failed to fetch reserves template");
  }
};

import * as React from "react";
const IconPlayerOut = (props) => (
  <svg
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 8.5 13"
    style={{
      enableBackground: "new 0 0 8.5 13",
      width: props.w,
    }}
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path
        fill="#C51B34"
        className="st0"
        d="M4.1,12.3C4,12.1,3.9,12,3.8,11.8c0,0,0,0,0,0c0.3-0.4,0.6-0.8,1-1.2c0.5-0.6,1-1.2,1.5-1.9 c0.1-0.2,0.1-0.2,0-0.3c0.3,0,0.5,0,0.8,0c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.1,0,0.2c-0.4,0.6-0.9,1.1-1.3,1.7c-0.5,0.6-1,1.2-1.5,1.8 c0,0.1-0.1,0.1-0.1,0.1C4.2,12.3,4.2,12.3,4.1,12.3z"
      />
      <path
        fill="#E02437"
        className="st1"
        d="M6.2,8.3c0.1,0.2,0.1,0.2,0,0.3c-0.5,0.6-1,1.2-1.5,1.9c-0.3,0.4-0.6,0.8-1,1.2c0,0,0,0,0,0 C3,10.7,2.2,9.7,1.4,8.6c0-0.1-0.1-0.1,0-0.2c0-0.1,0.1-0.1,0.2-0.1c0.5,0,1.1,0,1.6,0c0.1,0,0.1,0,0.1-0.1c0-1.3,0-2.6,0-3.9 c0-0.6,0.1-1.1,0.4-1.6c0.3-0.5,0.7-0.9,1.3-1.2c0.1,0,0.2-0.1,0.3-0.1c0,0.1,0,0.1-0.1,0.1C5,1.7,4.8,1.9,4.7,2.1 c-0.3,0.6-0.5,1.3-0.5,2c0,1,0,2.1,0,3.1c0,0.2,0,0.3,0,0.5c0,0.3,0.2,0.5,0.5,0.5c0.1,0,0.3,0,0.4,0C5.5,8.3,5.9,8.3,6.2,8.3z"
      />
      <path
        fill="#C51A33"
        className="st2"
        d="M5.2,8.3c-0.1,0-0.3,0-0.4,0C4.5,8.3,4.3,8.1,4.3,7.8c0-0.2,0-0.3,0-0.5c0-1,0-2.1,0-3.1c0-0.7,0.1-1.4,0.5-2 C4.8,1.9,5,1.7,5.1,1.5c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.1,0.5-0.2,0.7-0.2c0.1,0,0.2,0,0.2,0.1 c0,0.1,0,0.2,0,0.2C5.8,1.8,5.6,2.2,5.4,2.7c-0.2,0.5-0.2,1-0.2,1.5C5.2,5.6,5.2,7,5.2,8.3z"
      />
    </g>
  </svg>
);
export default IconPlayerOut;

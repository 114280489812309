// import React from "react";
// import { Routes, Route } from "react-router-dom";
// import { Provider } from "react-redux";
// import storeRedux from "./redux/store";
// import { useTranslation } from "react-i18next";
// import Layout from "./pages/Layout";
// import BackOfficeMain from "./dashboard/src/main";
// import FrontOfficeRoutes from "./routes/FrontOfficeRoutes";

// function App() {
//   const { i18n } = useTranslation();
//   return (
//     <div dir={i18n.language === "ar" ? "rtl" : "ltr"}>
//       <Provider store={storeRedux}>
//         <Layout>
//           <Routes>
//             <Route path="*" element={<FrontOfficeRoutes />} />
//             <Route path="/dashboard/*" element={<BackOfficeMain />} />
//           </Routes>
//         </Layout>
//       </Provider>
//     </div>
//   );
// }

// export default App;

// App.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import storeRedux from "./redux/store";
import { AuthProvider } from "./contexts/AuthContext";
import { useTranslation } from "react-i18next";
import Layout from "./pages/Layout";
import BackOfficeMain from "./dashboard/src/main";
import FrontOfficeRoutes from "./routes/FrontOfficeRoutes";
import RoleBasedRoute from "./routes/RoleBasedRoute";
import UnauthorizedPage from "./components/Errors/pages/UnauthorizedPage";
import { MobileProvider } from "./dashboard/src/context/isMobileContext";

function App() {
  const { i18n } = useTranslation();

  return (
    <div dir={i18n.language === "ar" ? "rtl" : "ltr"}>
      <Provider store={storeRedux}>
        <MobileProvider>
          <AuthProvider>
            <Layout>
              <Routes>
                <Route
                  element={
                    <RoleBasedRoute
                      allowedRoles={["club", "dtn", "owner"]}
                      redirectPath="/"
                    />
                  }
                >
                  <Route path="/dashboard/*" element={<BackOfficeMain />} />
                </Route>
                <Route path="*" element={<FrontOfficeRoutes />} />
                <Route path="/unauthorized" element={<UnauthorizedPage />} />
              </Routes>
            </Layout>
          </AuthProvider>
        </MobileProvider>
      </Provider>
    </div>
  );
}

export default App;

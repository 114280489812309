import {
    TOGGLE_MENU,
    TOGGLE_LAYOUT,
    TOGGLE_NAVBAR,
    TOGGLE_SIDEBAR,
    SET_PAGE_TITLE,
} from '../actions/backOfficeActions';

const defaultState = {
    isDarkMode: false,
    mainLayout: 'app',
    theme: 'dark',
    menu: 'vertical',
    layout: 'full',
    rtlClass: 'ltr',
    animation: '',
    navbar: 'navbar-sticky',
    locale: 'en',
    sidebar: false,
    pageTitle: '',
    languageList: [
        { code: 'zh', name: 'Chinese' },
        { code: 'da', name: 'Danish' },
        { code: 'en', name: 'English' },
        { code: 'fr', name: 'French' },
        { code: 'de', name: 'German' },
        { code: 'el', name: 'Greek' },
        { code: 'hu', name: 'Hungarian' },
        { code: 'it', name: 'Italian' },
        { code: 'ja', name: 'Japanese' },
        { code: 'pl', name: 'Polish' },
        { code: 'pt', name: 'Portuguese' },
        { code: 'ru', name: 'Russian' },
        { code: 'es', name: 'Spanish' },
        { code: 'sv', name: 'Swedish' },
        { code: 'tr', name: 'Turkish' },
    ],
    semidark: false,
};

const initialState = {
    menu: localStorage.getItem('menu') || defaultState.menu,
    layout: localStorage.getItem('layout') || defaultState.layout,
    navbar: localStorage.getItem('navbar') || defaultState.navbar,
    isDarkMode: false,
    sidebar: localStorage.getItem('sidebar') || defaultState.sidebar,
};

const themeConfigReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_MENU:
            const newMenu = action.payload || state.menu;
            localStorage.setItem('menu', newMenu);
            return {
                ...state,
                menu: newMenu,
                sidebar: false, // reset sidebar state
            };
        case TOGGLE_LAYOUT:
            const newLayout = action.payload || state.layout;
            localStorage.setItem('layout', newLayout);
            return {
                ...state,
                layout: newLayout,
            };
        case TOGGLE_NAVBAR:
            const newNavbar = action.payload || state.navbar;
            localStorage.setItem('navbar', newNavbar);
            return {
                ...state,
                navbar: newNavbar,
            };
        case TOGGLE_SIDEBAR:
            return {
                ...state,
                sidebar: !state.sidebar,
            };
        case SET_PAGE_TITLE:
            document.title = `${action.payload} | MyTeam`;
            return state; // No state change here, just side effect
        default:
            return state;
    }
};

export default themeConfigReducer;

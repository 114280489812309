import React, { useContext, useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Avatar,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  getMatchWaitingConfirmation,
  refusMatchWaitingConfirmation,
} from "../dashboard/src/service/matcheService";
import Loading from "../components/Loadings/Loading";
import LineupTable from "../dashboard/src/components/DataTables/LineupTable";
import { useTranslation } from "react-i18next";
import Signature from "../components/Forms/signature/Signature";
import { AuthContext } from "../contexts/AuthContext";
import { useSnackbar } from "notistack";

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
}));

const TeamLogo = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(5),
  height: theme.spacing(5),
  margin: "auto",
}));

const ScoreTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "2rem",
  textAlign: "center",
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "1rem",
  textAlign: "center",
}));

const MatchEventsConfirmationPage = ({
  matchSelected,
  onClose,
  setCurrentData,
  reload,
}) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [openRefuseModal, setOpenRefuseModal] = useState(false);
  const [matchData, setMatchData] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [signature, setSignature] = useState("");
  const [refusalReason, setRefusalReason] = useState("");
  const [eventsHome, setEventsHome] = useState("");
  const [eventsAway, setEventsAway] = useState("");
  const [teamHome, setTeamHome] = useState("");
  const [teamAway, setTeamAway] = useState("");
  const [error, setError] = useState("");

  const handleAccept = () => setOpenAcceptModal(true);
  const handleRefuse = () => setOpenRefuseModal(true);

  const handleCloseAcceptModal = () => {
    setOpenAcceptModal(false);
    setSignature("");
  };
  const handleCloseRefuseModal = () => {
    setOpenRefuseModal(false);
    setRefusalReason("");
    setError("");
  };

  const handleConfirmRefusal = async () => {
    try {
      if (refusalReason === "") {
        return setError(t("REFUSAL_REASON_REQUIRED"));
      }
      const body = {
        matchId: matchSelected?.id,
        comment: refusalReason,
      };
      const refusMatch = await refusMatchWaitingConfirmation(body);
      if (refusMatch?.status === 200) {
        enqueueSnackbar({
          message: t("MATCH_REFUSED_WITH_SUCCESS"),
          variant: "success",
        });
        onClose();
      }
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  const handleSignatureUpdate = (role, isSigned) => {
    if (isSigned) {
      onClose();
      reload();
      enqueueSnackbar({
        message: t("MATCH_ACCEPTED_WITH_SUCCESS"),
        variant: "success",
      });
    }
    setSignature((prevSignatures) => ({
      ...prevSignatures,
      [role]: isSigned,
    }));
  };

  useEffect(() => {
    fetchMatchWaitingConfirmation(matchSelected?.id || matchSelected.match?.id);
  }, []);

  const fetchMatchWaitingConfirmation = async (id) => {
    try {
      setLoading(true);
      const response = await getMatchWaitingConfirmation(id);
      const data = response?.data?.match;
      if (response?.data.events?.length === 0) {
        onClose();
      }
      setEventsHome(
        response?.data.events[0].equipe_id === data?.equipeHome?.id
          ? response?.data.events[0]
          : response?.data.events[1]
      );
      setEventsAway(
        response?.data.events[0].equipe_id === data?.equipeAway?.id
          ? response?.data.events[0]
          : response?.data.events[1]
      );

      setTeamHome(data?.equipeHome);
      setTeamAway(data?.equipeAway);
      setMatchData(data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6">
            {t("MATCH_WAITING_CONFIRMATION")}
          </Typography>
        </Toolbar>
      </AppBar>

      <div className="container mx-auto py-8">
        <StyledCard>
          {!loading ? (
            <CardContent>
              <Typography variant="h5" gutterBottom align="center">
                {matchData?.Competition.name}
              </Typography>
              <Typography variant="subtitle1" gutterBottom align="center">
                {matchData.match_date} | {matchData.match_time} |{" "}
                {matchData.Stade.name}
              </Typography>

              <Grid container spacing={3} alignItems="start" className="mt-4">
                <Grid item xs={4}>
                  <TeamLogo
                    src={matchData.equipeHome?.image}
                    alt={matchData.equipeHome.name}
                  />
                  <CustomTypography variant="h6" align="center">
                    {matchData.equipeHome.slug}
                  </CustomTypography>
                </Grid>
                <Grid item xs={4}>
                  <ScoreTypography variant="h4" align="center">
                    {matchSelected?.match?.but_home} -{" "}
                    {matchSelected?.match?.but_away}
                  </ScoreTypography>
                </Grid>
                <Grid item xs={4}>
                  <TeamLogo
                    src={matchData.equipeAway?.image}
                    alt={matchData.equipeAway.name}
                  />
                  <CustomTypography variant="h6" align="center">
                    {matchData.equipeAway.name}
                  </CustomTypography>
                </Grid>
              </Grid>

              <Grid container spacing={3} className="mt-4">
                <Grid item xs={6}>
                  <CustomTypography variant="h6">
                    {t("HOME_TEAM_EVENTS")}
                  </CustomTypography>
                  <LineupTable
                    players={eventsHome.ListPlayer || []}
                    substitutes={eventsHome?.ListReplacment || []}
                    team={teamHome}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomTypography variant="h6">
                    {t("AWAY_TEAM_EVENTS")}
                  </CustomTypography>
                  <LineupTable
                    players={eventsAway.ListPlayer || []}
                    substitutes={eventsAway?.ListReplacment || []}
                    team={teamAway}
                  />
                </Grid>
              </Grid>
            </CardContent>
          ) : (
            <Loading />
          )}
        </StyledCard>

        {user?.equipe_id === matchSelected.match.equipeAway.id && (
          <div
            className="flex justify-center space-x-4"
            style={{
              position: "sticky",
              bottom: 0,
              backgroundColor: "#ffffff80",
              padding: "20px 50%",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <button
              onClick={handleAccept}
              className="text-white px-4 py-2 hover:bg-blue-400 bg-primary rounded-md "
            >
              {t("ACCEPT")}
            </button>
            <button
              onClick={handleRefuse}
              className="text-white px-4 py-2 hover:bg-red-400 bg-red-600 rounded-md "
            >
              {t("REFUSE")}
            </button>
          </div>
        )}

        <Dialog open={openAcceptModal} onClose={handleCloseAcceptModal}>
          <DialogTitle>{t("SIGNATURE_MODAL_TITLE")}</DialogTitle>
          <DialogContent>
            <Signature
              t={t}
              role="arbiture"
              isSigned={signature?.arbiture}
              handleSignatureUpdate={handleSignatureUpdate}
              isArbiture={user}
              isForConfirmation={true}
              matchId={matchSelected?.id}
              type={"confirmation"}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAcceptModal} color="primary">
              {t("CLOSE")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openRefuseModal} onClose={handleCloseRefuseModal}>
          <DialogTitle>{t("REFUSE_MATCH_EVENTS")}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t("PROVIDE_REFUSAL_REASON")}</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="reason"
              label={t("REFUSAL_REASON")}
              type="text"
              fullWidth
              variant="outlined"
              value={refusalReason}
              onChange={(e) => setRefusalReason(e.target.value)}
              error={!!error}
              helperText={error}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseRefuseModal} color="primary">
              {t("CLOSE")}
            </Button>
            <Button onClick={handleConfirmRefusal} color="primary">
              {t("CONFIRM")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default MatchEventsConfirmationPage;

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { getGoalScorersForTeamStats } from '../../service/statisticService';
import SmallLoading from '../../../../components/Loadings/SmallLoading';
import NoDataFound from '../Layouts/NoDataFound';

export default function TeamGoalScorers({ category }) {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getStats = async () => {
        try {
            const response = await getGoalScorersForTeamStats(category);
            setData(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getStats();
    }, [category]);

    return (
        <aside className="widget widget--sidebar card card--has-table widget-leaders">
            <div className="widget__title card__header">
                <h4>{t('TEAM_GOALSCORERS')}</h4>
            </div>
            {loading ? <div className="widget__content card__content my-10">
                <SmallLoading /></div> : !loading && data?.length == 0 ? <div className='h-20'>
                    <NoDataFound />
                </div> :
                <div className="widget__content card__content overflow-scroll" style={{ height: "28rem" }}>
                    <div className="table-responsive">
                        <table className="table team-leader">
                            <thead>
                                <tr>
                                    <th className="team-leader__type">{t('FULL_NAME')}</th>
                                    <th className="team-leader__goals">{t('GOALS')}</th>
                                    <th className="team-leader__gp">{t('GAMES_PLAYED')}</th>
                                    <th className="team-leader__avg">{t('AVERAGE')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((player, index) => (
                                        <tr key={index}>
                                            <td className="team-leader__player">
                                                <div className="team-leader__player-info">
                                                    <figure className="team-leader__player-img team-leader__player-img--sm">
                                                        <img
                                                            src={player.joueur.image}
                                                            alt={`${player.joueur.nom} ${player.joueur.prenom}`}
                                                        />
                                                    </figure>
                                                    <div className="team-leader__player-inner">
                                                        <h5 className="team-leader__player-name">
                                                            {`${player.joueur.prenom} ${player.joueur.nom}`}
                                                        </h5>
                                                        {/* Uncomment this block if you want to show the player's position */}
                                                        {/* <span className="team-leader__player-position">
                                                                {player.position === 2 ? 'Forward' : 'Unknown Position'}
                                                            </span> */}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="team-leader__goals">{player.total_buts}</td>
                                            <td className="team-leader__gp">{player.total_matches}</td>
                                            <td className="team-leader__avg">
                                                <div className="circular">
                                                    <div
                                                        className="circular__bar"
                                                        data-percent={player.average_buts * 100}
                                                    >
                                                        <span className="circular__percents">{player.average_buts}</span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </aside>
    );
}

import React, { useContext, useEffect, useState } from "react";
import { Avatar } from "@mui/material";
import { addEventToState } from "../../../redux/actions/matchEventsActions";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { getReservesTeamplate } from "../../../redux/middleware/matchReservesThunks";
import ModalSelectPlayerMobile from "../../../dashboard/src/components/Modal/ModalSelectPlayerMobile";
import { MobileContext } from "../../../dashboard/src/context/isMobileContext";

const InjuriesEvent = ({
  injuredPlayer,
  setPlayerSelected,
  t,
  currentTeam,
  teamRecevant,
  teamVisiteuse,
  players,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [injuryTime, setInjuryTime] = useState({
    RegularTime: 0,
    extraTime: 0,
  });
  const [openModalSelectPlayer, setOpenModalSelectPlayer] = useState(false);
  const [selectedMotif, setselectedMotif] = useState(0);
  const [selectedConditionMotif, setSelectedConditionMotif] = useState(0);
  const [template, setTemplate] = useState([]);
  const isMobile = useContext(MobileContext);
  const fetchTeamplate = async () => {
    const data = await getReservesTeamplate("injuries", "");
    setTemplate(data);
  };
  useEffect(() => {
    fetchTeamplate();
  }, [injuryTime]);

  const handleMotifSelect = (event) => {
    setselectedMotif(event?.target?.value);
  };
  const handleConfitionMotifSelect = (event) => {
    setSelectedConditionMotif(event?.target?.value);
  };

  const handleSelectModalPlayer = (player) => {
    setPlayerSelected([player]);
    setOpenModalSelectPlayer(false);
  };

  const handleValidateInjury = () => {
    const { RegularTime, extraTime } = injuryTime;

    if (!injuredPlayer || !RegularTime || selectedMotif === 0) {
      const variant = "error";
      enqueueSnackbar(t("FILL_ALL_FIELDS"), { variant });
      return;
    }

    const isValidRegularTime =
      RegularTime === 45 ||
      RegularTime === 90 ||
      RegularTime === 105 ||
      RegularTime === 120;

    const injuryEvent = {
      team: {
        id:
          currentTeam == "teamRecevant" ? teamRecevant?.id : teamVisiteuse?.id,
        name:
          currentTeam == "teamRecevant"
            ? teamRecevant?.name
            : teamVisiteuse?.name,
        logo:
          currentTeam == "teamRecevant"
            ? teamRecevant?.logo
            : teamVisiteuse?.logo,
      },
      injuredPlayer: injuredPlayer,
      eventName: "injury",
      RegularTime,
      extraTime: isValidRegularTime ? extraTime : 0,
      reason: selectedConditionMotif,
      position: selectedMotif,
    };

    dispatch(addEventToState(injuryEvent));

    setPlayerSelected([]);
    setInjuryTime({ RegularTime: 0, extraTime: 0 });
    setselectedMotif(0);
    setTemplate([]);
  };

  const handleChange = (field, value) => {
    setInjuryTime((prev) => ({ ...prev, [field]: parseInt(value) }));
  };

  const handlePlayerClick = (player) => {
    setPlayerSelected((prevState) => {
      const isSelected = prevState.some((p) => p?.id === player?.id);
      if (isSelected) {
        return prevState.filter((p) => p?.id !== player?.id);
      } else if (prevState.length < 1) {
        return [player];
      } else {
        return prevState;
      }
    });
  };
  const conditionMotifs = [
    {
      label: 1,
      value: "CONTACT_WITH_AN_OBJECT",
    },
    { label: 2, value: "CONTACT_WITH_A_PLAYER" },
    { label: 3, value: "ALONE" },
  ];
  const isExtraTimeDisabled = !(
    injuryTime.RegularTime === 45 ||
    injuryTime.RegularTime === 90 ||
    injuryTime.RegularTime === 105 ||
    injuryTime.RegularTime === 120
  );

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg text-center">
      <h2 className="text-xl max-sm:text-lg font-bold font-myteam mb-4">
        {t("LOG_INJURY_EVENT")}
      </h2>
      <div className="flex justify-around gap-4">
        <div className="flex items-center space-x-4">
          <div className="text-center">
            <label
              htmlFor="injuredPlayer"
              className="block text-sm font-myteam font-medium text-gray-700 mb-1"
            >
              {t("INJURED_PLAYER")}
            </label>
            <div className="space-y-2 font-myteam">
              {injuredPlayer ? (
                <>
                  <Avatar
                    key={injuredPlayer.id}
                    alt={injuredPlayer.fullName}
                    src={injuredPlayer.image}
                    className="mx-auto cursor-pointer"
                    onClick={() => handlePlayerClick(injuredPlayer)}
                  />
                  <p className="font-myteam">{injuredPlayer.fullName}</p>
                </>
              ) : (
                <div
                  className="flex justify-center"
                  onClick={() =>
                    isMobile ? setOpenModalSelectPlayer(true) : undefined
                  }
                >
                  <img
                    src="../../../assets/images/tshirt.png"
                    alt="T-shirt"
                    className="w-10 h-10 rounded bg-white p-1"
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div>
          <label
            htmlFor="injuryMinute"
            className="block text-sm font-myteam font-medium text-gray-700 mb-1"
          >
            {t("INJURY_MINUTE")}
          </label>

          <div className="flex items-center">
            <div className="flex w-max font-myteam flex-wrap items-center rounded-lg">
              <div className="p-2">
                <input
                  style={{
                    backgroundColor: "#fafafa",
                  }}
                  type="number"
                  value={injuryTime.RegularTime}
                  min={0}
                  max={120}
                  onChange={(e) => handleChange("RegularTime", e.target.value)}
                  className="mt-1 text-center block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <label>+</label>
              <div className="p-2">
                <input
                  style={{
                    backgroundColor: "#fafafa",
                  }}
                  type="number"
                  disabled={isExtraTimeDisabled}
                  value={injuryTime.extraTime}
                  min={0}
                  max={50}
                  onChange={(e) => handleChange("extraTime", e.target.value)}
                  className="mt-1 font-myteam text-center block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-center m-5">
        <div>
          <label
            htmlFor="motif"
            className="block text-sm font-myteam font-medium text-gray-700 mb-1"
          >
            {t("MOTIF_INJURY")}
          </label>
          <div className=" flex flex-wrap justify-center border m-2 w-max items-center border-gray-300 p-4 rounded-lg bg-white shadow">
            <select
              style={{
                backgroundColor: "#fafafa",
              }}
              onChange={handleConfitionMotifSelect}
              defaultValue={selectedConditionMotif}
            >
              <option value={0}>{t("RESION_INJURIES")}</option>
              {conditionMotifs?.map((tmp) => {
                return (
                  <option key={tmp.label} value={tmp.value}>
                    {t(tmp.value)}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        {selectedConditionMotif != 0 && (
          <div>
            <label
              htmlFor="motif"
              className="block text-sm font-myteam font-medium text-gray-700 mb-1"
            >
              {t("INJURIES_POSITION")}
            </label>
            <div className=" flex flex-wrap justify-center border m-2 w-max items-center border-gray-300 p-4 rounded-lg bg-white shadow">
              <select
                style={{
                  backgroundColor: "#fafafa",
                }}
                onChange={handleMotifSelect}
                defaultValue={selectedMotif}
              >
                <option value={0}>{t("INJURIES_POSITION")}</option>
                {template?.map((tmp) => {
                  return (
                    <option key={tmp.id} value={tmp.value}>
                      {t(tmp.title)}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        )}
      </div>

      {openModalSelectPlayer ? (
        <ModalSelectPlayerMobile
          players={players}
          isOpen={openModalSelectPlayer}
          callback={(ply) => handleSelectModalPlayer(ply)}
          onClose={() => setOpenModalSelectPlayer(false)}
        />
      ) : null}

      <button
        onClick={handleValidateInjury}
        disabled={!injuredPlayer||!selectedMotif}
        className="w-auto px-4 py-2 font-myteam bg-blue-500 disabled:bg-blue-300 text-white rounded-md hover:bg-blue-600 focus:outline-none mb-4"
      >
        {t("VALIDATE_INJURY")}
      </button>
    </div>
  );
};

export default InjuriesEvent;

import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DateTimePicker,
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";

function DateTimePickers({ label, value, onChange, style }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="my-4">
        {label && (
          <label className="block text-sm font-myteam font-medium text-gray-700 mb-2">
            {label}
          </label>
        )}
        <DateTimePicker defaultValue={value} onChange={onChange} />
      </div>
    </LocalizationProvider>
  );
}

export default DateTimePickers;

import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StickyHeadTable from "../components/Tables/StickyHeadTable";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTeam,
  getComposition,
  validateComposition,
} from "../redux/middleware/matchThunks";
import GroupIcon from "@mui/icons-material/Group";
import { Avatar, TextField, Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import Formation from "../components/LineUp/Formation";
import { Add, Check } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import PlayerCard from "../components/LineUp/PlayerCard";
import Loading from "../components/Loadings/Loading";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { getTeamById } from "../redux/selectors/matchSelectors";
import MatchSelected from "../components/layouts/MatchSelected";
import { useSnackbar } from "notistack";
import { AuthContext } from "../contexts/AuthContext";
import SportsIcon from "@mui/icons-material/Sports";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import GoBackButton from "../components/GoBackButton";
import { MobileContext } from "../dashboard/src/context/isMobileContext";
import ModalSurClassement from "../dashboard/src/components/Modal/ModalSurClassement";
import ModalSelectPlayerMobile from "../dashboard/src/components/Modal/ModalSelectPlayerMobile";

const PrepareMatchPage = () => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const location = useLocation();
  const { match, teamId, teamSlug } = location.state || {};
  const [teamFiltred, setTeamFiltred] = useState();
  const teams = useSelector((state) => state.matchReducer.teams);
  const myComposition = useSelector(
    (state) => state.matchReducer?.myComposition
  );
  const loading = useSelector((state) => state.matchReducer.loading);
  const error = useSelector((state) => state.matchReducer.error);
  const [filter, setFilter] = useState("");
  const [players, setPlayers] = useState([]);
  const [subs, setSubs] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [tabActive, setTabActive] = useState(1);
  const [selectedRoleFilter, setSelectedRoleFilter] = useState("");
  const team = useSelector((state) => state.matchReducer);
  const [encadrement, setEncadrement] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [formationSelect, setFormationSelect] = useState();
  const [formationSaved, setFormationSaved] = useState(0);
  const [validateCompUpdate, setValidateCompUpdate] = useState(true);
  const [openModalForConvocPlayer, setOpenModalForConvocPlayer] =
    useState(false);
  const [openMobileSelectPlayers, setOpenMobileSelectPlayers] = useState(false);
  const [openMobileSelectPlayersSubs, setOpenMobileSelectPlayersSubs] =
    useState(false);
  const [openMobileSelectStaffs, setOpenMobileSelectStaffs] = useState(false);
  const navigate = useNavigate();
  const isMobile = useContext(MobileContext);

  useEffect(() => {
    if (!match || match == undefined || match == null) {
      navigate("/");
    }
  }, []);

  const columns = [
    {
      id: "fullName",
      label: t("FULL_NAME"),
      minWidth: 100,
      format: (value, row) => (
        <div className="flex items-center space-x-2">
          <Avatar
            alt={value}
            src={row.image}
            className="w-10 h-10 rounded-full object-cover	"
          />
          <span className="font-medium font-myteam text-gray-700">{value}</span>
        </div>
      ),
    },
    { id: "role", label: t("ROLE_FUNCTION"), minWidth: 100 },
    {
      id: "shirtNmbr",
      label: t("SHIRT"),
      minWidth: 100,
      format: (value, row) => (
        <EditableInput
          shirtNumber={row?.shirtNmbr}
          role={row?.role}
          onShirtNumberChange={(e) => handleEditShirtNumbr(e, row)}
        />
      ),
    },
    {
      id: "licenceNumber",
      label: t("LICENCE"),
      minWidth: 120,
    },
    {
      id: "convoced",
      label: t("CONVOCED"),
      minWidth: 170,
      format: (value, row) =>
        value ? (
          <p
            className={`${"text-green-600"} font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
          >
            <Check />
          </p>
        ) : (
          <button
            className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
            onClick={() => handlePlayerClick(row)}
          >
            {selectedPosition == null ? t("SUB") : t("TITULAR")}
          </button>
        ),
    },
  ];

  useEffect(() => {
    if (teams?.length !== 0 && teamId) {
      setTeamFiltred(getTeamById(teams, teamId));
    }
    if (teamFiltred) {
      setFilteredPlayers(
        teamFiltred?.team.filter((eqp) => {
          const filterWords = filter.trim().toLowerCase().split(/\s+/);
          const fullName = `${eqp.nom?.toLowerCase()} ${eqp.prenom?.toLowerCase()}`;
          return filterWords.every((word) => fullName.includes(word));
        })
      );
      const playersArray = [];
      teamFiltred?.composition?.players?.forEach((player) => {
        playersArray[player.position_index] = player;
      });
      setPlayers(playersArray?.filter((arr) => arr !== undefined));
      setEncadrement(teamFiltred?.composition?.staffs || []);
      setSubs(teamFiltred?.composition?.subs || []);
      setFormationSelect(teamFiltred?.composition?.formation || "4-4-2");
    }
    if (team.myTeam && team?.myTeam.players?.length !== 0) {
      const allPlayersAndStaffs = [
        ...(team.myTeam.team?.players ?? []),
        ...(team.myTeam.team?.staffs ?? []),
      ];
      setFilteredPlayers(
        allPlayersAndStaffs.filter((eqp) => {
          const filterWords = filter.trim().toLowerCase().split(/\s+/);
          const fullName = `${eqp.nom?.toLowerCase()} ${eqp.prenom?.toLowerCase()}`;
          return filterWords.every((word) => fullName.includes(word));
        })
      );
    }
  }, [filter, team.myTeam, teamFiltred, teams]);

  useEffect(() => {
    if (Object.keys(myComposition).length !== 0) {
      const playersArray = [];
      myComposition?.players?.forEach((player) => {
        playersArray[player.position_index] = player;
      });
      setFormationSelect(myComposition?.formation);
      setPlayers(playersArray);
      setEncadrement(myComposition?.staffs || []);
      setSubs(myComposition?.subs || []);
    }
  }, [myComposition]);

  useEffect(() => {
    if (user && !teamId) {
      dispatch(fetchTeam(match?.match?.id));
      dispatch(getComposition(match?.match?.id, user?.equipe_id, "userTeam"));
    }
    if (teamId) {
      dispatch(getComposition(match?.match?.id, teamId, "teams"));
    }
  }, [dispatch, teamId]);

  const handleEditShirtNumbr = (e, row) => {
    const isNumber = /^\d+$/.test(e?.target?.value);
    if (isNumber) {
      if (players?.filter((player) => player?.id === row?.id)) {
        setPlayers(
          players?.map((player) =>
            player?.id === row?.id
              ? { ...player, shirtNmbr: e?.target?.value }
              : player
          )
        );
      }
      if (subs?.filter((player) => player?.id === row?.id)) {
        setSubs(
          subs?.map((player) =>
            player?.id === row?.id
              ? { ...player, shirtNmbr: e?.target?.value }
              : player
          )
        );
      }
    }
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const filteredPlayersByRole = selectedRoleFilter
    ? filteredPlayers.filter((eqp) => eqp.role === selectedRoleFilter)
    : filteredPlayers;

  const rows = filteredPlayersByRole?.map((eqp) => ({
    id: eqp.id,
    image: eqp.image,
    firstName: eqp.nom,
    lastName: eqp.prenom,
    fullName: `${eqp.nom} ${eqp.prenom}`,
    role: eqp?.role,
    licenceNumber: eqp.licenceNumber || eqp?.licenceId || "-",
    convoced:
      players?.some(
        (player) =>
          player?.id === eqp.id &&
          player?.role?.toLowerCase() === eqp.role?.toLowerCase()
      ) ||
      subs?.some(
        (sub) =>
          sub?.id === eqp.id &&
          sub?.role?.toLowerCase() === eqp.role?.toLowerCase()
      ) ||
      encadrement?.some(
        (sub) =>
          sub?.id === eqp.id &&
          sub?.role?.toLowerCase() === eqp.role?.toLowerCase()
      ),
    shirtNmbr: eqp.shirtNumber || eqp?.shirtNmbr || 0,
    post: eqp.post,
    position_index: eqp.position_index,
  }));

  const handlePlayerClick = (player) => {
    if (player.role?.toLowerCase() !== "player") {
      handleAddEncadrant(player);
      return;
    }
    if (
      players?.some((p) => p?.id === player?.id && p?.role === player?.role) ||
      subs.some((sub) => sub?.id === player?.id && sub?.role === player?.role)
    ) {
      const variant = "error";
      enqueueSnackbar(t("ALREADY_CONVOCED"), { variant });
      return;
    }

    if (selectedPosition !== null) {
      const playerInAnotherPosition = players.findIndex(
        (p, index) => index != selectedPosition && p?.id === player?.id
      );

      if (playerInAnotherPosition === -1) {
        const newPlayers = [...players];
        newPlayers[selectedPosition] = player;
        setPlayers(newPlayers);
        setSelectedPosition(null);
      } else {
        const variant = "error";
        enqueueSnackbar(t("PLAYER_ALREADY_IN_POSITION"), { variant });
        return;
      }
    } else {
      handleAddSubstitute(player);
    }
  };
  const uniqueRoles = [
    ...new Set(
      teamFiltred && teamFiltred !== undefined
        ? [...(teamFiltred.team?.map((row) => row.role) ?? [])]
        : [
            ...(team.myTeam.team?.players?.map((row) => row.role) ?? []),
            ...(team.myTeam.team?.staffs?.map((row) => row.role) ?? []),
          ]
    ),
  ];

  const handleAddSubstitute = (player) => {
    if (player.role?.toLowerCase() == "player") {
      if (!subs.some((e) => e.id === player.id)) {
        setSubs([...subs, player]);
      } else {
        const variant = "error";
        enqueueSnackbar(t("ALREADY_CONVOCED"), { variant });
      }
    }
  };

  const handleRemoveSubstitute = (player) => {
    setSubs(subs.filter((sub) => sub.id !== player?.id));
  };

  const handleAddEncadrant = (encadrant) => {
    if (encadrant.role?.toLowerCase() !== "player") {
      if (!encadrement.some((e) => e.id === encadrant.id)) {
        setEncadrement([...encadrement, encadrant]);
      } else {
        const variant = "error";
        enqueueSnackbar(t("ALREADY_CONVOCED"), { variant });
      }
    }
  };

  const handleRemoveEncadrant = (encadrant) => {
    setEncadrement(encadrement.filter((e) => e.id !== encadrant.id));
  };

  const handleRoleFilterChange = (e) => {
    setSelectedRoleFilter(e.target.value);
  };

  const handleValiderComposition = async () => {
    if (players?.filter((players) => players != undefined)?.length < 7) {
      setValidateCompUpdate(true);
      enqueueSnackbar(t("MESSAGES_ERROR.NOT_ENOUGH_PLAYERS"), {
        variant: "error",
      });
      return;
    }

    if (subs.length < 2) {
      setValidateCompUpdate(true);
      enqueueSnackbar(t("MESSAGES_ERROR.NOT_ENOUGH_SUBS"), {
        variant: "error",
      });
      return;
    }

    // if (encadrement.length < 3) {
    //   setValidateCompUpdate(true);
    //   enqueueSnackbar(t("MESSAGES_ERROR.NOT_ENOUGH_STAFF"), {
    //     variant: "error",
    //   });
    //   return;
    // }

    if (teamId && teamId != undefined) {
      navigate("/match/fuille", {
        state: {
          match,
          validComposition: {
            teamSlug: teamSlug,
          },
        },
      });
      return;
    }
    // formationSaved == 0 && setFormationSaved(1);

    // if (formationSaved == 2) {
    //   const res = await dispatch(
    //     validateComposition(
    //       match?.match?.id,
    //       teamId && teamId != undefined ? teamId : user?.equipe_id
    //     )
    //   );
    //   if (res) {
    //     navigate(-1);
    //     enqueueSnackbar(t("MESSAGES_SUCCESS.COMPOSITION_VALIDATED"), {
    //       variant: "success",
    //     });
    //   }
    // }

    const res = await dispatch(
      validateComposition(
        match?.match?.id,
        teamId && teamId != undefined ? teamId : user?.equipe_id,
        "Non joué préparé"
      )
    );
    if (teamId && teamId != undefined) {
      navigate("/match/fuille", {
        state: {
          match,
          validComposition: {
            teamSlug: teamSlug,
          },
        },
      });
      return;
    }
    if (res) {
      navigate(-1);
      enqueueSnackbar(t("MESSAGES_SUCCESS.COMPOSITION_VALIDATED"), {
        variant: "success",
      });
    }
  };

  useEffect(() => {
    if (formationSaved == 1) return handleValiderComposition();
  }, [formationSaved, validateCompUpdate]);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <MatchSelected match={match} t={t} />
          <div className="p-4 bg-white shadow-lg rounded-lg">
            {<GoBackButton />}
            <div
              className={`${
                isMobile ? "px-0 " : "px-10 "
              } flex justify-between items-center flex-wrap gap-2 py-2`}
            >
              <div className="text-center items-center">
                <div className="flex items-center justify-center space-x-2">
                  <Avatar
                    alt={user?.equipe_slug || teamFiltred?.name}
                    src={teamFiltred?.logo || user?.image_equipe}
                    className="w-16 h-16 rounded-full object-cover"
                  />
                  <span className="font-bold font-myteam text-gray-800 max-w-xs truncate">
                    {user?.equipe_slug || teamFiltred?.name} (
                    {match?.match?.category_divisions})
                  </span>
                </div>
              </div>
              <div className="text-center items-center">
                {match?.match?.Arbitres?.length != 0 && (
                  <>
                    <div className="flex items-center justify-center space-x-2">
                      <span className="font-myteam font-bold uppercase text-gray-700 max-w-xs">
                        {t("ARBITER_OFFICIEL")}
                      </span>
                    </div>
                    <div className="font-myteam flex items-center text-gray-500 text-sm">
                      {match?.match?.Arbitres?.map((arbt, index) => (
                        <p className="p-2" key={index}>
                          {arbt?.function !== "principal" ? (
                            <SportsScoreIcon />
                          ) : (
                            <SportsIcon />
                          )}
                          {arbt.nom} {arbt.prenom}
                        </p>
                      ))}
                    </div>
                  </>
                )}
              </div>
              <button
                disabled={teamId ? false : validateCompUpdate}
                onClick={() => handleValiderComposition()}
                className="bg-green-700 font-myteam disabled:bg-gray-400 hover:bg-green-900 text-white font-bold py-2 px-4 rounded"
              >
                <VerifiedUserIcon /> {t("VALIDER_COMPOSITION")}
              </button>
            </div>
            <div className="flex max-sm:flex-wrap mt-4 justify-between">
              <div className="max-sm:w-full w-3/5 mb-4">
                <h2 className="text-lg font-myteam font-semibold mb-4">
                  <GroupIcon /> {t("EFFECTIF")}
                </h2>
                <div className="flex">
                  <TextField
                    label={t("SEARCH_BY_NAME")}
                    value={filter}
                    onChange={handleFilterChange}
                    variant="outlined"
                    className="mb-4 mx-2 w-1/2 h-16"
                  />
                  <Select
                    value={selectedRoleFilter}
                    onChange={handleRoleFilterChange}
                    displayEmpty
                    style={{
                      backgroundColor: "#fafafa",
                    }}
                    className="mb-4 mx-2 w-1/2 h-3/5"
                    variant="outlined"
                  >
                    <MenuItem value="" className="font-myteam">
                      {t("ALL_ROLES")}
                    </MenuItem>
                    {uniqueRoles.map((role) => (
                      <MenuItem key={role} value={role}>
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                  <button
                    onClick={() => setOpenModalForConvocPlayer(true)}
                    className="text-sm text-blue-600 font-bold w-44 hover:text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-300 ease-in-out"
                  >
                    {t("CONVOCE_PLAYER")}
                  </button>
                </div>
                <StickyHeadTable
                  loading={loading}
                  columns={columns}
                  rows={rows}
                  error={error}
                  cursor={"cursor-pointer"}
                  headerColor={"black"}
                  headerLabelsColor={"white"}
                  initialRowsPerPage={25}
                  //onRowClick={handlePlayerClick}
                />
              </div>
              <div
                className={`max-sm:w-full ${
                  isMobile ? "w-full flex-wrap" : "w-2/5"
                } flex mb-4`}
              >
                <div
                  className={`h-1/2 max-sm:w-full relative ${
                    isMobile ? "w-full ml-2 mr-2" : "ml-4 mr-4"
                  }`}
                >
                  <h2 className="text-lg font-myteam font-semibold mb-4">
                    <GroupIcon /> {t("COMPOSITION")}
                  </h2>
                  <Formation
                    setPlayers={setPlayers}
                    setSubs={setSubs}
                    subs={subs}
                    setEncadrement={setEncadrement}
                    setSelectedPosition={setSelectedPosition}
                    selectedPosition={selectedPosition}
                    players={players}
                    encadrement={encadrement}
                    isMobile={isMobile}
                    setOpenMobileSelectPlayers={setOpenMobileSelectPlayers}
                    showFilterOptions={true}
                    defaultFormation={formationSelect}
                    match={match?.match}
                    equipeId={user?.equipe_id}
                    setFormationSaved={setFormationSaved}
                    formationSaved={formationSaved}
                    setValidateCompUpdate={setValidateCompUpdate}
                  />
                </div>
              </div>
              <div
                className={`h-1/2 max-sm:w-full ${
                  isMobile ? "w-full" : "w-1/2"
                }`}
              >
                <div className="flex">
                  <button
                    className={`mr-4 ${
                      tabActive === 1 ? "text-blue-500 p-4 bg-gray-100" : ""
                    }`}
                    onClick={() => setTabActive(1)}
                  >
                    <h2 className="text-lg font-myteam font-semibold">
                      <PersonIcon /> {t("REMPLACANTS")}
                    </h2>
                  </button>
                  <button
                    className={`mr-4 ${
                      tabActive === 2 ? "text-blue-500 p-4 bg-gray-100" : ""
                    }`}
                    onClick={() => setTabActive(2)}
                  >
                    <h2 className="text-lg font-myteam font-semibold">
                      <MilitaryTechIcon /> {t("ENCADREMENT")}
                    </h2>
                  </button>
                </div>

                {tabActive === 1 ? (
                  <>
                    <div
                      className="p-3 border-2 rounded-md"
                      style={{
                        maxHeight: "75vh",
                        overflowY: "scroll",
                        backgroundColor: "#F5F5F5",
                      }}
                    >
                      {subs.length === 0 ? (
                        <div className="flex items-center p-4">
                          <span className="text-gray-500 font-myteam">
                            {t("NO_SUBSTITUTES_MESSAGE")}
                          </span>
                          <button
                            className="ml-2 text-blue-500"
                            onClick={() =>
                              isMobile
                                ? setOpenMobileSelectPlayersSubs(true)
                                : undefined
                            }
                          >
                            <Add />
                          </button>
                        </div>
                      ) : (
                        <>
                          {isMobile ? (
                            <div className="flex justify-end items-center">
                              <button
                                className="inline-flex items-center justify-center p-2 rounded-full object-cover bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-300 ease-in-out"
                                onClick={() =>
                                  isMobile
                                    ? setOpenMobileSelectPlayersSubs(true)
                                    : undefined
                                }
                              >
                                <Add />
                              </button>
                            </div>
                          ) : null}
                          {subs.map((sub, index) => (
                            <div
                              key={sub.id}
                              className="flex items-center space-x-2 p-1"
                            >
                              <PlayerCard
                                player={sub}
                                onRemove={handleRemoveSubstitute}
                              />
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="p-3 border-2 rounded-md"
                      style={{
                        maxHeight: "75vh",
                        overflowY: "scroll",
                        backgroundColor: "#F5F5F5",
                      }}
                    >
                      {encadrement.length === 0 ? (
                        <div className="flex items-center p-4">
                          <span className="text-gray-500">
                            {t("NO_ENCADRANTS_MESSAGE")}
                          </span>
                          <button
                            className="ml-2 text-blue-500"
                            onClick={() =>
                              isMobile
                                ? setOpenMobileSelectStaffs(true)
                                : undefined
                            }
                          >
                            <Add />
                          </button>
                        </div>
                      ) : (
                        <>
                          {isMobile ? (
                            <div className="flex justify-end items-center">
                              <button
                                className="inline-flex items-center justify-center p-2 rounded-full object-cover bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-300 ease-in-out"
                                onClick={() =>
                                  isMobile
                                    ? setOpenMobileSelectStaffs(true)
                                    : undefined
                                }
                              >
                                <Add />
                              </button>
                            </div>
                          ) : null}
                          {encadrement.map((encadrant, index) => (
                            <div
                              key={encadrant.id}
                              className="flex items-center space-x-2 p-2"
                            >
                              <PlayerCard
                                player={encadrant}
                                onRemove={handleRemoveEncadrant}
                              />
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {openModalForConvocPlayer ? (
            <ModalSurClassement
              modal20={openModalForConvocPlayer}
              setModal20={setOpenModalForConvocPlayer}
              categActive={match?.match?.category_divisions}
              setPlayers={setFilteredPlayers}
            />
          ) : null}
          {openMobileSelectPlayers ? (
            <ModalSelectPlayerMobile
              players={rows?.filter(
                (row) => row.role?.toLowerCase() === "player"
              )}
              callback={handlePlayerClick}
              isOpen={openMobileSelectPlayers}
              onClose={() => setOpenMobileSelectPlayers(false)}
            />
          ) : null}
          {openMobileSelectPlayersSubs ? (
            <ModalSelectPlayerMobile
              players={rows?.filter(
                (row) => row.role?.toLowerCase() === "player"
              )}
              callback={handleAddSubstitute}
              isOpen={openMobileSelectPlayersSubs}
              onClose={() => setOpenMobileSelectPlayersSubs(false)}
            />
          ) : null}
          {openMobileSelectStaffs ? (
            <ModalSelectPlayerMobile
              players={rows?.filter(
                (row) => row.role?.toLowerCase() != "player"
              )}
              callback={handleAddEncadrant}
              title={t("SELECT_STAFF")}
              avaliableData={t("NO_STAFF_AVAILABLE")}
              messageForAdd={t("ADD_STAFF_TO_LIST")}
              isOpen={openMobileSelectStaffs}
              onClose={() => setOpenMobileSelectStaffs(false)}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export default PrepareMatchPage;

const EditableInput = ({ shirtNumber, role, onShirtNumberChange }) => {
  const [inputValue, setInputValue] = useState(shirtNumber);

  useEffect(() => {
    setInputValue(shirtNumber);
  }, [shirtNumber]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onShirtNumberChange(e);
  };

  return (
    <input
      type="text"
      className="w-12 h-10 text-center border rounded bg-white"
      value={inputValue}
      onChange={handleChange}
      maxLength={2}
      disabled={role?.toLowerCase() !== "player"}
    />
  );
};

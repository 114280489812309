import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ErrorCode from '../ErrorCode';

export default function UnauthorizedPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/"); 
  };

  return (
    <ErrorCode 
      code={"401"} 
      title={t('MESSAGES_ERROR.UNAUTHORIZED_TITLE')} 
      message={t('MESSAGES_ERROR.UNAUTHORIZED_MESSAGE')} 
      callback={goBack} 
      btnText={t('BACK')} 
      btnStyle={
        {
          backgroundColor: '#007bff',
          color: '#fff',
          borderRadius: '5px',
          padding: '10px 20px',
          fontWeight: 'bold',
          cursor: 'pointer',
        }
      }
    />
  );
}

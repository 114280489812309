import React, { useContext, useEffect, useState } from "react";
import StickyHeadTable from "../components/Tables/StickyHeadTable";
import { Check, Close, ModeEdit } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import MatchModal from "../components/modals/MatchModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchMatches } from "../redux/middleware/matchThunks";
import { AuthContext } from "../contexts/AuthContext";
import { useNavigate } from "react-router";
import { removeEvents } from "../redux/actions/matchEventsActions";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { formatDateByDayName } from "../dashboard/src/utils/Utils";
import {
  customOptionWithAll,
  customOptionWithTranslate,
  divisionsData,
  roundData,
  statusOptions,
} from "../dashboard/src/utils/selectesData";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { MobileContext } from "../dashboard/src/context/isMobileContext";
import ExportMatchSheetExcelButton from "../dashboard/src/components/Buttons/ExportMatchSheetExcelButton";
import { Dialog } from "@mui/material";
import MatchEventsConfirmationPage from "./MatchEventsConfirmationPage";
import MatchRefusalDisplay from "../components/layouts/MatchRefusalDisplay";

const HomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [openModalMatche, setOpenModalMatche] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const handleCloseModalMatche = () => setOpenModalMatche(false);
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [openModalCommentRefus, setOpenModalCommentRefus] = useState(false);
  const [RowSelected, setRowSelected] = useState(null);
  const handleCloseModalConfirmation = () => {
    setLoadData(true);
    setRowSelected(null);
    setOpenModalConfirmation(false);
  };
  const [selectedDivsCategory, setSelectedDivsCategory] = useState();
  const [selectedRound, setSelectedRound] = useState();
  const [initialRowsPerPage, setInitialRowsPerPage] = useState(25);
  const [ReloadTable, setReloadTable] = useState(false);
  const dispatch = useDispatch();
  const [dateFilter, setDateFilter] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [filter, setFilter] = useState([]);
  const matches = useSelector((state) => state.matchReducer.matches);
  const loading = useSelector((state) => state.matchReducer.loading);
  const error = useSelector((state) => state.matchReducer.error);
  const matchHasArtbiter = useSelector(
    (state) => state.matchReducer.matchHasArtbiter
  );
  const isMobile = useContext(MobileContext);

  const columns = [
    {
      id: "category_age",
      label: t("CATEGORY"),
      minWidth: 50,
      format: (value, row) => (
        <span className="font-myteam text-gray-700 font-bold">
          #{row?.match?.category_divisions}
        </span>
      ),
    },
    { id: "round", label: t("ROUND"), minWidth: 100 },
    { id: "date", label: t("DATE"), minWidth: 170 },
    {
      id: "match",
      label: t("MATCH"),
      minWidth: 170,
      format: (value, row) => (
        <div className="flex items-center justify-center text-center space-x-2">
          <div className="relative" style={{ minWidth: "max-content" }}>
            <img
              alt={value.equipeHome.name}
              src={value.equipeHome.image}
              style={{
                resizeMode: "contain",
              }}
              className="w-10 h-10 object-contain"
            />
            {value?.equipeHome?.status?.toLowerCase() == "non joué préparé" ? (
              <Check className="text-green-600" />
            ) : (
              <Close className="text-red-600" />
            )}
          </div>
          <span className="font-medium text-center font-myteam text-gray-700 max-w-28 truncate">
            {value.equipeHome.slug}
          </span>
          <span className="mx-2 font-myteam">{t("VS")}</span>
          <span className="font-medium text-center font-myteam text-gray-700 max-w-28 truncate">
            {value.equipeAway.slug}
          </span>
          <div className="relative" style={{ minWidth: "max-content" }}>
            <img
              alt={value.equipeAway.name}
              src={value.equipeAway.image}
              className="w-10 h-10 object-contain"
            />
            {value?.equipeAway?.status?.toLowerCase() == "non joué préparé" ? (
              <Check className="text-green-600" />
            ) : (
              <Close className="text-red-600" />
            )}
          </div>
        </div>
      ),
    },
    { id: "stade", label: t("STADE"), minWidth: 100 },
    {
      id: "score",
      label: t("SCORE"),
      minWidth: 50,
      format: (value, row) => (
        <span className="font-myteam text-gray-700">
          {row?.match?.but_home + " - " + row?.match?.but_away}
        </span>
      ),
    },
    {
      id: "status",
      label: t("ETAT"),
      minWidth: 150,
      format: (value, row) => {
        const equipeStatus =
          user?.equipe_id == row?.match?.equipeHome?.id
            ? row?.match?.equipeHome?.status?.toLowerCase()
            : row?.match?.equipeAway?.status?.toLowerCase();

        if (user?.role?.toLowerCase() == "arbitre" && matchHasArtbiter) {
          return (
            <button
              disabled={value == "Joué"}
              className={` ${
                value == "Non joué non préparé"
                  ? "text-red-600"
                  : value == "Non joué préparé"
                  ? "text-orange-600"
                  : "text-green-600"
              } font-bold py-2 px-4 font-myteam rounded focus:outline-none focus:shadow-outline`}
              onClick={() => handleActionClick(row)}
            >
              {value == "Joué" ? <Check /> : <ModeEdit />}{" "}
              {t(value?.toLowerCase())}
            </button>
          );
        } else {
          return (
            <button
              disabled={equipeStatus == "joué"}
              className={` ${
                equipeStatus == "non joué non préparé" ||
                equipeStatus == "refused"
                  ? "text-red-600 hover:text-gray-400"
                  : equipeStatus == "non joué préparé"
                  ? "text-orange-600 hover:text-gray-400"
                  : equipeStatus == "waiting_confirmation"
                  ? "text-yellow-500 hover:text-gray-400"
                  : "text-green-600"
              } font-bold py-2 px-4 font-myteam rounded focus:outline-none focus:shadow-outline`}
              onClick={() => handleActionClick(row)}
            >
              {equipeStatus == "joué" ? <Check /> : <ModeEdit />}{" "}
              {t(equipeStatus)}
            </button>
          );
        }
      },
    },
    {
      id: "match",
      label: t("ACTION"),
      sortable: true,
      minWidth: 100,
      format: (match) => (
        <div className="flex items-center space-x-3">
          {match?.status != "waiting_confirmation" && (
            <button
              className="group relative p-2 bg-white border border-blue-200 rounded-lg shadow-sm transition-all duration-300 ease-in-out hover:shadow-md hover:border-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
              aria-label="View details"
              onClick={() =>
                match?.status != "refused"
                  ? navigate("/dashboard/match/details", {
                      state: { matchId: match?.id, match },
                    })
                  : (setOpenModalCommentRefus(true), setRowSelected(match))
              }
            >
              <VisibilityIcon className="w-5 h-5 text-blue-500 group-hover:text-blue-600 transition-colors duration-300" />
              <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                {t("VIEW")}
              </span>
            </button>
          )}
          {match?.status == "joué" ? (
            <ExportMatchSheetExcelButton match={match} />
          ) : null}
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchMatches());
    localStorage.removeItem("teamsCompositionValidate");
    localStorage.removeItem("matchEventsSatate");
    dispatch(removeEvents());
  }, [dispatch, loadData]);

  const handleActionClick = (row) => {
    if (
      row.status == "waiting_confirmation" &&
      (user?.equipe_id == row.match.equipeAway.id ||
        user?.equipe_id == row.match.equipeHome.id)
    ) {
      setOpenModalConfirmation(true);
    } else {
      setOpenModalMatche(true);
    }
    setRowSelected(row);
  };

  useEffect(() => {
    const rows = matches.matches
      ?.map((match) => {
        const matchDateTime = new Date(
          `${match.match_date} ${match.match_time}`
        );
        return {
          id: match?.id,
          date: formatDateByDayName(matchDateTime),
          match: match,
          stade: match.location,
          status:
            user?.role?.toLowerCase() == "arbitre"
              ? match.equipeAway?.status?.toLowerCase() ==
                match.equipeHome?.status?.toLowerCase()
                ? match.equipeAway?.status
                : "Non joué non préparé"
              : match?.status,
          created_at: match.created_at,
          round: match.round,
          matchDateTime,
        };
      })
      .sort((a, b) => {
        const now = new Date();
        if (a.matchDateTime >= now && b.matchDateTime >= now) {
          return a.matchDateTime - b.matchDateTime;
        } else if (a.matchDateTime < now && b.matchDateTime < now) {
          return b.matchDateTime - a.matchDateTime;
        }
        return a.matchDateTime >= now ? -1 : 1;
      });

    if (selectedDivsCategory || selectedRound || dateFilter || selectedStatus) {
      setFilter(
        rows?.filter(
          (row) =>
            (!selectedDivsCategory ||
              selectedDivsCategory?.value == "all" ||
              row?.match?.category_divisions == selectedDivsCategory?.value) &&
            (!selectedRound ||
              selectedRound?.value == "all" ||
              row?.match?.round == selectedRound?.value) &&
            (!selectedStatus ||
              selectedStatus?.value == "all" ||
              row?.match?.status?.toLowerCase() ==
                selectedStatus?.value?.toLowerCase()) &&
            (!dateFilter ||
              (row?.matchDateTime &&
                Array.isArray(dateFilter) &&
                new Date(row?.matchDateTime).getDate() ==
                  new Date(dateFilter[0]).getDate() &&
                new Date(row?.matchDateTime).getMonth() ==
                  new Date(dateFilter[0]).getMonth() &&
                new Date(row?.matchDateTime).getFullYear() ==
                  new Date(dateFilter[0]).getFullYear()))
        )
      );
      return;
    }
    setFilter(rows);
  }, [
    selectedDivsCategory,
    selectedRound,
    dateFilter,
    selectedStatus,
    matches.matches,
  ]);

  const handleColseModalComment = () => {
    setOpenModalCommentRefus(false);
    setRowSelected(null);
  };
  const resetFilters = () => {
    setDateFilter(null);
    setSelectedDivsCategory(null);
    setSelectedRound(null);
    setSelectedStatus(null);
    setReloadTable(true);
  };

  const getAvailableStatus = () => {
    const status = RowSelected?.status?.toLowerCase();
    const role = user?.role?.toLowerCase();

    const isTeamHome = RowSelected?.match?.equipeHome?.id == user?.equipe_id;
    const teamAwayPrepared =
      RowSelected?.match?.equipeAway?.status?.toLowerCase() ==
      "non joué préparé";

    if (status === "non joué non préparé" && role === "club") return 0;
    if (status === "non joué préparé" && role === "club" && matchHasArtbiter)
      return 2;
    if (status === "joué" && role === "club") return 2;
    if (status === "non joué préparé" && role === "arbitre") return 1;
    if (
      status === "non joué préparé" &&
      matchHasArtbiter == false &&
      isTeamHome &&
      teamAwayPrepared
    ) {
      return 1;
    }
    if (matchHasArtbiter == false && isTeamHome && status == "refused") {
      return 1;
    }

    return 2;
  };

  return (
    <div
      className={`max-sm:m-2 m-2 bg-white rounded-lg ${
        isMobile ? "p-2" : "p-10"
      }`}
    >
      <div className="flex justify-between items-start ">
        <h1
          className={`font-bold mb-4 ${
            isMobile ? " text-center text-xl" : "text-2xl "
          }`}
        >
          {t("LIST_OF_MATCHES")}
        </h1>
      </div>
      <div className="flex flex-row justify-end">
        <div
          className={`grid grid-cols-2 md:grid-cols-2 lg:grid-cols-6 gap-4 w-full items-center pb-4`}
        >
          <Select
            placeholder={t("FILTER_BY_CATRGORY")}
            options={divisionsData}
            className="p-2 rounded-md shadow-sm focus:z-9999 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
            value={selectedDivsCategory}
            getOptionLabel={(option) => (
              <span>
                {option.label == "ALL" ? t(option?.label) : option.label}
              </span>
            )}
            components={{
              Option: ({ data, innerRef, innerProps }) =>
                customOptionWithAll({ data, innerRef, innerProps, t }),
            }}
            onChange={(selectedOption) =>
              setSelectedDivsCategory(selectedOption)
            }
            styles={{
              control: (base, state) => ({
                ...base,
                zIndex: state.isFocused ? 9999 : "auto",
              }),
              menu: (base) => ({
                ...base,
                zIndex: 9999,
              }),
            }}
          />
          <Select
            placeholder={t("FILTER_BY_STATUS")}
            options={statusOptions}
            className="p-2 rounded-md shadow-sm focus:z-9999 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
            value={selectedStatus}
            getOptionLabel={(option) => <span>{t(option?.label)}</span>}
            components={{
              Option: ({ data, innerRef, innerProps }) =>
                customOptionWithTranslate({ data, innerRef, innerProps, t }),
            }}
            onChange={(selectedOption) => setSelectedStatus(selectedOption)}
            styles={{
              control: (base, state) => ({
                ...base,
                zIndex: state.isFocused ? 9999 : "auto",
              }),
              menu: (base) => ({
                ...base,
                zIndex: 9999,
              }),
            }}
          />
          <Select
            placeholder={t("FILTER_BY_ROUND")}
            options={roundData}
            className="p-2 rounded-md shadow-sm focus:z-9999 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
            value={selectedRound}
            getOptionLabel={(option) => (
              <span>
                {option.label == "ALL" ? t(option?.label) : option.label}
              </span>
            )}
            components={{
              Option: ({ data, innerRef, innerProps }) =>
                customOptionWithAll({ data, innerRef, innerProps, t }),
            }}
            styles={{
              control: (base, state) => ({
                ...base,
                zIndex: state.isFocused ? 9999 : "auto",
              }),
              menu: (base) => ({
                ...base,
                zIndex: 9999,
              }),
            }}
            onChange={(selectedOption) => setSelectedRound(selectedOption)}
          />
          <div className="col-span-1 md:col-span-2 lg:col-span-1">
            <Flatpickr
              value={dateFilter}
              placeholder={t("FILTER_BY_DATE")}
              options={{ dateFormat: "Y-m-d" }}
              className="form-input p-2 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
              onChange={(dates) => setDateFilter(dates)}
            />
          </div>
          <div className="flex items-center justify-end md:justify-end">
            <button
              type="button"
              className="btn btn-primary"
              onClick={resetFilters}
            >
              {t("RESET_FILTER")}
            </button>
          </div>
        </div>
      </div>
      <MatchModal
        open={openModalMatche}
        Available={getAvailableStatus()}
        RowSelected={RowSelected}
        handleClose={handleCloseModalMatche}
        title={t("SELECTIONNEZ_ETAPE_CONSTITUTION_FEUILLE_MATCH")}
        matchHasArtbiter={matchHasArtbiter}
      />
      <StickyHeadTable
        loading={loading}
        columns={columns}
        rows={filter}
        error={error}
        cursor={"cursor-pointer"}
        headerColor={"#1554A2"}
        headerLabelsColor={"white"}
        initialRowsPerPage={initialRowsPerPage}
        textAlign={"center"}
        ReloadTable={ReloadTable}
        setReloadTable={setReloadTable}
      />
      {openModalConfirmation && (
        <Dialog
          open={openModalConfirmation}
          onClose={handleCloseModalConfirmation}
          fullWidth
          maxWidth={"lg"}
        >
          <MatchEventsConfirmationPage
            currentData={filter}
            setCurrentData={setFilter}
            matchSelected={RowSelected}
            reload={() => setLoadData(true)}
            onClose={handleCloseModalConfirmation}
          />
        </Dialog>
      )}
      {openModalCommentRefus && (
        <Dialog
          open={openModalCommentRefus}
          onClose={handleColseModalComment}
          fullWidth
          maxWidth={"lg"}
        >
          <MatchRefusalDisplay
            matchData={RowSelected}
            onClose={handleColseModalComment}
          />
        </Dialog>
      )}
    </div>
  );
};

export default HomePage;

import { combineReducers, applyMiddleware, legacy_createStore } from "redux";
import { thunk } from "redux-thunk";
import matchReducer from "./reducers/matchReducer";
import matchEventsReducer from "./reducers/matchEventsReducer";
import { saveStateToLocalStorage } from "./selectors/matchSelectors";
import themeConfigReducer from "./reducers/themeConfigReducer";

const rootReducer = combineReducers({
  matchReducer,
  matchEventsReducer,
  themeConfig: themeConfigReducer
});

const storeRedux = legacy_createStore(rootReducer, applyMiddleware(thunk));

storeRedux.subscribe(() => {
  saveStateToLocalStorage(storeRedux.getState().matchReducer, "matchSatate");
  saveStateToLocalStorage(
    storeRedux.getState().matchEventsReducer,
    "matchEventsSatate"
  );
});

export default storeRedux;

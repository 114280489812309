import React, { useState, useEffect, useContext } from 'react';
import MultiColumn from '../components/DataTables/MultiColumn';
import { formatDateByDayName } from '../utils/Utils';
import { useTranslation } from 'react-i18next';
import sortBy from 'lodash/sortBy';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import ModalFormMatch from '../components/Modal/ModalFormMatch';
import { deleteMatch, getAllArbiters, getAllClubs, getAllCompetition, getAllMatches, getAllStades } from '../service/matcheService';
import { useNavigate } from 'react-router-dom';
import { customOption, customOptionWithAll, customOptionWithTranslate, divisionsData, roundData, statusOptionsDtn } from '../utils/selectesData';
import Swal from 'sweetalert2';
import { MobileContext } from '../context/isMobileContext';
import GroupedActionDTNButtons from '../components/Buttons/GroupedActionDTNButtons';
import { Check, Close } from '@mui/icons-material';
import MatchEventsConfirmationPage from '../../../pages/MatchEventsConfirmationPage';
import { Dialog } from "@mui/material";
import MatchRefusalDisplay from '../../../components/layouts/MatchRefusalDisplay';

export default function MatchesPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [uniqueArbiters, setUniqueArbiters] = useState([]);
    const [uniqueTeams, setUniqueTeams] = useState([]);
    const [uniqueStades, setUniqueStades] = useState([]);
    const [uniqueCompetitions, setUniqueCompetitions] = useState([]);
    const [rowData, setData] = useState([]);
    const [loadDataAfterAddNewMatch, setLoadDataAfterAddNewMatch] = useState(false);
    const [initialRecords, setInitialRecords] = useState(sortBy(rowData, 'date'));
    const [search, setSearch] = useState('');
    const [dateFilter, setDateFilter] = useState(null);
    const [selectedCompetition, setSelectedCompetition] = useState("");
    const [selectedArbiter, setSelectedArbiter] = useState(null);
    const [selectedDivsCategory, setSelectedDivsCategory] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [OpenModalCreateMatch, setOpenModalCreateMatch] = useState(false);
    const [selectedRound, setSelectedRound] = useState(null);
    const [SelectedMatch, setSelectedMatch] = useState(null);
    const isMobile = useContext(MobileContext);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
    const [openModalCommentRefus, setOpenModalCommentRefus] = useState(false);
    const [RowSelected, setRowSelected] = useState(null);

    const handleCloseModalConfirmation = () => {
        setOpenModalConfirmation(false)
        setRowSelected(null)
    }
    const handleColseModalComment = () => {
        setOpenModalCommentRefus(false)
        setRowSelected(null)
    }


    const fetchArbiters = async () => {
        try {
            const response = await getAllArbiters();
            const arbiters = response.data;

            const uniqueArbitersMap = new Map();

            arbiters?.forEach(item => {
                const name = `${item.nom} ${item.prenom}`;
                if (!uniqueArbitersMap.has(name)) {
                    uniqueArbitersMap.set(name, { id: item.id.toString(), image: item.image });
                }
            });

            const uniqueArbiters = Array.from(uniqueArbitersMap, ([name, { id, image }]) => ({
                value: id,
                label: name,
                logo: image
            }));

            setUniqueArbiters(uniqueArbiters);
        } catch (error) {
            console.error("Error fetching arbiters:", error);
        }
    };
    const fetchClubs = async () => {
        try {
            const response = await getAllClubs();
            const clubs = response.data;

            const uniqueTeamsMap = new Map();
            clubs.equipe?.forEach(item => {
                if (!uniqueTeamsMap.has(item.name)) {
                    uniqueTeamsMap.set(item.name, { id: item.id.toString(), image: item.image });
                }
            });

            const uniqueTeams = Array.from(uniqueTeamsMap, ([name, { id, image }]) => ({
                value: id,
                label: name,
                logo: image
            }));

            setUniqueTeams([{ value: 'all', label: t('ALL') }, ...uniqueTeams]);
        } catch (error) {
            console.error("Error fetching clubs:", error);
        }
    };
    const fetchStades = async () => {
        try {
            const response = await getAllStades();
            const stads = response.data;

            const uniqueStadeMap = new Map();
            stads?.stade.forEach(item => {
                if (!uniqueStadeMap.has(item.name)) {
                    uniqueStadeMap.set(item.name, item.id.toString());
                }
            });

            const uniqueStade = Array.from(uniqueStadeMap, ([slug, id]) => ({ value: id, label: slug }));

            setUniqueStades(uniqueStade);
        } catch (error) {
            console.error("Error fetching clubs:", error);
        }
    };
    const fetchCompetitions = async () => {
        try {
            const response = await getAllCompetition();
            const comp = response.data;

            const uniqueCompMap = new Map();
            comp?.forEach(item => {
                if (!uniqueCompMap.has(item.name)) {
                    uniqueCompMap.set(item.name, { id: item.id.toString(), image: item.image });
                }
            });

            const uniqueCompetitions = Array.from(uniqueCompMap, ([name, { id, image }]) => ({
                value: id,
                label: name,
                logo: image
            }));

            setUniqueCompetitions([{ value: 'all', label: t('ALL') }, ...uniqueCompetitions]);
        } catch (error) {
            console.error("Error fetching competitions:", error);
        }
    };
    const listOfMatches = async () => {
        try {
            const response = await getAllMatches();
            const comp = response.data;
            const rowData = comp?.data?.map(item => (
                {
                    id: item?.id,
                    equipeAway: {
                        id: item?.equipeAway?.id,
                        name: item?.equipeAway?.name,
                        slug: item?.equipeAway?.slug,
                        logo: item?.equipeAway?.image,
                        scoore: item.but_away,
                        isPrepared: item?.hasFeuilleMatchAway,
                        hasExcel: item?.status?.toLowerCase() == "played" ? true : false
                    },
                    equipeHome: {
                        id: item?.equipeHome?.id,
                        name: item?.equipeHome?.name,
                        slug: item?.equipeHome?.slug,
                        logo: item?.equipeHome?.image,
                        scoore: item.but_home,
                        isPrepared: item?.hasFeuilleMatchHome,
                        hasExcel: item?.status?.toLowerCase() == "played" ? true : false
                    },
                    category_divisions: item?.category_divisions,
                    match: item,
                    arbiter: item?.Arbitres,
                    stade: item?.Stade,
                    date: item?.match_date ? `${item?.match_date + " " + item?.match_time}` : "-",
                    competition: {
                        id: item?.competition_id,
                        name: item?.Competition?.name,
                        logo: item?.Competition?.image
                    },
                    round: item?.round && item?.round,
                    matchStatus: item?.status
                }
            ));
            setData(rowData)

        } catch (error) {
            console.error("Error fetching clubs:", error);
        }
    };

    useEffect(() => {
        filterRecords();
    }, [rowData, search, dateFilter, selectedRound, selectedStatus, selectedCompetition, selectedArbiter, selectedDivsCategory, selectedTeam]);

    useEffect(() => {
        listOfMatches();
        fetchCompetitions();
        fetchStades();
        fetchClubs();
        fetchArbiters();
    }, []);

    useEffect(() => {
        if (loadDataAfterAddNewMatch) {
            listOfMatches();
            setLoadDataAfterAddNewMatch(false);
        }
    }, [loadDataAfterAddNewMatch]);

    const filterRecords = () => {
        let filteredRecords = rowData.filter(item => {
            if (search?.trim() !== '') {
                const searchTerms = search.toLowerCase().split(' ');
                const match = searchTerms.some(term =>
                    item?.equipeAway?.name.toLowerCase().includes(term) ||
                    item?.equipeHome?.name.toLowerCase().includes(term) ||
                    item?.arbiter?.nom.toLowerCase().includes(term) ||
                    item?.arbiter?.prenom.toLowerCase().includes(term) ||
                    item?.stade?.toLowerCase().includes(term) ||
                    item?.date?.toLowerCase().includes(term) ||
                    item?.category_divisions?.toLowerCase().includes(selectedDivsCategory)
                );
                if (!match) return false;
            }

            if (dateFilter && dateFilter.length > 0) {
                const itemDate = new Date(item.date);
                const filterDate = new Date(dateFilter[0]);

                itemDate.setHours(0, 0, 0, 0);
                filterDate.setHours(0, 0, 0, 0);

                if (itemDate.getTime() !== filterDate.getTime()) return false;
            }

            if (selectedCompetition && selectedCompetition?.value !== "all" && selectedCompetition.value !== '' && item.competition.name !== selectedCompetition.label) {
                return false;
            }

            if (selectedArbiter && selectedArbiter.value !== '' && item?.arbiter?.nom + " " + item?.arbiter?.prenom != selectedArbiter?.label) {
                return false;
            }
            if (selectedDivsCategory && selectedDivsCategory?.value !== "all" && selectedDivsCategory.value !== '' && item?.category_divisions != selectedDivsCategory?.label) {
                return false;
            }

            if (selectedTeam && selectedTeam?.value != "all" && selectedTeam.value !== '' && item.equipeAway.name !== selectedTeam.label && item.equipeHome.name !== selectedTeam.label) {
                return false;
            }
            if (selectedRound && selectedRound?.value != "all" && selectedRound.value !== '' && item.round !== selectedRound.label && item.round !== selectedRound.label) {
                return false;
            }
            if (selectedStatus && selectedStatus?.value != "all" && selectedStatus.value !== '' && (item.matchStatus == "played") !== (selectedStatus.label == "joué")) {
                return false;
            }
            if (selectedStatus && selectedStatus?.value != "all" && selectedStatus.value !== '' && (item.matchStatus == "refused") !== (selectedStatus.label == "refused")) {
                return false;
            }

            return true;
        });

        setInitialRecords(sortBy(filteredRecords, 'date').reverse());
    };
    const resetFilters = () => {
        setSearch('');
        setDateFilter(null);
        setSelectedCompetition(null);
        setSelectedArbiter(null);
        setSelectedDivsCategory(null);
        setSelectedRound(null);
        setSelectedTeam(null);
        setSelectedStatus(null);
    };

    const columns = [
        {
            accessor: 'category_divisions',
            title: t("CATEGORY"),
            sortable: false,
            key: 'category_divisions',
            render: ({ category_divisions }) => {
                return (
                    <div className="flex items-center w-max font-bold text-black">
                        #{category_divisions}
                    </div>
                );
            },
        },
        {
            accessor: 'round',
            title: t('ROUND'),
            sortable: false,
            render: ({ round }) => (
                <div className="flex items-center w-max" >
                    {round ? round : "-"}
                </div>
            ),
        },
        {
            accessor: 'date',
            title: t('DATE'),
            sortable: false,
            render: ({ date }) => (<div key={date}>{formatDateByDayName(new Date(`${date}`))}</div>),
        },
        {
            accessor: 'equipeHome',
            title: t('TEAM_HOME'),
            sortable: false,
            render: ({ equipeHome }) => (
                <div className="flex items-center w-max" key={equipeHome.name}>
                    <img className="w-9 h-9 ltr:mr-2 rtl:ml-2 object-cover" src={equipeHome?.logo} alt={equipeHome?.name} />
                    <div>{equipeHome?.name}</div>
                    {equipeHome?.isPrepared ? <Check className={`w-8 h-8 text-green-400`} /> : <Close className="text-red-600" />}
                </div>
            ),
        },
        {
            accessor: 'equipeAway',
            title: t('TEAM_AWAY'),
            sortable: false,
            render: ({ equipeAway }) => (
                <div className="flex items-center w-max" key={equipeAway.name}>
                    <img className="w-9 h-9 ltr:mr-2 rtl:ml-2 object-cover" src={equipeAway?.logo} alt={equipeAway?.name} />
                    <div>{equipeAway?.name} </div>
                    {equipeAway?.isPrepared ? <Check className={`w-8 h-8 text-green-400`} /> : <Close className="text-red-600" />}
                </div>
            ),
        },
        {
            accessor: 'scoore',
            title: t('SCORE'),
            sortable: false,
            render: (match) => (
                <div className="flex items-center w-max" key={match.id}>
                    {match?.equipeHome?.hasExcel || match?.equipeAway?.hasExcel ? match.equipeHome?.scoore + " - " + match.equipeAway?.scoore : "-"}
                </div>
            ),
        },
        {
            accessor: 'match', title: t("STATUS"), sortable: false,
            render: ({ match }) => (
                <div className={` ${match?.status == "Not played yet"
                    ? "text-gray-600" :
                    match?.status == "refused"
                        ? "text-red-600"
                        : match?.status == "waiting_confirmation"
                            ? "text-orange-600"
                            : "text-green-600"
                    } font-bold py-2 px-4 font-myteam rounded focus:outline-none focus:shadow-outline`}
                >
                    {t(match?.status)}
                </div>
            ),
        },
        {
            accessor: 'id',
            title: t('ACTION'),
            sortable: false,
            render: (match) => (
                <GroupedActionDTNButtons
                    match={match}
                    t={t}
                    handleEdit={handleEdit}
                    navigate={navigate}
                    showAlert={showAlert}
                    setOpenModalConfirmation={setOpenModalConfirmation}
                    setOpenModalCommentRefus={setOpenModalCommentRefus}
                    setRowSelected={setRowSelected}
                />
            ),
        },
    ];

    const handleEdit = (match) => {
        setSelectedMatch(match);
        setOpenModalCreateMatch(true);
    };
    const showAlert = async (matchId) => {
        Swal.fire({
            icon: 'warning',
            title: t('ARE_YOU_SURE'),
            text: t("YOU_WONT_BE_ABLE_TO_REVERT_THIS"),
            showCancelButton: true,
            confirmButtonText: t('DELETE'),
            cancelButtonText: t('CANCEL'),
            padding: '2em',
            customClass: 'sweet-alerts',
        }).then(async (result) => {
            if (result.value) {
                try {
                    await deleteMatch(matchId);
                    setData(prevRecords => prevRecords.filter(record => record.id !== matchId));
                    Swal.fire({
                        title: t('DELETED'),
                        text: t('YOUR_FILE_HAS_BEEN_DELETED'),
                        icon: 'success',
                        customClass: 'sweet-alerts',
                    });
                } catch (error) {
                    console.error("Error deleting registration:", error);
                }
            }
        });
    };


    return (
        <>
            <div className='flex justify-end my-2'>
                <button className='btn btn-primary text-end' onClick={() => setOpenModalCreateMatch(true)}>{t('CREATE_MATCH')}</button>
            </div>
            <div className={`bg-white shadow-md rounded-lg ${isMobile ? "p-1" : "p-6"}`}>
                <div className="flex flex-col items-start">
                    <h1 className={`font-bold mb-4 ${isMobile ? " self-center text-xl" : "text-2xl "}`}>{t('LIST_OF_MATCHES')}</h1>
                    <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-6 gap-6 w-full items-center">
                        <Select
                            placeholder={t("FILTER_BY_COMPETITION")}
                            options={uniqueCompetitions}
                            className="p-2 rounded-md shadow-sm focus:z-9999 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
                            value={selectedCompetition}
                            onChange={(selectedOption) => setSelectedCompetition(selectedOption)}
                            components={{ Option: customOption }}

                        />
                        {/* <Select
                        placeholder={t("FILTER_BY_ARBITER")}
                        options={uniqueArbiters}
                        className="p-2 rounded-md shadow-sm z-50 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
                        value={selectedArbiter}
                        onChange={(selectedOption) => setSelectedArbiter(selectedOption)}
                        components={{ Option: customOption }}
                    /> */}
                        <Select
                            placeholder={t("FILTER_BY_CATRGORY")}
                            options={divisionsData}
                            components={{
                                Option: ({ data, innerRef, innerProps }) => customOptionWithAll({ data, innerRef, innerProps, t })
                            }}
                            getOptionLabel={(option) => <span>{option.label == 'ALL' ? t(option?.label) : option.label}</span>}
                            className="p-2 rounded-md shadow-sm focus:z-9999 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
                            value={selectedDivsCategory}
                            onChange={(selectedOption) => setSelectedDivsCategory(selectedOption)}
                        />
                        <Select
                            placeholder={t("FILTER_BY_STATUS")}
                            options={statusOptionsDtn}
                            className="p-2 rounded-md shadow-sm focus:z-9999 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
                            value={selectedStatus}
                            components={{
                                Option: ({ data, innerRef, innerProps }) =>
                                    customOptionWithTranslate({ data, innerRef, innerProps, t }),
                            }}
                            getOptionLabel={(option) => <p>{t(option.label)}</p>}
                            onChange={(selectedOption) =>
                                setSelectedStatus(selectedOption)
                            }
                            styles={{
                                control: (base, state) => ({
                                    ...base,
                                    zIndex: state.isFocused ? 9999 : "auto",
                                }),
                                menu: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                }),
                            }}
                        />
                        <Select
                            placeholder={t("FILTER_BY_TEAM")}
                            options={uniqueTeams}
                            className="p-2 rounded-md shadow-sm focus:z-9999 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
                            value={selectedTeam}
                            onChange={(selectedOption) => setSelectedTeam(selectedOption)}
                            components={{ Option: customOption }}
                        />
                        <Select
                            placeholder={t("FILTER_BY_ROUND")}
                            options={roundData}
                            getOptionLabel={(option) => (
                                <span>
                                    {option.label == "ALL" ? t(option?.label) : option.label}
                                </span>
                            )}
                            components={{
                                Option: ({ data, innerRef, innerProps }) =>
                                    customOptionWithAll({ data, innerRef, innerProps, t }),
                            }}
                            className="p-2 rounded-md shadow-sm focus:z-9999 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
                            value={selectedRound}
                            onChange={(selectedOption) => setSelectedRound(selectedOption)}
                        />
                        <div className="col-span-1 md:col-span-2 lg:col-span-1">
                            <Flatpickr
                                value={dateFilter}
                                placeholder={t('FILTER_BY_DATE')}
                                options={{ dateFormat: 'Y-m-d' }}
                                className="form-input p-2 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
                                onChange={(dates) => setDateFilter(dates)}
                            />
                        </div>
                        <div className="flex items-center max-w-sm:justify-end">
                            <button type="button" className="btn btn-primary focus:z-9999" onClick={resetFilters}>
                                {t("RESET_FILTER")}
                            </button>
                        </div>
                    </div>
                </div>

                <MultiColumn
                    search={search}
                    setSearch={setSearch}
                    setInitialRecords={setInitialRecords}
                    initialRecords={initialRecords}
                    rowData={rowData}
                    columns={columns}
                    enableExport={false}
                />
                {
                    OpenModalCreateMatch && <ModalFormMatch SelectedMatch={SelectedMatch} setSelectedMatch={setSelectedMatch} loadListOfMatches={setLoadDataAfterAddNewMatch} stades={uniqueStades} competitions={uniqueCompetitions} arbiters={uniqueArbiters} clubs={uniqueTeams} setModal20={setOpenModalCreateMatch} modal20={OpenModalCreateMatch} />
                }
            </div>
            {openModalConfirmation && RowSelected && (
                <Dialog
                    open={openModalConfirmation}
                    onClose={handleCloseModalConfirmation}
                    fullWidth
                    maxWidth={"lg"}
                >
                    <MatchEventsConfirmationPage
                        currentData={() => { }}
                        setCurrentData={() => { }}
                        matchSelected={{
                            match: RowSelected,
                        }}
                        onClose={handleCloseModalConfirmation}
                    />
                </Dialog>
            )}
            {openModalCommentRefus && (
                <Dialog
                    open={openModalCommentRefus}
                    onClose={handleColseModalComment}
                    fullWidth
                    maxWidth={"lg"}
                >
                    <MatchRefusalDisplay matchData={RowSelected} onClose={handleColseModalComment} />
                </Dialog>
            )}
        </>
    );
}

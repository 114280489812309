import React, { useEffect, useState } from 'react'
import "../../soccer/imports";
import Overview from '../MatchTabs/Overview';
import LineUps from '../MatchTabs/LineUps';
import { useLocation, useNavigate } from 'react-router-dom';
import MatchScore from '../../../../components/layouts/MatchScoore';
import { useTranslation } from 'react-i18next';
import { getSingleMatch } from '../../service/matcheService';
import Loading from "../../../../components/Loadings/Loading";
import GameTimeline from '../GameTimeline';
import MatchInfoDetails from '../MatchTabs/MatchInfoDetails';
import DispalySignateur from './DispalySignateur';


export default function MatchDetails() {
    const { t } = useTranslation();
    const [tabs, setTabs] = useState(1)
    const [loading, setLoading] = useState(true)
    const location = useLocation();
   
    const { matchId, match } = location.state || {};
    const navigate = useNavigate()
    const [response, setResponse] = useState({ match: {}, events: [] })

    useEffect(() => {
        const singleMatch = async (id) => {
            try {
                const response = await getSingleMatch(id);
                setResponse(response?.data)
                setLoading(false)
            } catch (error) {
                console.log(error)
            }
        }
        if (!matchId || matchId == null) {
            navigate("/")
        }

        if (matchId) {
            singleMatch(matchId)
        }
    }, [matchId])

    if (loading) {
        return <Loading />
    }

    return (
        <>
            <div className="alc-event-header alc-event-header--layout-2">
                <MatchScore match={match} />
                {
                    response?.events?.length > 0 &&
                    (
                        <nav className="content-filter">
                            <ul className="flex content-filter__FILE_SIZE_TOO_LARGElist">
                                <li className={`content-filter__item ${tabs === 1 && "content-filter__item--active"} cursor-pointer`}>
                                    <a onClick={() => setTabs(1)} className="content-filter__link">
                                        {t('MATCH_OVERVIEW')}
                                    </a>
                                </li>
                                <li className={`content-filter__item ${tabs === 2 && "content-filter__item--active"} cursor-pointer`}>
                                    <a onClick={() => setTabs(2)} className="content-filter__link">
                                        {t('MATCH_BOX_SCORE')}
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    )
                }
            </div>
            {
                response?.events?.length > 0&&tabs==1 &&
                <div className='my-2'>
                    <GameTimeline events={response?.events} teamHome={response?.match?.equipeHome} teamAway={response?.match?.equipeAway} />
                </div>
            }
            {
                response?.events?.length > 0 && tabs == 1 && <Overview events={response?.events} match={response?.match} />
            }
            {
                response?.events?.length > 0 && tabs == 2 && <LineUps
                    events={response?.events} match={response?.match} />
            }
            {
                response?.events?.length == 0 &&
                <MatchInfoDetails response={response} />
            }
            <div className='flex items-center'>
                {response?.match_sign?.find(m => m.equipe_id == response?.match?.equipeHome?.id) && (
                    <DispalySignateur sign={response?.match_sign.find(m => m.equipe_id == response?.match?.equipeHome?.id)} team={response?.match?.equipeHome} />
                )}

                {response?.match_sign?.find(m => m.equipe_id == response?.match?.equipeAway?.id) && (
                    <DispalySignateur sign={response?.match_sign.find(m => m.equipe_id == response?.match?.equipeAway?.id)} team={response?.match?.equipeAway} />
                )}
            </div>
        </>
    )
}

import { jwtDecode } from "jwt-decode";
import axios from "axios";
import axiosInstance from "../axiosConfig";

export const login = async (email, password) => {
  try {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_API_ENDPOINT_AUTH}login`,
      { email, password },
      { withCredentials: true }
    );
    const token = response?.data?.response;
    localStorage.setItem("myteamConnectToken", token);
    const decodedToken = jwtDecode(token);
    return decodedToken;
  } catch (error) {
    console.log("error : ",error)
    if (axios.isAxiosError(error)) {
      if (error.response?.status && error.response?.status === 401) {
        throw new Error("ACCOUNT_NOT_ACTIVE");
      } else {
        throw new Error("LOGIN_FAILED_CREDENTIALS");
      }
    } else {
      throw new Error("LOGIN_FAILED_NETWORK");
    }
  }
};

export const logout = async () => {
  localStorage?.removeItem("myteamConnectToken");
  localStorage?.removeItem("teamsCompositionValidate");
  localStorage?.removeItem("matchEventsSatate");
  localStorage?.removeItem("matchSatate");
  try {
    await axiosInstance.post(
      `${process.env.REACT_APP_API_ENDPOINT_AUTH}logout`
    );
  } catch (error) {
    console.log("LOGOUT_FAILED : ",error);
    throw new Error("LOGOUT_FAILED");
  }
};

export const logoutSign = async () => {
  localStorage.removeItem("myteamConnect_TeamSignedToken");
};
export const loginSign = async (email, password) => {
  logoutSign();
  try {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_API_ENDPOINT_AUTH}login-match`,
      { email, password },
      { withCredentials: true }
    );
    const token = response?.data?.response;
    localStorage.setItem("myteamConnect_TeamSignedToken", token);
    const decodedToken = jwtDecode(token);
    return decodedToken;
  } catch (error) {
    logoutSign();
    if (axios.isAxiosError(error)) {
      if (error.response.status === 401) {
        throw new Error("ACCOUNT_NOT_ACTIVE");
      } else {
        throw new Error("LOGIN_FAILED_CREDENTIALS");
      }
    } else {
      throw new Error("LOGIN_FAILED_NETWORK");
    }
  }
};

import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Dropdown from '../Dropdown';
import IconMenu from '../Icon/IconMenu';
import IconLogout from '../Icon/IconLogout';
import languages from '../../../../locales/LanguagesSupport';
import { AuthContext } from '../../../../contexts/AuthContext';
import { toggleSidebar } from '../../../../redux/actions/backOfficeActions';
import { MobileContext } from '../../context/isMobileContext';
const Header = () => {
    const location = useLocation();
    const isMobile=useContext(MobileContext)
    useEffect(() => {
        const selector = document.querySelector('ul.horizontal-menu a[href="' + window.location.pathname + '"]');
        if (selector) {
            selector.classList.add('active');
            const all = document.querySelectorAll('ul.horizontal-menu .nav-link.active');
            for (let i = 0; i < all.length; i++) {
                all[0]?.classList.remove('active');
            }
            const ul = selector.closest('ul.sub-menu');
            if (ul) {
                let ele = ul.closest('li.menu').querySelectorAll('.nav-link');
                if (ele) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele?.classList.add('active');
                    });
                }
            }
        }
    }, [location]);
    const themeConfig = useSelector((state) => state.themeConfig);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { user, logout } = useContext(AuthContext)

    return (<header className={`z-40 ${themeConfig.semidark && themeConfig.menu === 'horizontal' ? 'dark' : ''}`}>
        <div className="shadow-sm">
            <div className="relative bg-white flex w-full items-center px-5 py-2.5 dark:bg-black">
                <div className="horizontal-logo flex lg:hidden justify-between items-center ltr:mr-2 rtl:ml-2">

                    {(themeConfig?.sidebar||isMobile) && <Link to="/dashboard" className="main-logo flex items-center shrink-0">
                        <img className="w-36 ltr:-ml-1 rtl:-mr-1 inline" src="/assets/images/logos/myteamConnect.png" alt="logo" />
                    </Link>}
                    <button type="button" className="collapse-icon flex-none dark:text-[#d0d2d6] hover:text-primary dark:hover:text-primary flex lg:hidden ltr:ml-2 rtl:mr-2 p-2 rounded-full object-cover bg-white-light/40 dark:bg-dark/40 hover:bg-white-light/90 dark:hover:bg-dark/60" onClick={() => {
                        dispatch(toggleSidebar());
                    }}>
                        <IconMenu className="w-5 h-5" />
                    </button>
                </div>

                <div className="sm:flex-1 ltr:sm:ml-0 ltr:ml-auto sm:rtl:mr-0 rtl:mr-auto flex items-center space-x-1.5 lg:space-x-2 rtl:space-x-reverse dark:text-[#d0d2d6]">
                    <div className="sm:ltr:mr-auto sm:rtl:ml-auto"></div>
                    <div className="dropdown shrink-0">
                        <Dropdown
                            offset={[0, 8]}
                            placement="bottom-end"
                            btnClassName="block p-2 rounded-full object-cover bg-white-light/40 dark:bg-dark/40 hover:text-primary hover:bg-white-light/90 dark:hover:bg-dark/60"
                            button={
                                <img
                                    className="w-5 h-5 object-cover rounded-full object-cover"
                                    src={`/assets/images/flags/${i18next.language}.${i18next.language === 'ar' ? 'png' : 'svg'}`}
                                    alt="flag"
                                />
                            }
                        >
                            <ul className="!px-2 text-dark dark:text-white-dark grid grid-cols-2 gap-2 font-semibold dark:text-white-light/90 w-[280px]">
                                {languages.map((item) => {
                                    return <li key={item.code}>
                                        <button
                                            type="button"
                                            className={`flex w-full hover:text-primary rounded-lg ${i18next.language === item.code ? 'bg-primary/10 text-primary' : ''}`}
                                            onClick={() => i18next.changeLanguage(item.code)}
                                        >
                                            <img
                                                src={`/assets/images/flags/${item?.code?.toUpperCase()}.${item?.code === 'ar' ? 'png' : 'svg'}`}
                                                alt="flag"
                                                className="w-5 h-5 object-cover rounded-full object-cover"
                                            />
                                            <span className="ltr:ml-3 rtl:mr-3">{item?.name}</span>
                                        </button>
                                    </li>

                                })}
                            </ul>
                        </Dropdown>
                    </div>

                    {/* <div className="dropdown shrink-0">
                        <Dropdown
                            offset={[0, 8]}
                            placement="bottom-end"
                            btnClassName="relative block p-2 rounded-full object-cover bg-white-light/40 dark:bg-dark/40 hover:text-primary hover:bg-white-light/90 dark:hover:bg-dark/60"
                            button={<img className="w-6 h-6 rounded-full object-cover object-cover saturate-50 group-hover:saturate-100" src={user?.image_equipe} alt="Team" />}
                        />
                    </div> */}

                    <div className="dropdown shrink-0 flex">
                        <Dropdown
                            offset={[0, 8]}
                            placement="bottom-end"
                            btnClassName="relative group block"
                            button={<img className="w-9 h-9 rounded-full object-cover object-cover saturate-50 group-hover:saturate-100" src={user?.img} alt="userProfile" />}
                        >
                            <ul className="text-dark dark:text-white-dark !py-0 w-[230px] font-semibold dark:text-white-light/90">
                                <li>
                                    <div className="flex items-center px-4 py-4">
                                        <img className="rounded-md w-10 h-10 object-cover" src={user?.img} alt="userProfile" />
                                        <div className="ltr:pl-4 rtl:pr-4 truncate">
                                            <span className={`text-xs ${user?.role == "dtn" ? "bg-success-light text-success" : "bg-orange-300 text-orange-700"} rounded  px-4`}>{user?.role?.toUpperCase()}</span>

                                            <h4 className="text-base">
                                                {user?.nom + " " + user?.prenom}
                                            </h4>
                                            <button type="button" className="text-black/60 hover:text-primary dark:text-dark-light/60 dark:hover:text-white">
                                                {user?.email}
                                            </button>
                                        </div>
                                    </div>
                                </li>
                                <li className="border-t border-white-light dark:border-white-light/10">
                                    <Link to="/login" onClick={() => logout()} className="text-danger !py-3">
                                        <IconLogout className="w-4.5 h-4.5 ltr:mr-2 rtl:ml-2 rotate-90 shrink-0" />
                                        {t("LOGOUT")}
                                    </Link>
                                </li>
                            </ul>
                        </Dropdown>
                    </div>
                </div>
            </div>
        </div>
    </header>);
};
export default Header;


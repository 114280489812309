export const validateEmail = (email) => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return regex.test(email);
};

export const validatePhoneNumber = (phoneNumber) => {
  const regex = /^\d{3}-\d{3}-\d{4}$/;
  return regex.test(phoneNumber);
};
export const validatePassword = (password) => {
  // Password must be at least 8 characters long,
  // contain at least one uppercase letter,
  // one lowercase letter, one number,
  // and one special character
  const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/;
  return regex.test(password);
};

import React from 'react'
import { ClockIcon } from '@mui/x-date-pickers';
import { Group, Stadium } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { formatDateByDayName } from '../../utils/Utils';

export default function MatchInfoDetails({ response }) {
    const { t } = useTranslation();

    return (
        <div className="bg-white shadow-md rounded-lg p-6 mx-auto mt-5">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-4">
                    <h2 className="font-bold text-lg mb-3">{t('MATCH_INFORMATIONS')}</h2>
                    <div className="flex items-center">
                        <ClockIcon className="w-5 h-5 mr-2 text-blue-500" />
                        <span>{formatDateByDayName(new Date(`${response?.match.match_date} ${response?.match.match_time}`))}</span>
                    </div>
                    <div className="flex items-center">
                        <Stadium className="w-5 h-5 mr-2 text-green-500" />
                        <span>{response?.match.Stade.name}</span>
                    </div>
                </div>
                {
                    response?.match.Arbitres?.length!=0 &&
                    <div>
                        <h2 className="font-bold text-lg mb-3 flex items-center">
                            <Group className="w-10 h-10 mr-2 text-blue-500" />
                            {t('ARBITERS_OF_MATCH')}
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            {response?.match.Arbitres.map((arbiter, index) => (
                                <div key={index} className="flex items-center bg-gray-50 rounded p-2">
                                    <img
                                        src={arbiter.image}
                                        alt={`${arbiter.nom} ${arbiter.prenom}`}
                                        className="w-10 h-10 rounded-full object-cover mr-3"
                                    />
                                    <div>
                                        <p className="font-semibold">{`${arbiter.nom} ${arbiter.prenom}`}</p>
                                        <p className="text-sm text-gray-600">{arbiter.function}</p>
                                        {arbiter.licenceId && <p className="text-xs text-gray-500">License: {arbiter.licenceId}</p>}
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                }

            </div>
        </div>)
}

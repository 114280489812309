import {
  Email,
  Phone,
} from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="pt-10 mt-5 lg:px-9 border-t-2 bg-gray-50 px-4">
      <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
        <div className="sm:col-span-2">
          <a href="#" className="inline-flex items-center">
            <img
              src="../../../assets/images/logos/myteamConnect.png"
              alt="logo"
              className="h-16 w-100"
            />
          </a>
        </div>
        {/* <div className="flex flex-col gap-2 text-sm">
          <p className="text-base font-myteam font-bold tracking-wide text-gray-900">
            {t("CONTACTS")}
          </p>
          <div className="flex font-myteam items-center">
            <p className="mr-1 text-gray-800">
              <Email />
            </p>
            <a href="#" title="send email">
              admin@company.com
            </a>
          </div>
          <div className="flex font-myteam items-center">
            <p className="mr-1 text-gray-800">
              <Phone />
            </p>
            <a href="#" title="send email">
              +123 456 7890
            </a>
          </div>
        </div> */}
         {/* <div>
          <p className="text-base font-myteam font-bold tracking-wide text-gray-900">
            {t('FOOTER.COMPANY_INFO.AVAILABLE')}
          </p>
           <div className="flex items-center gap-1 px-0">
            <a href="#" className="w-full min-w-xl">
              <img
                src="../../../assets/images/downloadAppSotre.png"
                alt="Playstore Button"
                className="h-11"
              />
            </a>
            <a href="#" className="w-full min-w-xl">
              <img
                src="../../../assets/images/downloadplaySotre.png"
                alt="Playstore Button"
                className="h-16"
              />
            </a>
          </div> 
        </div>  */}
      </div>
      <div className="flex flex-col-reverse justify-center pt-5 pb-10 border-t lg:flex-row">
        <p className="text-sm font-myteam text-gray-600">
          
          © {t("FOOTER.COPYRIGHT")} 2024 MyTeam {t("FOOTER.ALL_RIGHTS_RESERVED")}
        </p>
      </div>
    </footer>
  );
};

export default Footer;

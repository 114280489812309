import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import IconCaretsDown from '../Icon/IconCaretsDown';
import IconMenuDashboard from '../Icon/Menu/IconMenuDashboard';
import IconMenuCalendar from '../Icon/Menu/IconMenuCalendar';
import IconMenuDocumentation from '../Icon/Menu/IconMenuDocumentation';
import IconMenuForms from '../Icon/Menu/IconMenuForms';
import { useUserRole } from '../../context/UserRoleContext';
import IconMenu from '../Icon/IconMenu';
import { toggleSidebar } from '../../../../redux/actions/backOfficeActions';

const Sidebar = () => {
    const themeConfig = useSelector((state) => state.themeConfig);
    const semidark = useSelector((state) => state.themeConfig.semidark);
    const location = useLocation();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { role } = useUserRole();


    useEffect(() => {
        const selector = document.querySelector('.sidebar ul a[href="' + window.location.pathname + '"]');
        if (selector) {
            selector.classList.add('active');
            const ul = selector.closest('ul.sub-menu');
            if (ul) {
                let ele = ul.closest('li.menu').querySelectorAll('.nav-link') || [];
                if (ele.length) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele.click();
                    });
                }
            }
        }
    }, []);
    useEffect(() => {
        if (window.innerWidth < 1024 && themeConfig.sidebar) {
            dispatch(toggleSidebar());
        }
    }, [location]);
    return (<div className={semidark ? 'dark' : ''}>
        <nav className={`sidebar fixed min-h-screen h-full top-0 bottom-0 w-[260px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] z-50 transition-all duration-300 ${semidark ? 'text-white-dark' : ''}`}>
            <div className="bg-white dark:bg-black h-full">
                <div className="flex justify-between items-center px-4 py-3">
                    <NavLink to="/dashboard" className="main-logo flex items-center shrink-0">
                        <img className="w-36 ml-[5px] flex-none" src="/assets/images/logos/myteamConnect.png" alt="logo" />
                    </NavLink>

                    <button type="button" className="collapse-icon w-8 h-8 rounded-full object-cover flex items-center hover:bg-gray-500/10 dark:hover:bg-dark-light/10 dark:text-white-light transition duration-300 rtl:rotate-180" onClick={() => dispatch(toggleSidebar())}>
                        <IconCaretsDown className="m-auto rotate-90" />
                    </button>
                </div>

                <PerfectScrollbar className="h-[calc(100vh-80px)] relative">
                    <ul className="relative font-semibold space-y-0.5 p-4 py-0">
                        <li className="nav-item">
                            <ul>

                                {/* <li className="nav-item">
                                    <NavLink to="classement" className="group">
                                        <div className="flex items-center">
                                            <IconMenu className="group-hover:!text-primary shrink-0" />
                                            <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">{t('CLASSEMENT')}</span>
                                        </div>
                                    </NavLink>
                                </li> */}
                                {role == "dtn" && (
                                    <>
                                        <li className="nav-item">
                                            <NavLink to="statistics" className="group">
                                                <div className="flex items-center">
                                                    <IconMenu className="group-hover:!text-primary shrink-0" />
                                                    <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">{t('CLASSEMENT')}</span>
                                                </div>
                                                {/* <div className="flex items-center">
                                                    <IconMenuDashboard className="group-hover:!text-primary shrink-0" />
                                                    <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">{t('DASHBOARD')}</span>
                                                </div> */}
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="list-registration-requests" className="group">
                                                <div className="flex items-center">
                                                    <IconMenuDocumentation className="group-hover:!text-primary shrink-0" />
                                                    <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">{t('LIST_REQUESTS')}</span>
                                                </div>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="list-matches" className="group">
                                                <div className="flex items-center">
                                                    <IconMenuCalendar className="group-hover:!text-primary shrink-0" />
                                                    <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">{t('MATCHES')}</span>
                                                </div>
                                            </NavLink>
                                        </li>
                                    </>
                                )}
                                {role == "club" && (
                                    <>
                                        <li className="nav-item">
                                            <NavLink to="statistics" className="group">

                                                <div className="flex items-center">
                                                    <IconMenuDashboard className="group-hover:!text-primary shrink-0" />
                                                    <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">{t('DASHBOARD')}</span>
                                                </div>
                                            </NavLink>
                                        </li>
                                        <li className='nav-item'>
                                            <NavLink to="classement" className="group">
                                                <div className="flex items-center">
                                                    <IconMenu className="group-hover:!text-primary shrink-0" />
                                                    <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">{t('CLASSEMENT')}</span>
                                                </div>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="matches" className="group">
                                                <div className="flex items-center">
                                                    <IconMenuCalendar className="group-hover:!text-primary shrink-0" />
                                                    <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">{t('LIST_OF_MATCHES')}</span>
                                                </div>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="registration" className="group">
                                                <div className="flex items-center">
                                                    <IconMenuForms className="group-hover:!text-primary shrink-0" />
                                                    <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">{t('REGISTRATION_REQUSETS')}</span>
                                                </div>
                                            </NavLink>
                                        </li>
                                    </>
                                )}
                                <li className="nav-item">
                                    <NavLink to="list-accepted-registrations" className="group">
                                        <div className="flex items-center">
                                            <IconMenu className="group-hover:!text-primary shrink-0" />
                                            <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">{t('LIST_LICENCES')}</span>
                                        </div>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </PerfectScrollbar>
            </div>
        </nav>
    </div>);
};
export default Sidebar;
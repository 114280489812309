import React from "react";
import { useTranslation } from "react-i18next";

export default function ErrorCode({ code, title, message, callback, btnText,btnStyle }) {
  const { t } = useTranslation();

  return (
    <section className="flex items-center h-screen p-16 dark:bg-gray-50 dark:text-gray-800">
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div className="max-w-md text-center">
          <h2 className="mb-8 font-extrabold text-9xl dark:text-gray-400">
            {code}
          </h2>
          <p className="text-2xl font-myteam font-bold md:text-3xl">{t(title)}</p>
          <p className="mt-4 mb-8 font-myteam dark:text-gray-600">{t(message)}</p>
          {btnText && (
            <button
              rel="noopener noreferrer"
              onClick={() => callback()}
              style={btnStyle}
              className="px-8 py-3 font-myteam font-bold rounded dark:bg-violet-600 dark:text-gray-50"
            >
              {t(btnText)}
            </button>
          )}
        </div>
      </div>
    </section>
  );
}

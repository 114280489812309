import React from "react";
import { useSelector } from "react-redux";
import Formation from "../../LineUp/Formation";
import { Avatar } from "@mui/material";
import { Group } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export default function Compositions({ teamRecevant, teamVisiteuse }) {
  const teams = useSelector((state) => state.matchReducer.teams);
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-center align-bottom">
      <div className="flex w-full justify-between max-sm:w-full">
        {/* Team 1 */}
        <div className="flex items-start">
          <div>
            <div className="m-4">
              <h1 className="font-myteam p-4 text-center font-bold text-3xl">
                <Group className="m-2" /> {t("REMPLACANTS")}
              </h1>
              <ol className="bg-gray-100 p-4 rounded-lg">
                {teams[teamRecevant?.name]?.composition?.subs?.map(
                  (player, index) => (
                    <li key={index} className="mb-2 flex items-center">
                      <span className="text-lg font-medium mr-2">
                        {index + 1} |
                      </span>
                      <span className="flex-grow max-w-xs truncate">
                        {player?.nom + " " + player?.prenom} {player?.shirtNmbr}
                      </span>
                    </li>
                  )
                )}
              </ol>
            </div>
            {teams[teamRecevant?.name]?.composition?.staffs?.length > 0 ? (
              <div className="m-4">
                <h1 className="font-myteam p-4 text-center font-bold text-3xl">
                  <Group className="m-2" /> {t("ENCADREMENT")}
                </h1>
                <ol className="bg-gray-100 p-4 rounded-lg">
                  {teams[teamRecevant?.name]?.composition?.staffs?.map(
                    (coach, index) => (
                      <li key={index} className="mb-2 flex items-center">
                        <span className="text-lg font-medium mr-2">
                          {index + 1}
                        </span>
                        <span className="flex-grow max-w-xs truncate">
                          {coach?.nom + " " + coach?.prenom} ( {coach?.role} )
                        </span>
                      </li>
                    )
                  )}
                </ol>
              </div>
            ) : null}
          </div>
          <div className="w-1/2 h-[400px] max-sm:w-full relative ml-4 mr-4">
            <div className="flex items-center justify-center space-x-2">
              <Avatar
                alt={teamRecevant.name}
                src={teamRecevant.logo}
                className="w-10 h-10 rounded-full object-cover"
              />
              <span className="font-myteam font-semibold max-w-xs truncate">
                {teamRecevant?.name}
              </span>
            </div>
            <div className="text-end">
              <span className="font-myteam font-bold max-w-xs truncate">
                {teams[teamRecevant?.name].composition?.formation}
              </span>
            </div>
            <Formation
              players={teams[teamRecevant?.name].composition?.players}
              showFilterOptions={false}
              defaultFormation={
                teams[teamRecevant?.name].composition?.formation
              }
            />
          </div>
        </div>

        {/* Team 2 */}
        <div className="flex items-start">
          <div className="w-1/2 h-[400px] max-sm:w-full relative ml-4 mr-4">
            <div className="flex items-center justify-center space-x-2">
              <Avatar
                alt={teamVisiteuse.name}
                src={teamVisiteuse.logo}
                className="w-10 h-10 rounded-full object-cover"
              />
              <span className="font-myteam font-semibold max-w-xs truncate">
                {teamVisiteuse?.name}
              </span>
            </div>
            <div className="text-end">
              <span className="font-myteam font-bold max-w-xs truncate">
                {teams[teamVisiteuse?.name].composition?.formation}
              </span>
            </div>
            <Formation
              players={teams[teamVisiteuse?.name].composition?.players}
              showFilterOptions={false}
              defaultFormation={
                teams[teamVisiteuse?.name].composition?.formation
              }
            />
          </div>
          <div>
            <div className="m-4">
              <h1 className="font-myteam p-4 text-center font-bold text-3xl">
                <Group className="m-2" /> {t("REMPLACANTS")}
              </h1>
              <ol className="bg-gray-100 p-4 rounded-lg">
                {teams[teamVisiteuse?.name]?.composition?.subs?.map(
                  (player, index) => (
                    <li key={index} className="mb-2 flex items-center">
                      <span className="text-lg font-medium mr-2">
                        {index + 1} |
                      </span>
                      <span className="flex-grow max-w-xs truncate">
                        {player?.nom + " " + player?.prenom} {player?.shirtNmbr}
                      </span>
                    </li>
                  )
                )}
              </ol>
            </div>
            {teams[teamVisiteuse?.name]?.composition?.staffs?.length > 0 ? (
              <div className="m-4">
                <h1 className="font-myteam p-4 text-center font-bold text-3xl">
                  <Group className="m-2" /> {t("ENCADREMENT")}
                </h1>
                <ol className="bg-gray-100 p-4 rounded-lg">
                  {teams[teamVisiteuse?.name]?.composition?.staffs?.map(
                    (coach, index) => (
                      <li key={index} className="mb-2 flex items-center">
                        <span className="text-lg font-medium mr-2">
                          {index + 1}
                        </span>
                        <span className="flex-grow max-w-xs truncate">
                          {coach?.nom + " " + coach?.prenom} ( {coach?.role} )
                        </span>
                      </li>
                    )
                  )}
                </ol>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

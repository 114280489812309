import React, { useState, useCallback, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import ImageUploading from 'react-images-uploading';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import CropIcon from '@mui/icons-material/Crop';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import { updatePlayerImage } from '../../service/registrationService';

export default function ModalEditPlayerImage({ SelectedPlayer, setLoadData, setFilteredRecords, setSelectedPlayer, modal20, setModal20 }) {
    const { t } = useTranslation();
    const [images, setImages] = useState([]);
    const [crop, setCrop] = useState({ unit: '%', width: 80, aspect: 1 });
    const [isCropping, setIsCropping] = useState(false);
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);
    const [fullImageUrl, setFullImageUrl] = useState(null);
    const imgRef = useRef(null);


    const onImageChange = (imageList) => {
        setImages(imageList);
        setIsCropping(false);
        setCroppedImageUrl(null);
        setFullImageUrl(imageList[0]?.data_url);
    };

    const coloredToast = (color, message) => {
        const toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            showCloseButton: true,
            customClass: {
                popup: `color-${color}`,
            },
        });
        toast.fire({
            title: message,
        });
    };

    const getCroppedImg = useCallback((image, crop) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                resolve(blob);
            }, 'image/jpeg', 1);
        });
    }, []);

    const handleCropComplete = async (crop) => {
        if (imgRef.current && crop.width && crop.height) {
            const croppedImageBlob = await getCroppedImg(imgRef.current, crop);
            const croppedImageUrl = URL.createObjectURL(croppedImageBlob);
            setCroppedImageUrl(croppedImageUrl);
        }
    };

    const handleSubmit = async () => {
        if (images.length === 0) {
            coloredToast('danger', t("NO_IMAGE_SELECTED"));
            return;
        }
        try {
            const finalImage = croppedImageUrl || fullImageUrl;
            if (!finalImage) {
                coloredToast('danger', t("NO_IMAGE_SELECTED"));
                return;
            }

            const dateNaissance = SelectedPlayer?.licence?.date_naissance;
            const formattedDate = dateNaissance ? dateNaissance.split('-').reverse().join('/') : '';
            const fullName = `${SelectedPlayer?.nom}_${SelectedPlayer?.prenom}-${formattedDate}`;

            const formData = new FormData();
            const blob = await fetch(finalImage).then(res => res.blob());
            formData.append('fullName', fullName);
            formData.append('playerTeamId', SelectedPlayer?.equipe_id);
            formData.append('photoProfil', blob);
            formData.append('licence_id', SelectedPlayer?.licence?.licenceId);

            const uploadImage = await updatePlayerImage(formData)

            if (uploadImage?.status == 200) {
                setFilteredRecords((prev) => {
                    return prev?.map((player) => {
                        if (player.id === SelectedPlayer.id) {
                            return {
                                ...player,
                                picture: finalImage,
                                user: {
                                    ...player.user,
                                    licence: {
                                        ...player?.licence,
                                        photoProfil: finalImage
                                    }
                                }
                            };
                        }
                        return player;
                    });
                })

                setSelectedPlayer(null);
                setModal20(false);
                //setLoadData(true)
                coloredToast('success', t("IMAGE_UPDATE_SUCCESS"));
            } else {
                coloredToast('danger', t("IMAGE_UPDATE_FAILED"));
            }
        } catch (error) {
            console.error('Error updating image:', error);
            coloredToast('danger', t("IMAGE_UPDATE_ERROR"));
        }
    };

    return (
        <Transition appear show={modal20} as={Fragment}>
            <Dialog as="div" open={modal20} onClose={() => { setModal20(false) }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0" />
                </Transition.Child>
                <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen px-4 py-8">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="panel overflow-scroll border-0 p-0 rounded-lg w-full max-w-md text-black dark:text-white-dark bg-white dark:bg-gray-800 shadow-xl">
                                <div className="flex bg-gray-50 dark:bg-gray-700 items-center justify-between px-5 py-3 border-b border-gray-200 dark:border-gray-600">
                                    <h5 className="font-bold text-lg">
                                        {t("UPDATE_PLAYER_IMAGE")}
                                    </h5>
                                    <button onClick={() => { setModal20(false) }} type="button" className="text-gray-400 hover:text-gray-500 focus:outline-none">
                                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="p-5">
                                    <div className="flex justify-between space-x-2 mb-4">
                                        <button
                                            type="button"
                                            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md text-sm font-medium hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                            onClick={() => { setSelectedPlayer(null); setModal20(false) }}
                                        >
                                            {t("CANCEL")}
                                        </button>
                                        <button
                                            type="button"
                                            disabled={isCropping}
                                            className="px-4 py-2 bg-green-600 text-white disabled:bg-green-300 rounded-md text-sm font-medium hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                            onClick={handleSubmit}
                                        >
                                            {t("UPDATE")}
                                        </button>
                                    </div>
                                    <div className="mb-5 flex justify-center">
                                        <img
                                            src={croppedImageUrl || fullImageUrl || SelectedPlayer?.licence.photoProfil || "/assets/images/profil-default.jpg"}
                                            alt={`${SelectedPlayer?.prenom} ${SelectedPlayer?.nom}`}
                                            className="w-32 h-32 object-cover rounded-full object-cover border-4 border-blue-500 shadow-lg"
                                        />
                                    </div>

                                    <ImageUploading
                                        value={images}
                                        onChange={onImageChange}
                                        maxNumber={1}
                                        dataURLKey="data_url"
                                    >
                                        {({
                                            imageList,
                                            onImageUpload,
                                            onImageRemoveAll,
                                            isDragging,
                                            dragProps
                                        }) => (
                                            <div className="upload__image-wrapper">
                                                {imageList.length === 0 && (
                                                    <button
                                                        className={`w-full py-2 px-4 border border-dashed rounded-lg text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 ${isDragging
                                                            ? 'border-blue-500 bg-blue-50 text-blue-500'
                                                            : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50'
                                                            }`}
                                                        onClick={onImageUpload}
                                                        {...dragProps}
                                                    >
                                                        <CloudUploadIcon className="mx-auto h-12 w-12 text-gray-400" />
                                                        <p className="mt-1">{t("CLICK_OR_DROP")}</p>
                                                    </button>
                                                )}
                                                {imageList.map((image, index) => (
                                                    <div key={index} className="image-item mt-4">
                                                        <div className="image-item__btn-wrapper mb-4 flex justify-center space-x-2">
                                                            <button
                                                                className={`px-4 py-2 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 ${isCropping
                                                                    ? 'bg-green-600 text-white hover:bg-green-700 focus:ring-green-500'
                                                                    : 'bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-500'
                                                                    }`}
                                                                onClick={() => setIsCropping(!isCropping)}
                                                            >
                                                                <CropIcon className=" inline-block" />
                                                                {isCropping ? t("FINISH_CROP") : t("CROP")}
                                                            </button>
                                                            <button
                                                                className="px-4 py-2 bg-red-600 text-white rounded-md text-sm font-medium hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                                                onClick={onImageRemoveAll}
                                                            >
                                                                <DeleteIcon className=" inline-block" />
                                                                {t("REMOVE")}
                                                            </button>
                                                        </div>
                                                        <div className="relative">
                                                            {!isCropping ? (
                                                                null
                                                            ) : (
                                                                <ReactCrop
                                                                    src={image?.data_url || SelectedPlayer?.licence.photoProfil}
                                                                    crop={crop}
                                                                    onChange={(c) => setCrop(c)}
                                                                    onComplete={handleCropComplete}
                                                                    aspect={1}
                                                                >
                                                                    <img ref={imgRef} id="cropImage" src={image.data_url || SelectedPlayer?.licence?.photoProfil} alt="Crop" />
                                                                </ReactCrop>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </ImageUploading>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}

import React, { useEffect, useState, useCallback, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ControleLicences from "../components/modals/ControleLicencesModal";
import MatchSelected from "../components/layouts/MatchSelected";
import SignatureModal from "../components/modals/SignatureModal";
import ArbitreFormModal from "../components/modals/ArbitreFormModal";
import { getTeamById } from "../redux/selectors/matchSelectors";
import ModalButton from "../components/modals/ModalButton";
import TeamSection from "../components/sections/TeamSection";
import ArbitreSection from "../components/sections/ArbitreSection";
import { AuthContext } from "../contexts/AuthContext";

export default function FuilleMatchPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { match, validComposition } = location.state||{};
  const { user } = useContext(AuthContext);
  const loading = useSelector((state) => state.matchReducer.loading);
  const teams = useSelector((state) => state.matchReducer.teams);
  const error = useSelector((state) => state.matchReducer.error);
  const [signatures, setSignatures] = useState({
    teamRecevant: false,
    arbiture: false,
    teamVisiteuse: false,
  });
  const [openControleLicences, setOpenControleLicences] = useState(false);
  const [openSignateur, setOpenSignateur] = useState(false);
  const [openArbitreForm, setOpenArbitreForm] = useState(false);
  const [teamRecevanteLicences, setTeamRecevanteLicences] = useState([]);
  const [teamVisiteuseLicences, setTeamVisiteuseLicences] = useState([]);
  const [teamsCompositionValidate, setTeamsCompositionValidate] = useState(
    () => {
      const savedData = localStorage.getItem("teamsCompositionValidate");
      return savedData ? JSON.parse(savedData) : [];
    }
  );

  useEffect(() => {
    if (!match || match == undefined || match == null) {
      navigate("/");
    }
  }, []);
  
  useEffect(() => {
    if (
      signatures?.teamRecevant &&
      signatures?.arbiture &&
      signatures?.teamVisiteuse
    ) {
      setOpenSignateur(false);
    }
  }, [signatures]);

  const handleConnexion = useCallback(
    (teamId, teamSlug) => {
      navigate("/match/prepare", { state: { match, teamId, teamSlug } });
    },
    [navigate, match]
  );

  const getPlayersLicences = useCallback(
    (teamId) => {
      const teamData = getTeamById(teams, teamId);
      const team_Id = teamData?.id || "";
      const teamName = teamData?.name || "";
      const teamLogo = teamData?.logo || "";
      const players = teamData?.composition?.players || [];
      const subs = teamData?.composition?.subs || [];
      const staffs = teamData?.composition?.staffs || [];

      return {
        team_Id,
        teamLogo,
        teamName,
        teamList: [...players, ...subs, ...staffs].map((user) => ({
          avatar: user?.image,
          primaryText: `${user?.prenom}`,
          secondaryText: `${t("LICENCE_NUMBR")} ${user?.licenceId}`,
          thirdText: user?.nom,
        })),
        fullTeam: [...players, ...subs, ...staffs],
      };
    },
    [teams, t]
  );

  const pushTeamsCompositionValidate = useCallback((validComposition) => {
    setTeamsCompositionValidate((prev) => {
      const newState = [...prev, validComposition];
      localStorage.setItem(
        "teamsCompositionValidate",
        JSON.stringify(newState)
      );
      return newState;
    });
  }, []);

  useEffect(() => {
    if (validComposition) {
      pushTeamsCompositionValidate(validComposition);
      setTeamRecevanteLicences(getPlayersLicences(match?.match?.equipeAway.id));
      setTeamVisiteuseLicences(getPlayersLicences(match?.match?.equipeHome.id));
    }
  }, [
    dispatch,
    validComposition,
    getPlayersLicences,
    match,
    pushTeamsCompositionValidate,
  ]);

  return (
    <div className="mt-4 p-4 pb-16 bg-white shadow-lg rounded-lg">
      <div className="flex justify-between mb-10">
        <MatchSelected match={match} t={t} />
        <ArbitreSection match={match} t={t} />
      </div>
      <ArbitreFormModal
        t={t}
        setOpen={setOpenArbitreForm}
        isOpen={openArbitreForm}
        match={match}
      />
      <div className="flex max-sm:flex-wrap mt-4 justify-between">
        <TeamSection
          match={match}
          team={match?.match?.equipeAway}
          teamLicences={teamRecevanteLicences}
          teamsCompositionValidate={teamsCompositionValidate}
          handleConnexion={handleConnexion}
          t={t}
          teamType="EQUIPE_RECEVANTE"
        />
        <TeamSection
          match={match}
          team={match?.match?.equipeHome}
          teamLicences={teamVisiteuseLicences}
          teamsCompositionValidate={teamsCompositionValidate}
          handleConnexion={handleConnexion}
          t={t}
          teamType="EQUIPE_VISITEUSE"
        />
      </div>
      {teamsCompositionValidate?.some(
        (vld) => vld?.teamSlug == match?.match?.equipeHome?.slug
      ) &&
        teamsCompositionValidate?.some(
          (vld) => vld?.teamSlug == match?.match?.equipeAway?.slug
        ) && (
          <div className="flex flex-wrap justify-between">
            <div className="flex flex-wrap">
              <ModalButton
                onClick={() => setOpenControleLicences(true)}
                text={t("CONTROLS")}
                className="bg-blue-800 mx-5 uppercase hover:bg-blue-500"
              />
              <ControleLicences
                teamRecevant={teamRecevanteLicences}
                teamVisiteuse={teamVisiteuseLicences}
                isOpen={openControleLicences}
                setOpen={setOpenControleLicences}
              />
              <ModalButton
                onClick={() =>
                  navigate("/match/reserve", {
                    state: { match, type: "before" },
                  })
                }
                text={t("RESERVES_AVANT_MATCH")}
                className="bg-blue-800 uppercase hover:bg-blue-500"
              />
            </div>

            {!signatures?.teamRecevant ||
            !signatures?.arbiture ||
            !signatures?.teamVisiteuse ? (
              <ModalButton
                onClick={() => setOpenSignateur(true)}
                text={t("SIGNATURES_AVANT_MATCH")}
                className="bg-orange-500 mx-5 uppercase hover:bg-orange-700"
              />
            ) : (
              <ModalButton
                onClick={() => navigate("/match/events", { state: { match } })}
                text={t("MATCH_SHEET")}
                className="bg-orange-500 mx-5 uppercase hover:bg-orange-700"
              />
            )}
            <SignatureModal
              isArbiture={user}
              teamRecevant={teamRecevanteLicences}
              teamVisiteuse={teamVisiteuseLicences}
              isOpen={openSignateur}
              matchId={match?.match?.id}
              setOpen={setOpenSignateur}
              t={t}
              signatures={signatures}
              setSignatures={setSignatures}
              type={"before"}
            />
          </div>
        )}
    </div>
  );
}

import React, { useState } from "react";
import GlobalModal from "./GlobalModal";
import {
  validateEmail,
  validatePassword,
} from "../../utils/validation/validation";
import { Alert } from "@mui/material";
import { Close } from "@mui/icons-material";

function LoginModal({ isOpen, setOpen, handleLogin, t}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit =async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError(t("MESSAGES_ERROR.EMAIL_INVALID"));
      return;
    }
    // if (!validatePassword(password)) {
    //   setError(t("MESSAGES_ERROR.PASSWORD_INVALID"));
    //   return;
    // }
    try {
      await handleLogin(email, password);
      setOpen(false);
    } catch (error) {
      setError(t("MESSAGES_ERROR."+error?.message));
    }
  };

  return (
    <GlobalModal
      isOpen={isOpen}
      setOpen={setOpen}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <div className="bg-white w-96 rounded p-5">
        <div className="font-bold text-end">
          <button onClick={() => setOpen(false)}>
            <Close />
          </button>
        </div>
        <h2 className="font-bold text-center font-myteam mb-3">{t("LOGIN")}</h2>
        <form onSubmit={handleSubmit}>
          {error && (
            <Alert
              severity="error"
              className="font-bold text-center font-myteam"
              style={{
                marginBottom: 20,
              }}
            >
              {error}
            </Alert>
          )}
          <div className="mb-3">
            <label className="font-myteam font-bold">{t("EMAIL")}</label>
            <input
              type="email"
              value={email}
              style={{
                backgroundColor:"#fafafa"
              }}
              onChange={(e) => setEmail(e.target.value)}
              className="border rounded w-full p-2"
            />
          </div>
          <div className="mb-3">
            <label className="font-myteam font-bold">{t("PASSWORD")}</label>
            <input
              type="password"
              value={password}
              style={{
                backgroundColor:"#fafafa"
              }}
              onChange={(e) => setPassword(e.target.value)}
              className="border rounded w-full p-2"
            />
          </div>
          <button
            type="submit"
            className="w-full font-myteam font-bold bg-blue-500 text-white rounded p-2"
          >
            {t("SE_CONNECTER")}
          </button>
        </form>
      </div>
    </GlobalModal>
  );
}

export default LoginModal;

export const TOGGLE_MENU = 'TOGGLE_MENU';
export const TOGGLE_LAYOUT = 'TOGGLE_LAYOUT';
export const TOGGLE_NAVBAR = 'TOGGLE_NAVBAR';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';


export const toggleMenu = (payload) => ({
    type: TOGGLE_MENU,
    payload,
});

export const toggleLayout = (payload) => ({
    type: TOGGLE_LAYOUT,
    payload,
});

export const toggleNavbar = (payload) => ({
    type: TOGGLE_NAVBAR,
    payload,
});

export const toggleSidebar = () => ({
    type: TOGGLE_SIDEBAR,
});

export const setPageTitle = (payload) => ({
    type: SET_PAGE_TITLE,
    payload,
});
import React from 'react';
import { useTranslation } from 'react-i18next';

const TimelineIcon = ({ type }) => {
    switch (type) {
        case "regular":
            return <i className="icon-svg icon-soccer-ball" />;
        case 'penalty':
            return <i className="icon-svg icon-soccer-ball-penalty" />;
        case 'own':
            return <i className="icon-svg icon-soccer-ball-own-goal" />;
        case 'yellow':
            return <i className="icon-svg icon-yellow-card" />;
        case 'red':
            return <i className="icon-svg icon-red-card" />;
        case 'yellowRed':
            return <img src='../../../../assets/images/footCards/yellowRed.png' width={25} height={25} />;
        case 'substitution':
            return <i className="icon-svg icon-substitution" />;
        case 'full-time':
            return <i className="icon-svg icon-time" />;
        default:
            return null;
    }
};
const extractEvents = (eventPlayers) => {
    const events = [];

    eventPlayers.forEach(player => {
        if (typeof player === 'number') return;

        player.buts.forEach(goal => {
            if (goal.minute != undefined) {
                events.push({
                    event: 'goal',
                    minute: goal.minute,
                    period: goal.period,
                    player: `${player.prenom} ${player.nom}`,
                    assist: goal.joueur_id_assist
                        ? `${eventPlayers.find(p => p.id === goal.joueur_id_assist)?.prenom} ${eventPlayers.find(p => p.id === goal.joueur_id_assist)?.nom}`
                        : null,
                    type: goal.type
                });
            }
        });

        if (player.carts) {
            if (Array?.isArray(player.carts)) {
                player.carts?.map(cart => {
                    return events.push({
                        event: 'card',
                        minute: cart.minute,
                        period: cart.period,
                        player: `${player.prenom} ${player.nom}`,
                        type: cart.type
                    });
                })
            }
            if (!Array?.isArray(player.carts) && player.carts.minute != undefined) {
                events.push({
                    event: 'card',
                    minute: player.carts.minute,
                    period: player.carts.period,
                    player: `${player.prenom} ${player.nom}`,
                    type: player.carts.type
                });
            }
        }

        if (player.changements) {
            const playerIn = eventPlayers.find(p => p.id === player.changements.joueur_id_in);
            const playerOut = eventPlayers.find(p => p.id === player.changements.joueur_id_out);

            if (
                player.changements.minute !== undefined &&
                !events.some(
                    e =>
                        e.minute === player.changements.minute &&
                        e.period === player.changements.period &&
                        e.playerOut === (playerOut ? `${playerOut.prenom} ${playerOut.nom}` : 'Unknown') &&
                        e.playerIn === (playerIn ? `${playerIn.prenom} ${playerIn.nom}` : 'Unknown')
                )
            ) {
                events.push({
                    event: 'substitution',
                    type: 'substitution',
                    minute: player.changements.minute,
                    period: player.changements.period,
                    playerOut: playerOut ? `${playerOut.prenom} ${playerOut.nom}` : 'Unknown',
                    playerIn: playerIn ? `${playerIn.prenom} ${playerIn.nom}` : 'Unknown'
                });
            }
        }

    });

    return events;
};

const Event = ({ event, team }) => {
    const playerEvent = extractEvents([...event?.ListPlayer, ...event?.ListReplacment]);
    return (
        playerEvent?.map((evt, index) => (
            <div key={index} className={`game-timeline__event game-timeline__event--${evt.minute}`}>
                {
                    team == 1 && <> <div className={`game-timeline__team-1`}>
                        {/* <div className="game-timeline__event-info">
                            {
                                evt.type == "substitution" ? <>
                                    <div className="game-timeline__event-name">{`${evt.playerIn}`}</div>
                                    <div className="game-timeline__event-name">{`${evt.playerOut}`}</div>
                                </> : <div className="game-timeline__event-name">{`${evt.player}`}</div>
                            }
                        </div> */}
                        <TimelineIcon type={evt.type} />
                    </div>
                        <div className="game-timeline__time">{`${evt.minute}'`}</div></>
                }
                {
                    team == 2 && <> <div className={`game-timeline__team-2`}>
                        <TimelineIcon type={evt.type} />
                        {/* <div className="game-timeline__event-info">
                            {
                                evt.type == "substitution" ? <>
                                    <div className="game-timeline__event-name">{`${evt.playerIn}`}</div>
                                    <div className="game-timeline__event-name">{`${evt.playerOut}`}</div>
                                </> : <div className="game-timeline__event-name">{`${evt.player}`}</div>
                            }
                        </div> */}
                    </div>
                        <div className="game-timeline__time">{`${evt.minute}'`}</div></>
                }
            </div>
        ))
    )
}

export default function GameTimeline({ events, teamHome, teamAway }) {
    const { t } = useTranslation();

    return (
        <div className="card">
            <div className="card__header">
                <h4>{t('GAME_TIMELINE')}</h4>
            </div>
            <div className="card__content card__content--pattern-dotted">
                <div className="game-timeline-wrapper ">
                    <div className="game-timeline game-timeline--adaptive">
                        <div className="game-timeline__event">
                            <div className="game-timeline__team-1">
                                <div className="game-timeline__team-shirt">
                                    <img src={teamHome?.image} className="rounded w-30 h-8" />
                                </div>
                            </div>
                            <div className="game-timeline__time">0'</div>
                            <div className="game-timeline__team-2">
                                <div className="game-timeline__team-shirt">
                                    <img src={teamAway?.image} className="rounded w-30 h-8" />
                                </div>
                            </div>
                        </div>
                        {
                            events?.length === 1
                                ? <Event event={events[0]} team={1} />
                                : (
                                    <>
                                        <Event event={events[0]} team={2} />
                                        <Event event={events[1]} team={1} />
                                    </>
                                )
                        }

                        <div className="game-timeline__event game-timeline__event--ft">
                            <div className="game-timeline__time">FT</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { countriesData } from '../../../utils/selectesData';
import { useTranslation } from 'react-i18next';
import { createRegistrationClub } from '../../../service/registrationService';
import { useSnackbar } from 'notistack';

export default function Coordonnees({ setStep, step, defaultValues, registrationId, isForUpdate }) {
  const { t } = useTranslation();
  const savedState = JSON.parse(localStorage.getItem('formStateCoordonnes')) || {};
  const [address1, setAddress1] = useState(savedState?.address1 || defaultValues?.adresse_1 || defaultValues?.licence?.adresse_1 || '');
  const [address2, setAddress2] = useState(savedState?.address2 || defaultValues?.adresse_2 || defaultValues?.licence?.adresse_2 || '');
  const [city, setCity] = useState(savedState.city || defaultValues?.ville || defaultValues?.licence?.ville || '');
  const [state, setState] = useState(savedState.state || defaultValues?.etat || defaultValues?.licence?.etat || '');
  const [postalCode, setPostalCode] = useState(savedState.postalCode || defaultValues?.codePostal || defaultValues?.licence?.codePostal || '');
  const [pays, setPays] = useState(savedState.pays?.value || defaultValues?.pays || defaultValues?.licence?.pays || 'Seychelles');
  const [phoneNumber, setPhoneNumber] = useState(savedState.phoneNumber || defaultValues?.numTel || defaultValues?.licence?.numTel || '');
  const [email, setEmail] = useState(savedState.email || defaultValues?.email || defaultValues?.licence?.email || '');

  // Validation state variables
  const [address1Error, setAddress1Error] = useState('');
  const [cityError, setCityError] = useState('');
  const [stateError, setStateError] = useState('');
  const [postalCodeError, setPostalCodeError] = useState('');
  const [paysError, setPaysError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [emailError, setEmailError] = useState('');

  const { enqueueSnackbar }=useSnackbar()

  useEffect(() => {
    if (!registrationId && !defaultValues?.id) {
      setStep(step - 1)
    }
  }, [registrationId, setStep, step, defaultValues]);

  useEffect(() => {
    const formState = {
      address1,
      address2,
      city,
      state,
      postalCode,
      pays,
      phoneNumber,
      email,
      step,
    };
    localStorage.setItem('formStateCoordonnes', JSON.stringify(formState));
  }, [address1, address2, city, state, postalCode, pays, phoneNumber, email, step]);


  const handleClearError = () => {
    setAddress1Error('');
    setCityError('');
    setStateError('');
    setPostalCodeError('');
    setPaysError('');
    setPhoneNumberError('');
    setEmailError('');
  }

  const handleSubmit = async () => {
    handleClearError()

    let formIsValid = true;

    if (!address1) {
      setAddress1Error(t('MESSAGES_ERROR.ADDRESS_1_REQUIRED'));
      formIsValid = false;
    }

    if (!city) {
      setCityError(t('MESSAGES_ERROR.CITY_REQUIRED'));
      formIsValid = false;
    }

    if (!state) {
      setStateError(t('MESSAGES_ERROR.STATE_REQUIRED'));
      formIsValid = false;
    }

    if (!postalCode) {
      setPostalCodeError(t('MESSAGES_ERROR.POSTAL_CODE_REQUIRED'));
      formIsValid = false;
    }

    if (!pays) {
      setPaysError(t('MESSAGES_ERROR.COUNTRY_REQUIRED'));
      formIsValid = false;
    }

    if (!phoneNumber) {
      setPhoneNumberError(t('MESSAGES_ERROR.PHONE_NUMBER_REQUIRED'));
      formIsValid = false;
    }

    if (!email) {
      setEmailError(t('MESSAGES_ERROR.EMAIL_REQUIRED'));
      formIsValid = false;
    }

    if (!formIsValid) {
      return;
    }

    try {
      await createRegistrationClub({
        address1,
        address2,
        city,
        state,
        postalCode,
        phoneNumber,
        email,
        pays: t(pays?.value) || pays,
      }, step, registrationId || defaultValues?.id);

      setStep(step + 1);
      
      if (isForUpdate) {
        enqueueSnackbar(t('COORDONNES_UPDATED_SUCCESS'), { variant: 'success' });
      }

    } catch (error) {
      console.error('Error creating registration:', error);
    }
  };

  return (
    <form noValidate className="space-y-5 mx-4 my-4">
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
        <div>
          <label htmlFor="address1">{t('ADRESSE')} 1 <span className='text-red-500'>*</span></label>
          <input
            id="address1"
            type="text"
            placeholder={t('ADRESSE') + " 1"}
            value={address1}
            onChange={(e) => { setAddress1(e.target.value); handleClearError() }}
            className={`form-input ${address1Error ? 'border-red-500' : ''}`}
          />
          {address1Error && <p className="text-red-500">{address1Error}</p>}
        </div>
        <div>
          <label htmlFor="address2">{t('ADRESSE')} 2</label>
          <input
            id="address2"
            type="text"
            placeholder={t('ADRESSE') + " 2"}
            value={address2}
            onChange={(e) => { setAddress2(e.target.value); handleClearError() }}
            className="form-input"
          />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
        <div>
          <label htmlFor="city">{t("CITY")} <span className='text-red-500'>*</span></label>
          <input
            id="city"
            type="text"
            placeholder={t("CITY")}
            value={city}
            onChange={(e) => { setCity(e.target.value); handleClearError() }}
            className={`form-input ${cityError ? 'border-red-500' : ''}`}
          />
          {cityError && <p className="text-red-500">{cityError}</p>}
        </div>
        <div>
          <label htmlFor="state">{t("ETAT")} <span className='text-red-500'>*</span></label>
          <input
            id="state"
            type="text"
            placeholder={t("ETAT")}
            value={state}
            onChange={(e) => { setState(e.target.value); handleClearError() }}
            className={`form-input ${stateError ? 'border-red-500' : ''}`}
          />
          {stateError && <p className="text-red-500">{stateError}</p>}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
        <div>
          <label htmlFor="postalCode">{t("CODE_POSTAL")} <span className='text-red-500'>*</span></label>
          <input
            id="postalCode"
            type="text"
            placeholder={t("CODE_POSTAL")}
            value={postalCode}
            onChange={(e) => { setPostalCode(e.target.value); handleClearError() }}
            className={`form-input ${postalCodeError ? 'border-red-500' : ''}`}
          />
          {postalCodeError && <p className="text-red-500">{postalCodeError}</p>}
        </div>
        <div>
          <label htmlFor="pays">{t('PAYS')} <span className='text-red-500'>*</span></label>
          <Select
            options={countriesData}
            components={{ Option: ({ data, innerRef, innerProps }) => <div ref={innerRef} {...innerProps} className="flex items-center p-2 cursor-pointer hover:bg-blue-200"><span>{t(data.label)}</span></div> }}
            defaultInputValue={pays}
            getOptionLabel={(option) => t(option.label)}
            onChange={(selectedOption) => { setPays(selectedOption); handleClearError() }}
            className={`custom-multiselect ${paysError ? 'border-red-500' : ''}`}
          />
          {paysError && <p className="text-red-500">{paysError}</p>}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
        <div>
          <label htmlFor="phoneNumber">{t("PHONE_NUMBER")} <span className='text-red-500'>*</span></label>
          <input
            id="phoneNumber"
            type="text"
            placeholder={t("PHONE_NUMBER")}
            value={phoneNumber}
            onChange={(e) => { setPhoneNumber(e.target.value); handleClearError() }}
            className={`form-input ${phoneNumberError ? 'border-red-500' : ''}`}
          />
          {phoneNumberError && <p className="text-red-500">{phoneNumberError}</p>}
        </div>
        <div>
          <label htmlFor="email">{t("EMAIL")} <span className='text-red-500'>*</span></label>
          <input
            id="email"
            type="text"
            placeholder={t("EMAIL")}
            value={email}
            onChange={(e) => { setEmail(e.target.value); handleClearError() }}
            className={`form-input ${emailError ? 'border-red-500' : ''}`}
          />
          {emailError && <p className="text-red-500">{emailError}</p>}
        </div>
      </div>
      <div className="flex justify-between">
        <button
          type="button"
          className={`btn ${isForUpdate ? "bg-green-500 text-white" : "btn-primary"} ${step === 1 ? 'hidden' : ''}`}
          onClick={() =>
            setStep(step === 1 ? 1 : step - 1)
          }
        >
          {t("BACK")}
        </button>
        <button
          type="button"
          className={`btn ${isForUpdate ? "bg-green-500 text-white" : "btn-primary"} ltr:ml-auto rtl:mr-auto`}
          onClick={handleSubmit}
        >
          {step === 5 ? t("FINISH") : t("NEXT")}
        </button>
      </div>
    </form>
  );
}

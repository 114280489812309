import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next';
import { getGamesHistoryStats } from '../../../service/statisticService';
import SmallLoading from '../../../../../components/Loadings/SmallLoading';
import NoDataFound from '../../Layouts/NoDataFound';

export default function GamesPointsSoccer({category}) {
    const { t } = useTranslation();
    const [optsData, setOptsData] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchOptions = async () => {
        try {
            const response = await getGamesHistoryStats(category);
            setOptsData(response?.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOptions();
    }, [category]);

    const options = {
        tooltip: {
            trigger: 'item',
        },
        legend: {
            orient: 'horizontal',
            bottom: 'bottom',
        },
        series: [
            {
                name: t('GAMES_HISTORY'),
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 20,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: [
                    { value: optsData?.data[1], name: t('DONUT_CHART_LABEL_DRAW') },
                    { value: optsData?.data[0], name: t('DONUT_CHART_LABEL_WIN') },
                    { value: optsData?.data[2], name: t('DONUT_CHART_LABEL_LOST') },
                ],
            },
        ],
        color: [ '#ADC4CE','#A2C579', '#DD5746'],
    };

    return (
        <React.Fragment>
            <div className="card__header card__header--has-legend">
                <h4>{t('GAMES_HISTORY')}</h4>
                <div id="gamesPoinstsLegendSoccer" className="chart-legend" />
            </div>
            {
                loading ? <div className='card__content'><SmallLoading /></div> :
                    !optsData ? <div className='h-20'>
                        <NoDataFound />
                    </div> :
                        <div className="card__content">
                            <p>{t('TOTAL_OF_MATCHES_PLAYED')} : {optsData?.data?.reduce((t, c) => t = t + c, 0)}</p>
                            <ReactECharts option={options} style={{ height: 300 }} className="rounded-lg bg-white dark:bg-black overflow-hidden" />
                        </div>
            }
        </React.Fragment>
    );
}

import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { login, logout } from "../api/services/authServices";
import { jwtDecode } from "jwt-decode";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("myteamConnectToken");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setIsAuthenticated(true);
        setUser(decodedToken);
      } catch (error) {
        handleLogout();
      }
    }
  }, []);

  const handleLogin = async (email, password) => {
    try {
      const decodedToken = await login(email, password);
      setIsAuthenticated(true);
      setUser(decodedToken);
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      setIsAuthenticated(false);
      setUser(null);
      navigate("/login");
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        login: handleLogin,
        logout: handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

import React from "react";
import SportsIcon from "@mui/icons-material/Sports";
import SportsScoreIcon from "@mui/icons-material/SportsScore";

const ArbitreSection = ({ match, t }) => (
  <div className="text-center items-center">
    {match?.match?.Arbitres?.length !== 0 && (
      <>
        <div className="flex items-center justify-center space-x-2">
          <span className="font-myteam font-bold uppercase text-gray-700 max-w-xs">
            {t("ARBITER_OFFICIEL")}
          </span>
        </div>
        <div className="font-myteam flex items-center text-gray-500 text-sm">
          {match?.match?.Arbitres?.map((arbt) => (
            <p className="p-2 font-myteam" key={arbt.licenceId}>
              {arbt.function !== "principal" ? <SportsScoreIcon /> : <SportsIcon />}
              {arbt.nom} {arbt.prenom}
            </p>
          ))}
        </div>
      </>
    )}
  </div>
);

export default ArbitreSection;
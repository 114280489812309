import {
  FETCH_MATCHES_REQUEST,
  FETCH_MATCHES_SUCCESS,
  FETCH_MATCHES_FAILURE,
  FETCH_TEAM_REQUEST,
  FETCH_TEAM_SUCCESS,
  FETCH_TEAM_FAILURE,
  UPDATE_COMPOSITION_REQUEST,
  UPDATE_COMPOSITION_SUCCESS,
  UPDATE_COMPOSITION_FAILURE,
  FETCH_COMPOSITION_REQUEST,
  FETCH_COMPOSITION_FAILURE,
  FETCH_COMPOSITION_SUCCESS,
  SAVE_SERVER_COMPOSITION_SUCCESS,
  VALIDATE_COMPOSITION_SUCCESS,
  VALIDATE_COMPOSITION_FAILURE,
  VALIDATE_COMPOSITION_REQUEST,
  UPDATE_PLAYER_INFO,
} from "../actions/matchActions";
import { loadStateFromLocalStorage } from "../selectors/matchSelectors";

const initialState = {
  matches: [],
  teams: [],
  loading: false,
  error: null,
  myTeam: {
    players: [],
    staffs: [],
  },
  myComposition: {},
  matchHasArtbiter: false,
};

const persistedState = loadStateFromLocalStorage("matchSatate");

const combineTeam = (equipe) => [
  ...(equipe?.personnes || []),
  ...(equipe?.ListPlayer || []),
  ...(equipe?.ListReplacment || []),
];

const matchReducer = (state = persistedState || initialState, action) => {
  switch (action.type) {
    case FETCH_MATCHES_REQUEST:
    case FETCH_TEAM_REQUEST:
    case FETCH_COMPOSITION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_COMPOSITION_SUCCESS:
      const equipe = action?.payload;
      const equipeSlug = equipe?.equipe?.slug;
      const equipeLogo = equipe?.equipe?.image;

      const existingTeam = state?.teams[equipeSlug];

      const updatedTeamData = existingTeam
        ? {
            ...existingTeam,
            id: equipe?.equipe_id || 0,
            name: equipe?.equipe.slug || "",
            logo: equipeLogo || "",
            team: combineTeam(equipe),
            composition: {
              status: equipe?.status || "",
              formation: equipe?.formation || "",
              staffs: equipe?.personnes || [],
              players: equipe?.ListPlayer || [],
              subs: equipe?.ListReplacment || [],
            },
          }
        : {
            id: equipe?.equipe_id || 0,
            name: equipe?.equipe.slug || "",
            logo: equipeLogo || "",
            team: combineTeam(equipe),
            composition: {
              status: equipe?.status || "",
              formation: equipe?.formation || "",
              staffs: equipe?.personnes || [],
              players: equipe?.ListPlayer || [],
              subs: equipe?.ListReplacment || [],
            },
          };
      return {
        ...state,
        loading: false,
        teams: {
          ...state.teams,
          [equipeSlug]: updatedTeamData,
        },
      };

    case FETCH_COMPOSITION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_MATCHES_SUCCESS:
      return {
        ...state,
        loading: false,
        matches: action.payload,
      };
    case FETCH_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        myTeam: {
          team: {
            players: action.payload.myTeam.Players || [],
            staffs: action.payload.myTeam.staffs || [],
          },
        },
      };
    case UPDATE_PLAYER_INFO:
      return {
        ...state,
        loading: false,
        myTeam: {
          team: {
            ...state.myTeam?.team,
            players: state.myTeam?.team?.players.map((player) => {
              const updatedPlayer = action.payload.find(
                (p) => p?.joueur_id == player?.id
              );
            
              if (updatedPlayer) {
                
                return {
                  ...player,
                  shirtNumber: updatedPlayer.shirtNmbr,
                };
              }

              return player;
            }),
          },
        },
      };

    case FETCH_MATCHES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_COMPOSITION_REQUEST || VALIDATE_COMPOSITION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_COMPOSITION_SUCCESS:
      return {
        ...state,
        loading: false,
        myComposition: {
          status: action.payload?.status || "",
          formation: action.payload?.formation,
          players: action.payload?.ListPlayer,
          staffs: action.payload?.personnes,
          subs: action.payload?.ListReplacment,
        },
      };
    case SAVE_SERVER_COMPOSITION_SUCCESS || VALIDATE_COMPOSITION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_COMPOSITION_FAILURE || VALIDATE_COMPOSITION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default matchReducer;

import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Modal,
  Card,
  CardContent,
  Grid,
  Button,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DescriptionIcon from "@mui/icons-material/Description";

const theme = createTheme({
  palette: {
    primary: {
      main: "#074173",
    },
    secondary: {
      main: "#f50057",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const MatchModal = ({ open, handleClose, title, Available, RowSelected }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const matchHasArbiter = useSelector(
    (state) => state.matchReducer?.matchHasArtbiter
  );

  const handlePrepareClick = () => {
    navigate("/match/prepare", { state: { match: RowSelected } });
  };

  const handleMatchSheetClick = () => {
    navigate(matchHasArbiter ? "/match/fuille" : "/match/events", {
      state: { match: RowSelected },
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "20px",
              color: "primary.main",
              mb: 3,
            }}
          >
            {title}
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={6}>
              <Card
                sx={{
                  cursor: Available === 0 ? "pointer" : "default",
                  transition: "all 0.3s",
                  "&:hover":
                    Available === 0
                      ? {
                          transform: "translateY(-5px)",
                          boxShadow: 3,
                        }
                      : {},
                }}
                onClick={Available === 0 ? handlePrepareClick : undefined}
              >
                <CardContent
                  sx={{
                    backgroundColor:
                      Available === 0 ? "primary.main" : "#EEEEEE",
                    color: Available === 0 ? "white" : "text.secondary",
                    padding: "24px",
                    borderRadius: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <DriveFileRenameOutlineIcon sx={{ fontSize: 40, mb: 1 }} />
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "medium", textAlign: "center" }}
                  >
                    {t("PREPARER_TEAM")}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card
                sx={{
                  cursor: Available === 1 ? "pointer" : "default",
                  transition: "all 0.3s",
                  "&:hover":
                    Available === 1
                      ? {
                          transform: "translateY(-5px)",
                          boxShadow: 3,
                        }
                      : {},
                }}
                onClick={Available === 1 ? handleMatchSheetClick : undefined}
              >
                <CardContent
                  sx={{
                    backgroundColor:
                      Available === 1 ? "primary.main" : "#EEEEEE",
                    color: Available === 1 ? "white" : "text.secondary",
                    padding: "24px",
                    borderRadius: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <DescriptionIcon sx={{ fontSize: 40, mb: 1 }} />
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "medium", textAlign: "center" }}
                  >
                    {t("FEUILLE_DE_MATCH")}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
            <Button variant="outlined" onClick={handleClose} sx={{ mr: 1 }}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default MatchModal;

import { DataTable } from 'mantine-datatable';
import { useContext, useEffect, useState } from 'react';
import sortBy from 'lodash/sortBy';
import { downloadExcel } from 'react-export-table-to-excel';
import IconFile from '../Icon/IconFile';
import IconPrinter from '../Icon/IconPrinter';
import { useTranslation } from 'react-i18next';
import { MobileContext } from '../../context/isMobileContext';


const MultiColumn = ({ loading, rowData, columns, col, header, enableExport, title, initialRecords, search, setSearch, setInitialRecords, PDF_TITLE, USER_NAME }) => {
    const { t } = useTranslation()
    const [page, setPage] = useState(1);
    const PAGE_SIZES = [10, 20, 30, 50, 100];
    const [pageSize, setPageSize] = useState(PAGE_SIZES[1]);
    const [recordsData, setRecordsData] = useState(initialRecords);
    const [sortStatus, setSortStatus] = useState({
        columnAccessor: 'id',
        direction: 'asc',
    });
    const isMobile=useContext(MobileContext)
    useEffect(() => {
        setPage(1);
    }, [pageSize]);
    useEffect(() => {
        const from = (page - 1) * pageSize;
        const to = from + pageSize;
        setRecordsData([...initialRecords.slice(from, to)]);
    }, [page, pageSize, initialRecords]);


    useEffect(() => {
        const data = sortBy(initialRecords, sortStatus.columnAccessor);
        setInitialRecords(sortStatus.direction === 'desc' ? data.reverse() : data);
        setPage(1);
    }, [sortStatus]);

    function handleDownloadExcel() {
        downloadExcel({
            fileName: 'table',
            sheet: 'react-export-table-to-excel',
            tablePayload: {
                header,
                body: rowData,
            },
        });
    }

    const exportTable = (type) => {
        let columns = col;
        let records = rowData;
        let newVariable = window.navigator;
        const dynamicTitle = PDF_TITLE;
        const userName = USER_NAME;
        const date = new Date().toLocaleDateString();
        let filename = PDF_TITLE+"_"+date;

        if (type === 'csv') {
            const colDelimiter = ',';  // Changed to comma for standard CSV
            const lineDelimiter = '\n';
            const escapeCSV = (cell) => {
                if (cell == null) return '';
                cell = cell.toString();
                if (cell.includes(colDelimiter) || cell.includes('"') || cell.includes('\n')) {
                    return '"' + cell.replace(/"/g, '""') + '"';
                }
                return cell;
            };
        
            let result = columns.map(d => escapeCSV(capitalize(d))).join(colDelimiter) + lineDelimiter;
        
            result += records.map(item => 
                columns.map(d => escapeCSV(item[d])).join(colDelimiter)
            ).join(lineDelimiter);
        
            const blob = new Blob([result], { type: 'text/csv;charset=utf-8;' });
            
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, filename + '.csv');
            } else {
                const link = document.createElement('a');
                if (link.download !== undefined) {
                    const url = URL.createObjectURL(blob);
                    link.setAttribute('href', url);
                    link.setAttribute('download', filename + '.csv');
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        } else if (type === 'print') {
            var rowhtml = `
                <!DOCTYPE html>
                <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>Print</title>
                    <style>
                        @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
                        body {
                            font-family: 'Roboto', Arial, sans-serif;
                            color: #333;
                            line-height: 1.6;
                            margin: 0;
                            padding: 20px;
                        }
                        .header {
                            text-align: center;
                            margin-bottom: 20px;
                        }
                        .title {
                            font-size: 24px;
                            font-weight: bold;
                            margin-bottom: 10px;
                        }
                        .subtitle {
                            font-size: 14px;
                            color: #666;
                        }
                        .logo {
                            position: absolute;
                            top: 20px;
                            left: 20px;
                            width: 80px;
                        }
                        table {
                            width: 100%;
                            border-collapse: collapse;
                            margin-top: 20px;
                        }
                        th, td {
                            text-align: left;
                            padding: 12px;
                            border-bottom: 1px solid #ddd;
                        }
                        th {
                            background-color: #f8f9fa;
                            font-weight: bold;
                            text-transform: uppercase;
                            font-size: 12px;
                            color: #495057;
                        }
                        tr:nth-child(even) {
                            background-color: #f8f9fa;
                        }
                        @media print {
                            body {
                                -webkit-print-color-adjust: exact;
                                print-color-adjust: exact;
                            }
                        }
                    </style>
                </head>
                <body>
                    <img src="../assets/images/logos/myteamConnect.png" alt="App Logo" class="logo">
                    <div class="header">
                        <div class="title">${dynamicTitle}</div>
                        <div class="subtitle">Date: ${date} | User: ${userName}</div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                ${header?.map(d => `<th>${capitalize(d)}</th>`).join('')}
                            </tr>
                        </thead>
                        <tbody>
                            ${records.map(item => `
                                <tr>
                                    ${columns?.map(d => `<td>${item[d] || ''}</td>`).join('')}
                                </tr>
                            `).join('')}
                        </tbody>
                    </table>
                </body>
                </html>
            `;
        
            var winPrint = window.open('', '', 'left=0,top=0,width=1000,height=600,toolbar=0,scrollbars=0,status=0');
            winPrint.document.write(rowhtml);
            winPrint.document.close();
            winPrint.focus();
            winPrint.print();
        } else if (type === 'txt') {
            let coldelimiter = ',';
            let linedelimiter = '\n';
            let result = columns
                .map((d) => capitalize(d))
                .join(coldelimiter);
            result += linedelimiter;
            records.map((item) => {
                columns.map((d, index) => {
                    if (index > 0) result += coldelimiter;
                    let val = item[d] ? item[d] : '';
                    result += val;
                });
                result += linedelimiter;
            });
            if (!result.match(/^data:text\/txt/i) && !newVariable.msSaveOrOpenBlob) {
                var data1 = 'data:application/txt;charset=utf-8,' + encodeURIComponent(result);
                var link1 = document.createElement('a');
                link1.setAttribute('href', data1);
                link1.setAttribute('download', filename + '.txt');
                link1.click();
            } else {
                var blob1 = new Blob([result]);
                if (newVariable.msSaveOrOpenBlob) {
                    newVariable.msSaveBlob(blob1, filename + '.txt');
                }
            }
        }
    };


    const capitalize = (text) => {
        return text
            .replace('_', ' ')
            .replace('-', ' ')
            .toLowerCase()
            .split(' ')
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
    };

    return (
        <div className={`mt-6 ${isMobile&&"p-1"}`}>
            {title && <h3 className="text-xl font-bold mb-4">{title}</h3>}
            <div className="flex flex-wrap md:items-center justify-between md:flex-row mb-4.5 gap-5">
                {
                    enableExport && <div className="flex items-center">
                        <button type="button" className="btn btn-primary btn-sm m-1" onClick={()=>exportTable("excel")}>
                            <IconFile className="w-5 h-5 ltr:mr-2 rtl:ml-2" />
                            {t("CSV")}
                        </button>
                        <button type="button" onClick={() => exportTable('print')} className="btn btn-primary btn-sm m-1">
                            <IconPrinter className="ltr:mr-2 rtl:ml-2" />
                            {t("PRINT")}
                        </button>
                    </div>
                }

                <div className="ltr:ml-auto rtl:mr-auto">
                    <input type="text" className="form-input w-auto" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
                </div>
            </div>
            <div className="datatables">
                {
                    loading ? (<div className="flex justify-center items-center h-full">
                        <span className="animate-spin border-4 border-blue-500 border-l-transparent rounded-full object-cover w-12 h-12 inline-block align-middle m-auto mb-10"></span>
                    </div>) : (<DataTable highlightOnHover className="whitespace-nowrap text-black font-bold"
                        records={recordsData} columns={columns} totalRecords={initialRecords.length}
                        recordsPerPage={pageSize} page={page} onPageChange={(p) => setPage(p)}
                        recordsPerPageOptions={PAGE_SIZES} onRecordsPerPageChange={setPageSize}
                        sortStatus={sortStatus} onSortStatusChange={setSortStatus} minHeight={200}
                        paginationText={({ from, to, totalRecords }) =>
                            t('PAGINATION_TEXT', { from, to, totalRecords })
                        }
                        styles={{
                            header: {
                                backgroundColor: "#1554A2",
                                color: "white",
                                zIndex:0
                            }
                        }}
                        style={{
                            fontWeight: 'bold',
                        }}

                    />)
                }
            </div>
        </div>);
};
export default MultiColumn;

import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BorderColor, DeleteForever } from '@mui/icons-material';
import Visibility from '@mui/icons-material/Visibility';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import { handleExportMatchSheetExcel } from '../../controller/matchController';

const DropdownPortal = ({ children, buttonRef, isOpen, setIsOpen }) => {
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
                buttonRef.current && !buttonRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [setIsOpen, buttonRef]);

    useEffect(() => {
        if (isOpen && dropdownRef.current && buttonRef.current) {
            const buttonRect = buttonRef.current.getBoundingClientRect();
            const dropdownRect = dropdownRef.current.getBoundingClientRect();

            let top = buttonRect.bottom + window.scrollY;
            let left = buttonRect.left + window.scrollX;
            if (top + dropdownRect.height > window.innerHeight + window.scrollY) {
                top = buttonRect.top - dropdownRect.height + window.scrollY;
            }
            if (left + dropdownRect.width > window.innerWidth + window.scrollX) {
                left = buttonRect.right - dropdownRect.width + window.scrollX;
            }

            dropdownRef.current.style.top = `${top}px`;
            dropdownRef.current.style.left = `${left}px`;
        }
    }, [isOpen, buttonRef]);

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div
            ref={dropdownRef}
            className="fixed z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
            style={{ position: 'absolute' }}
        >
            {children}
        </div>,
        document.body
    );
};

const GroupedActionDTNButtons = ({ match, t, handleEdit,setOpenModalConfirmation, navigate, showAlert, setOpenModalCommentRefus
    , setRowSelected }) => {
    const [isOpen, setIsOpen] = useState(false);
    const buttonRef = useRef(null);

    const toggleDropdown = () => setIsOpen(!isOpen);

    return (
        <>
            <button
                ref={buttonRef}
                type="button"
                className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-blue-200 focus:bg-blue-200"
                onClick={toggleDropdown}
            >
                <MoreVertIcon className="w-5 h-5" />
            </button>

            <DropdownPortal buttonRef={buttonRef} isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <button
                        className="group flex items-center px-4 w-full py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        onClick={() => {
                            handleEdit(match);
                            setIsOpen(false);
                        }}
                    >
                        <BorderColor className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                        {t("UPDATE")}
                    </button>
                        <button
                            className={`${match?.matchStatus == "refused"? "text-red-700 hover:bg-red-100 hover:text-red-900" : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"} group flex items-center px-4 w-full py-2 text-sm `}
                            onClick={() => {
                                if (match?.matchStatus != "refused"&&match?.matchStatus != "waiting_confirmation") {
                                    navigate("/dashboard/match/details", { state: { matchId: match?.id, match } });
                                    setIsOpen(false);
                                } else if(match?.matchStatus == "refused"){
                                    setOpenModalCommentRefus(true);
                                    setRowSelected(match?.match);
                                }else if (match?.matchStatus == "waiting_confirmation") {
                                    console.log("OPEN",match?.match)
                                    setOpenModalConfirmation(true);
                                    setRowSelected(match?.match);
                                }
                            }}
                        >

                            <Visibility className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                            {t("VIEW")}
                        </button>
                    <button
                        className="group flex items-center px-4 w-full py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        onClick={() => {
                            showAlert(match?.id);
                            setIsOpen(false);
                        }}
                    >
                        <DeleteForever className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                        {t("DELETE")}
                    </button>
                    {match?.equipeHome?.hasExcel || match?.equipeAway?.hasExcel ? (
                        <button
                            className="group flex items-center px-4 w-full py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                            onClick={() => {
                                handleExportMatchSheetExcel(match);
                                setIsOpen(false);
                            }}
                        >
                            <VerticalAlignBottomIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                            {t("EXPORT_MATCH_SHEET")}
                        </button>
                    ) : null}
                </div>
            </DropdownPortal >
        </>
    );
};

export default GroupedActionDTNButtons;
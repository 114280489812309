import * as React from "react";

const playerCard = (props) => {
  return (
    <svg
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 800 580"
      style={props.style}
      xmlSpace="preserve"
      {...props}
    >
      <style type="text/css">
        {
          "\n\t.st0{fill:#F9F9F9;}\n\t.st1{opacity:6.000000e-02;}\n\t.st2{fill:#E8E8E8;}\n\t.st3{opacity:0.8;fill:#191919;enable-background:new    ;}\n\t.st4{fill:#191919;}\n\t.st5{fill:#E10B17;}\n\t.st6{fill:none;stroke:#161616;stroke-width:0.75;stroke-miterlimit:10;}\n\t.st7{font-family:'CenturyGothic-Bold';}\n\t.st8{font-size:16.8876px;}\n\t.st9{fill:#F7F7F7;}\n\t.st10{fill:#056D3B;}\n\t.st11{opacity:0.36;}\n\t.st12{clip-path:url(#SVGID_2_);}\n\t.st13{fill:none;}\n\t.st14{font-family:'CenturyGothic';}\n\t.st15{font-size:17.7639px;}\n\t.st16{letter-spacing:17;}\n\t.st17{opacity:0.8;fill:#8E0303;enable-background:new    ;}\n\t.st18{fill:#8E0303;}\n\t.st19{fill:#FFFFFF;}\n\t.st20{font-size:15.8292px;}\n\t.st21{opacity:0.49;}\n"
        }
      </style>
      <path
        className="st0"
        d="M697.3,464h-590c-16.6,0-30-13.4-30-30V117c0-16.6,13.4-30,30-30h589.9c16.6,0,30,13.4,30,30v317 C727.3,450.6,713.9,464,697.3,464z"
      />
      <g className="st1">
        <path
          className="st2"
          d="M715.5,174.2c-1.3-0.2-3.6,1.1-6.6,3.4c-3,2.3-8.3,3.2-14.5,2.9s-22.8,6.3-34.1,9.5s-14.8,4-20.2,4 s-17.9-1.6-17.9-1.6s-1.5,1.8-4.2,4.5s-10.8,0.3-23.5-3.3s-7.9-2-13.8,8.1c-5.9,10.1-9.8,10.5-13.8,19.3s-5.9,12-6.2,19.3 c-0.2,7.3,1.1,3.6,1.6,8.5c0.3,3.1-1.9,5.8-3.4,8.3c0,0,48.4-5.2,66.5,8.3l-44.2,36c-10.7-7.1-23.6-11.3-37.4-11.3 c-25.4,0-47.5,14-59,34.7c-5.4,9.7-8.5,20.9-8.5,32.9c0,37.3,30.2,67.6,67.6,67.6s67.6-30.2,67.6-67.6c0-16.5-5.9-31.6-15.8-43.3 l41.6-39.4c14.2,14.4,7.3,48.4,6.3,84v82.8c0,5.4-1.8,16.1-5.5,19.8c-1,1-2,1.8-3.1,2.6h62.6c16.6,0,30-13.4,30-30V174 c-0.5,0.2-1.1,0.3-1.9,0.5C720.1,175.8,716.8,174.4,715.5,174.2z"
        />
        <path
          className="st2"
          d="M606,441.5l-0.2-3c-8.9,7.4-18.8,13.1-29.8,17.2s-22.7,6.2-35,6.2c-14.2,0-27.5-2.7-39.9-8.1 s-23.2-12.8-32.4-22.1c-9.2-9.3-16.5-20.2-21.8-32.7c-5.4-12.5-8-25.9-8-40.1s2.7-27.6,8-40.1c0.5-1.1,1-2.1,1.4-3.2 c5.2-11.2,12-21.1,20.4-29.7c2.6-2.6,5.3-5.1,8.1-7.4c2.6-2.9,5.4-5.3,8.8-6.7c1-4.1,1.9-7.8,2.6-10.6c2.7-11.1,0.7-11.7,5.3-20.9 c4.5-9.2,12.9-23.5,14.7-31.7c1.8-8.2,6.1-12.8,5.4-17.6c-0.7-4.8,1.4-8.6,1.9-13.1s-1-10.4-1-10.4s-4.8,0.5-9.7-0.2 c-4.9-0.6-20.1,5.3-20.1,5.3c-3.8,1.1-6.3-1.4-6.3-1.4c-4.7,3.6-16.9,6.7-28.4,5.7c-11.5-0.9-31.2,0.4-36.5,3.3 c-5.3,2.9-11,4.2-15.9,4c-4.9-0.3-7.6,3-8.4,0.1s3.9-4.5,0-4.1s-9.7,4.1-11.1,3.1s2.5-3.6,2.5-3.6s-3-0.5-4-2.4 c-0.9-2,10.3-3.2,12.7-4.2c2.3-1,0.7-2.1,16.5-2c15.7,0.2,24.9-2.2,38.7-7.7c13.9-5.5,29.1-5.1,29.1-5.1s0-2.4-1.2-5.1 c-1.2-2.7-3.1-5.3-0.8-4c2.4,1.3,2.9-3.3,11.9-5.1s13.3-5.8,20.5-6.3s12.1-0.2,19.4-2.6s19.6,2.2,19.6,2.2c0.5-1.7,3.4-2.4,3.4-2.4 s0.2-4.1-0.4-7.1s0.1-4.7,0.1-6.3c0-1.6-1.1-2.3-0.4-4c0.7-1.7,0.5-4.8,0.5-4.8c-1-5.3-1.8-9.7,5.3-16.1s10.4-5.6,20-4.5 s12.6,10,14,15.1s-2.2,15.8-3.1,18.6c-0.9,2.8-2.6,4.9-6.2,9c-3.6,4.1-2,11-2,11s1.4,0.4,2.7,1c1.4,0.6,4.2,1.3,11.4,3.4 c7.1,2.1,18.9,13.5,22.8,16.9c3.9,3.4,13.2,4.9,16.3,6.1c3.1,1.2,7.9,2.4,7.9,2.4c7.1-3.5,35.2-3.2,46.3-5s14.8-2,21.6-6.8 s15.7-2.3,19.6-2.5c2.9-0.1,3.6,1.7,4.8,3.3V117c0-16.6-13.4-30-30-30h-517c0,0.1,0,0.2,0,0.2c-0.2,7.3,1.1,3.6,1.6,8.5 c0.3,3.1-1.9,5.8-3.4,8.3c0,0,48.4-5.2,66.5,8.3l-44.2,36c-10.7-7.1-23.6-11.3-37.4-11.3c-25.4,0-47.5,14-59,34.7 c-5.4,9.7-8.5,20.9-8.5,32.9c0,37.3,30.2,67.6,67.6,67.6s67.6-30.2,67.6-67.6c0-16.5-5.9-31.6-15.8-43.3l41.6-39.4 c14.2,14.4,7.3,48.4,6.3,84v82.8c0,5.4-1.8,16.1-5.5,19.8c-3.7,3.7-8.2,5.6-13.6,5.6c-5.4,0-9.8-1.9-13.2-5.6 c-3.4-3.8-5.1-14.5-5.1-19.9l-0.2-3c-8.9,7.4-18.8,13.1-29.8,17.2s-22.7,6.2-35,6.2c-14.2,0-27.5-2.7-39.9-8.1S98,288.1,88.8,278.8 c-4.2-4.2-8-8.8-11.3-13.7v169c0,16.6,13.4,30,30,30H614c-1.1-0.7-2-1.6-2.9-2.6C607.6,457.6,606,446.9,606,441.5z"
        />
        <path
          className="st2"
          d="M96.8,125.4c2.6-2.9,5.4-5.3,8.8-6.7c1-4.1,1.9-7.8,2.6-10.6c2.7-11.1,0.7-11.7,5.3-20.9 c0-0.1,0.1-0.1,0.1-0.2h-6.2c-16.6,0-30,13.4-30,30v29.5c3.4-4.9,7.2-9.5,11.4-13.8C91.3,130.2,94,127.7,96.8,125.4z"
        />
        <path
          className="st3"
          d="M643.3,441.6v-82.8c1-35.6,7.9-69.6-6.3-84l-41.6,39.4c9.8,11.7,15.8,26.8,15.8,43.3 c0,37.3-30.2,67.6-67.6,67.6S476,394.9,476,357.5c0-11.9,3.1-23.1,8.5-32.9l-36.3-9.1c-0.5,1.1-1,2.1-1.4,3.2 c-5.4,12.5-8,25.9-8,40.1s2.7,27.6,8,40.1s12.6,23.5,21.8,32.7c9.2,9.3,20,16.7,32.4,22.1c12.4,5.4,25.7,8.1,39.9,8.1 c12.4,0,24.1-2.1,35-6.2c11-4.1,20.9-9.8,29.8-17.2l0.2,3c0,5.4,1.7,16.1,5.1,19.9c0.9,1,1.9,1.9,2.9,2.6h20.7 c1.1-0.7,2.1-1.6,3.1-2.6C641.5,457.7,643.3,447,643.3,441.6z"
        />
        <path
          className="st4"
          d="M722.5,161.5c-3.9,0.2-12.8-2.3-19.6,2.5s-10.6,4.9-21.6,6.8c-11,1.8-39.1,1.5-46.3,5c0,0-4.8-1.2-7.9-2.4 c-3.1-1.2-12.4-2.7-16.3-6.1c-3.9-3.4-15.6-14.8-22.8-16.9c-7.1-2.1-10-2.8-11.4-3.4c-1.4-0.6-2.7-1-2.7-1s-1.6-7,2-11 c3.6-4.1,5.4-6.2,6.2-9c0.9-2.8,4.5-13.5,3.1-18.6s-4.4-14-14-15.1s-12.8-1.9-20,4.5c-7.1,6.4-6.4,10.8-5.3,16.1 c0,0,0.2,3.1-0.5,4.8c-0.7,1.7,0.4,2.3,0.4,4c0,1.6-0.7,3.3-0.1,6.3c0.6,3,0.4,7.1,0.4,7.1s-2.9,0.7-3.4,2.4c0,0-12.3-4.5-19.6-2.2 s-12.1,2.1-19.4,2.6c-7.2,0.5-11.5,4.5-20.5,6.3s-9.5,6.3-11.9,5.1c-2.4-1.3-0.4,1.4,0.8,4c1.2,2.7,1.2,5.1,1.2,5.1 s-15.2-0.4-29.1,5.1s-23,7.8-38.7,7.7c-15.7-0.2-14.1,1-16.5,2c-2.3,1-13.6,2.3-12.7,4.2c0.9,2,4,2.4,4,2.4s-3.9,2.6-2.5,3.6 s7.2-2.8,11.1-3.1c3.9-0.4-0.8,1.2,0,4.1c0.8,2.9,3.5-0.3,8.4-0.1c4.9,0.3,10.6-1,15.9-4c5.3-2.9,25-4.3,36.5-3.3 c11.5,0.9,23.7-2.1,28.4-5.7c0,0,2.5,2.6,6.3,1.4c0,0,15.1-6,20.1-5.3c4.9,0.6,9.7,0.2,9.7,0.2s1.5,5.8,1,10.4 c-0.5,4.5-2.6,8.3-1.9,13.1c0.7,4.8-3.6,9.3-5.4,17.6c-1.8,8.2-10.2,22.5-14.7,31.7s-2.6,9.8-5.3,20.9c-0.7,2.9-1.6,6.6-2.6,10.6 c-3.4,1.4-6.2,3.8-8.8,6.7c-2.8,2.3-5.5,4.8-8.1,7.4c-8.4,8.6-15.2,18.5-20.4,29.7l36.3,9.1c11.5-20.7,33.6-34.7,59-34.7 c13.8,0,26.7,4.2,37.4,11.3l44.2-36c-18.1-13.5-66.5-8.3-66.5-8.3c1.5-2.5,3.7-5.2,3.4-8.3c-0.5-4.8-1.8-1.1-1.6-8.5 c0.2-7.3,2.2-10.5,6.2-19.3s7.9-9.2,13.8-19.3c5.9-10.1,1.1-11.7,13.8-8.1c12.7,3.6,20.8,6,23.5,3.3s4.2-4.5,4.2-4.5 s12.5,1.6,17.9,1.6s8.9-0.9,20.2-4c11.3-3.2,27.9-9.8,34.1-9.5c6.2,0.3,11.5-0.6,14.5-2.9c3-2.3,5.3-3.7,6.6-3.4 c1.3,0.2,4.6,1.6,9.9,0.3c0.8-0.2,1.4-0.4,1.9-0.5v-9.2C726.1,163.2,725.4,161.4,722.5,161.5z"
        />
        <path
          className="st3"
          d="M121.1,300.8c12.4,5.4,25.7,8.1,39.9,8.1c12.4,0,24.1-2.1,35-6.2c11-4.1,20.9-9.8,29.8-17.2l0.2,3 c0,5.4,1.7,16.1,5.1,19.9c3.4,3.8,7.8,5.6,13.2,5.6s9.9-1.9,13.6-5.6c3.7-3.7,5.5-14.5,5.5-19.8v-82.8c1-35.6,7.9-69.6-6.3-84 l-41.6,39.4c9.8,11.7,15.8,26.8,15.8,43.3c0,37.3-30.2,67.6-67.6,67.6s-67.6-30.2-67.6-67.6c0-11.9,3.1-23.1,8.5-32.9l-27.3-6.9 V265c3.4,4.9,7.2,9.5,11.3,13.7C97.9,288,108.7,295.3,121.1,300.8z"
        />
        <path
          className="st4"
          d="M104.6,171.6c11.5-20.7,33.6-34.7,59-34.7c13.8,0,26.7,4.2,37.4,11.3l44.2-36c-18.1-13.5-66.5-8.3-66.5-8.3 c1.5-2.5,3.7-5.2,3.4-8.3c-0.5-4.8-1.8-1.1-1.6-8.5c0-0.1,0-0.2,0-0.2h-67.1c0,0.1-0.1,0.1-0.1,0.2c-4.5,9.2-2.6,9.8-5.3,20.9 c-0.7,2.9-1.6,6.6-2.6,10.6c-3.4,1.4-6.2,3.8-8.8,6.7c-2.8,2.3-5.5,4.8-8.1,7.4c-4.2,4.3-8,8.9-11.4,13.8v18.2L104.6,171.6z"
        />
      </g>
      {props.player?.ligueName && (
        <path
          className="st5"
          d="M576.3,166h-348c-2.2,0-4-1.7-4-3.8v-17.4c0-2.1,1.8-3.8,4-3.8h348c2.2,0,4,1.7,4,3.8v17.4 C580.4,164.3,578.6,166,576.3,166z"
        />
      )}
      {props.player?.category && props.player?.role && (
        <path
          className="st6"
          d="M556.5,219h-313c-6.9,0-12.5-5.6-12.5-12.5l0,0c0-6.9,5.6-12.5,12.5-12.5h313c6.9,0,12.5,5.6,12.5,12.5l0,0 C569,213.4,563.4,219,556.5,219z"
        />
      )}
      <text
        transform="matrix(1 0 0 1 215.2412 135.6426)"
        className="st7 st8"
        style={{
          fontFamily: "century-gothic, sans-serif",
          fontWeight: "bold",
        }}
      >
        {props.player?.federationName}
      </text>
      <text
        transform="matrix(1 0 0 1 235.9412 158.6426)"
        className="st9 st7 st8"
        style={{
          fontFamily: "century-gothic, sans-serif",
          fontWeight: "bold",
        }}
      >
        {props.player?.ligueName}
      </text>
      {props.player?.category && props.player?.role && (
        <>
          <text
            transform="matrix(1 0 0 1 350.563 213.6426)"
            className="st10 st7 st8"
            style={{
              fontFamily: "century-gothic, sans-serif",
              fontWeight: "normal",
            }}
          >
            {props.player?.category} -
          </text>
          <text
            transform="matrix(1 0 0 1 400.563 213.6426)"
            className="st10 st7 st8"
            style={{
              fontFamily: "century-gothic, sans-serif",
              fontWeight: "bold",
            }}
          >
            {props.player?.role}
          </text>
        </>
      )}
      <g>
        <g>
          <g>
            <defs>
              <path
                id="SVGID_1_"
                d="M254.4,356.2h-65.1c-12,0-21.7-9.7-21.7-21.7v-81.8c0-12,9.7-21.7,21.7-21.7h65.1 c12,0,21.7,9.7,21.7,21.7v81.8C276.1,346.5,266.4,356.2,254.4,356.2z"
              />
            </defs>
            <clipPath id="SVGID_2_">
              <use
                xlinkHref="#SVGID_1_"
                style={{
                  overflow: "visible",
                }}
              />
            </clipPath>
            <g transform="matrix(1 0 0 1 0 -1.525879e-05)" className="st12">
              <image
                style={{
                  overflow: "visible",
                  enableBackground: "new",
                }}
                width={980}
                height={980}
                xlinkHref={props.player?.image}
                transform="matrix(0.1503 0 0 0.1503 148.191 221.2987)"
              />
            </g>
          </g>
        </g>
      </g>
      <rect x={297.6} y={240.7} className="st13" width={198.6} height={153.4} />
      <text
        transform="matrix(1 0 0 1 297.5635 253.7627)"
        className="st14 st15"
        style={{
          fontFamily: "century-gothic, sans-serif",
          fontWeight: "normal",
        }}
      >
        {"Prénom"} :
      </text>
      <text
        transform="matrix(1 0 0 1 297.5635 278.8627)"
        className="st14 st15"
        style={{
          fontFamily: "century-gothic, sans-serif",
          fontWeight: "normal",
        }}
      >
        {"Nom"} :
      </text>
      {props.player?.dateNaissance && (
        <text
          transform="matrix(1 0 0 1 297.5635 303.9627)"
          className="st14 st15"
          style={{
            fontFamily: "century-gothic, sans-serif",
            fontWeight: "normal",
          }}
        >
          {"Date de naissance"} :
        </text>
      )}
      {props.player?.extrait && (
        <text
          transform="matrix(1 0 0 1 297.5635 328.9627)"
          className="st14 st15"
          style={{
            fontFamily: "century-gothic, sans-serif",
            fontWeight: "normal",
          }}
        >
          {"Extrait :"}
        </text>
      )}
      <text
        transform="matrix(1 0 0 1 348.6635 328.9627)"
        className="st14 st15 st16"
        style={{
          fontFamily: "century-gothic, sans-serif",
          fontWeight: "normal",
        }}
      />
      {props?.player.dateQualification && (
        <text
          transform="matrix(1 0 0 1 297.5635 354.0627)"
          className="st14 st15"
          style={{
            fontFamily: "century-gothic, sans-serif",
            fontWeight: "normal",
          }}
        >
          {"Date de qualification"} :
        </text>
      )}
      <g>
        <g>
          <path
            className="st17"
            d="M397.2,370.1h-97.3v25.3h97.3c7.4,0,13.5-5.7,13.5-12.7C410.7,375.8,404.7,370.1,397.2,370.1z"
          />
          <path
            className="st18"
            d="M178.6,370.1c-7.4,0-13.5,5.7-13.5,12.7s6,12.7,13.5,12.7h121.3v-25.3H178.6V370.1z"
          />
        </g>
        <text
          transform="matrix(1 0 0 1 178.6079 388.5068)"
          className="st19 st7 st20"
          style={{
            fontFamily: "century-gothic, sans-serif",
            fontWeight: "normal",
          }}
        >
          {"N° de licence :"}
        </text>
        <text
          transform="matrix(1 0 0 1 310.6079 388.5068)"
          className="st19 st7 st20"
          style={{
            fontFamily: "century-gothic, sans-serif",
            fontWeight: "bold",
          }}
        >
          {props.player?.licenceNumber || props.player?.licenceId}
        </text>
      </g>
      <g className="st21">
        <path
          className="st19"
          d="M636,255.8H494.2c-3,0-5.4-2.4-5.4-5.4v-9.1c0-3,2.4-5.4,5.4-5.4H636c3,0,5.4,2.4,5.4,5.4v9.1 C641.4,253.4,639,255.8,636,255.8z"
        />
        <path
          className="st19"
          d="M636,280.9H494.2c-3,0-5.4-2.4-5.4-5.4v-9.1c0-3,2.4-5.4,5.4-5.4H636c3,0,5.4,2.4,5.4,5.4v9.1 C641.4,278.4,639,280.9,636,280.9z"
        />
        <path
          className="st19"
          d="M636,305.9H494.2c-3,0-5.4-2.4-5.4-5.4v-9.1c0-3,2.4-5.4,5.4-5.4H636c3,0,5.4,2.4,5.4,5.4v9.1 C641.4,303.5,639,305.9,636,305.9z"
        />
        <path
          className="st19"
          d="M636,331H494.2c-3,0-5.4-2.4-5.4-5.4v-9.1c0-3,2.4-5.4,5.4-5.4H636c3,0,5.4,2.4,5.4,5.4v9.1 C641.4,328.6,639,331,636,331z"
        />
        <path
          className="st19"
          d="M636,356.1H494.2c-3,0-5.4-2.4-5.4-5.4v-9.1c0-3,2.4-5.4,5.4-5.4H636c3,0,5.4,2.4,5.4,5.4v9.1 C641.4,353.7,639,356.1,636,356.1z"
        />
      </g>
      <rect x={499.2} y={239.6} className="st13" width={133.8} height={153.4} />
      <text
        transform="matrix(1 0 0 1 510.1621 252.7178)"
        className="st7 st15"
        style={{
          fontFamily: "century-gothic, sans-serif",
          fontWeight: "bold",
        }}
      >
        {props.player?.nom}
      </text>
      <text
        transform="matrix(1 0 0 1 510.6621 277.8178)"
        className="st7 st15"
        style={{
          fontFamily: "century-gothic, sans-serif",
          fontWeight: "bold",
        }}
      >
        {props.player?.prenom}
      </text>
      {props?.player?.dateNaissance && (
        <text
          transform="matrix(1 0 0 1 510.8621 302.9178)"
          className="st7 st15"
          style={{
            fontFamily: "century-gothic, sans-serif",
            fontWeight: "bold",
          }}
        >
          {props.player?.dateNaissance}
        </text>
      )}
      {props.player?.extrait && (
        <text
          transform="matrix(1 0 0 1 510.1621 327.9178)"
          className="st7 st15"
          style={{
            fontFamily: "century-gothic, sans-serif",
            fontWeight: "bold",
          }}
        >
          {props.player?.extrait}
        </text>
      )}
      {props.player?.dateQualification && (
        <text
          transform="matrix(1 0 0 1 510.8621 353.0178)"
          className="st7 st15"
          style={{
            fontFamily: "century-gothic, sans-serif",
            fontWeight: "bold",
          }}
        >
          {props.player?.dateQualification}
        </text>
      )}
      <path
        className="st19"
        d="M671.1,437.8h-48.8c-3.4,0-6.1-2.7-6.1-6.1V384c0-3.4,2.7-6.1,6.1-6.1h48.8c3.4,0,6.1,2.7,6.1,6.1v47.7 C677.2,435,674.4,437.8,671.1,437.8z"
      />
      <image
        style={{
          overflow: "visible",
          enableBackground: "new",
        }}
        width={225}
        height={225}
        xlinkHref={props?.player?.qrCode || "../../assets/images/qrCode.png"}
        transform="matrix(0.1689 0 0 0.1689 627 388)"
      />
      {props.player?.federationLogo && (
        <g id="XVKTTd_1_">
          <image
            style={{
              overflow: "visible",
              enableBackground: "new",
            }}
            width={499}
            height={594}
            id="XVKTTd"
            xlinkHref={props.player?.federationLogo}
            transform="matrix(0.1155 0 0 0.1155 616.0803 111.4344)"
          />
        </g>
      )}
      {props.player?.ligueLogo && (
        <g id="XVKTTd_2_">
          <image
            style={{
              overflow: "visible",
              enableBackground: "new",
            }}
            width={499}
            height={594}
            id="XVKTTd_3_"
            xlinkHref={props.player?.ligueLogo}
            transform="matrix(0.1155 0 0 0.1155 153.0802 111.4344)"
          />
        </g>
      )}
    </svg>
  );
};
export default playerCard;

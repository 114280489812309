import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StickyHeadTable from "../components/Tables/StickyHeadTable";
import { useSelector } from "react-redux";
import { Avatar, TextField, Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loading from "../components/Loadings/Loading";
import MatchSelected from "../components/layouts/MatchSelected";
import { AuthContext } from "../contexts/AuthContext";
import { useSnackbar } from "notistack";
import { getTeamById } from "../redux/selectors/matchSelectors";
import { getReserveMotifText } from "../constants/utils";
import {
  createReservesTeamplate,
  getReservesTeamplate,
} from "../redux/middleware/matchReservesThunks";
import GoBackButton from "../components/GoBackButton";

const ReservesPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { match, type } = location.state;
  const loading = useSelector((state) => state.matchReducer.loading);
  const error = useSelector((state) => state.matchReducer.error);
  const { user } = useContext(AuthContext);
  const [filter, setFilter] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [motifText, setMotifText] = useState("");
  const [selectedRoleFilter, setSelectedRoleFilter] = useState("");
  const teams = useSelector((state) => state.matchReducer.teams);
  const [selectedReserveId, setSelectedReserveId] = useState(null);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [currentTeam, setCurrentTeam] = useState("teamVisiteuse");
  const [teamRecevant, setTeamRecevant] = useState([]);
  const [teamVisiteuse, setTeamVisieuse] = useState([]);
  const [userSelected, setUserSelected] = useState([]);
  const [teamplate, setTeamplate] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const columns = [
    {
      id: "fullName",
      label: t("FULL_NAME"),
      minWidth: 100,
      format: (value, row) => (
        <div className="flex items-center space-x-2">
          <Avatar
            alt={value}
            src={row.image}
            className="w-10 h-10 rounded-full object-cover"
          />
          <span className="font-medium font-myteam text-gray-700">{value}</span>
        </div>
      ),
    },
    { id: "role", label: t("ROLE_FUNCTION"), minWidth: 100 },
    { id: "shirtNmbr", label: t("SHIRT_NUMBER"), minWidth: 100 },
    {
      id: "licenceNumber",
      label: t("LICENCE_NUMBER"),
      minWidth: 170,
    },
  ];

  useEffect(() => {
    if (type) {
      const fetchTeamplate = async () => {
        const data = await getReservesTeamplate("reserves", type);
        setTeamplate(data);
      };

      fetchTeamplate();
    }
  }, []);

  useEffect(() => {
    setTeamRecevant(getTeamById(teams, match.match.equipeHome.id));
    setTeamVisieuse(getTeamById(teams, match.match.equipeAway.id));
    setCurrentTeam("teamRecevant");
  }, [teamRecevant, teamVisiteuse]);

  useEffect(() => {
    const allPlayersAndStaffs =
      currentTeam === "teamRecevant"
        ? [...(teamRecevant?.team ?? [])]
        : [...(teamVisiteuse?.team ?? [])];
    setFilteredPlayers(
      allPlayersAndStaffs.filter((eqp) => {
        const filterWords = filter.trim().toLowerCase().split(/\s+/);
        const fullName = `${eqp.nom.toLowerCase()} ${eqp.prenom.toLowerCase()}`;
        return filterWords.every((word) => fullName.includes(word));
      })
    );
  }, [filter, currentTeam, teams]);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const filteredPlayersByRole = selectedRoleFilter
    ? filteredPlayers.filter((eqp) => eqp.role === selectedRoleFilter)
    : filteredPlayers;

  const rows = filteredPlayersByRole?.map((eqp) => ({
    id: eqp.id,
    image: eqp.image,
    firstName: eqp.nom,
    isCaptain: eqp.isCaptain || false,
    lastName: eqp.prenom,
    fullName: `${eqp.nom} ${eqp.prenom}`,
    role: eqp.role,
    licenceNumber: eqp.licenceId || "-",
    shirtNmbr: eqp.shirtNumber || eqp?.shirtNmbr || "-",
    position_index: eqp.position_index,
    post: eqp.post,
    playerSelected: userSelected?.id == eqp.id && true,
    licenceCreatedAt: new Date(eqp.licenceIdCreatedAt),
  }));

  const handleSelectChange = (e) => {
    const value = e.target.value;
    const selectedReserve = reserves.find((reserve) => reserve.value === value);

    if (
      ((value === "MOTIF_SUSPENDED_PLAYER" ||
        value === "MOTIF_LICENSE_ISSUE") &&
        !userSelected) ||
      userSelected?.length === 0
    ) {
      const variant = "error";
      enqueueSnackbar(t("SELECT_USER_ERROR"), { variant });
      return;
    }
    setSelectedReserveId(selectedReserve?.id || null);
    setSelectedOption(value);
    setMotifText(
      getReserveMotifText(
        value,
        user,
        match,
        currentTeam,
        teamRecevant,
        teamVisiteuse,
        userSelected,
        t
      )
    );
  };

  const handleSendingReserve = async () => {
    if (!motifText) {
      const variant = "error";
      enqueueSnackbar(t("FILL_ALL_FIELDS"), { variant });
      return;
    }
    const payload = {
      match: match?.match?.id,
      equipe:
        currentTeam == "teamRecevant" ? teamRecevant?.id : teamVisiteuse?.id,
      reserve: selectedReserveId,
      joueur: userSelected?.id,
      type: type,
    };
    try {
      const createdReserve = await createReservesTeamplate(payload);
      if (createdReserve == 200) {
        setUserSelected(null);
        setMotifText("");
        setSelectedOption("");
        const variant = "success";
        enqueueSnackbar(t("RESERVE_SENT_SUCCESSFULLY"), { variant });
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePlayerClick = (user) => {
    setMotifText("");
    setSelectedOption("");
    setUserSelected((prevState) => (prevState?.id == user.id ? null : user));
  };

  const uniqueRoles = [
    ...new Set(
      currentTeam == "teamRecevant"
        ? [...(teamRecevant?.team?.map((row) => row.role) ?? [])]
        : [...(teamVisiteuse?.team?.map((row) => row.role) ?? [])]
    ),
  ];
  const reserves = [
    { id: 0, label: t("SELECT_RESERVE"), value: "" },
    ...teamplate?.map((item, index) => ({
      id: item?.id,
      label: t(item.title),
      value: item.text,
    })),
  ];

  const handleRoleFilterChange = (e) => {
    setSelectedRoleFilter(e.target.value);
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <MatchSelected match={match} t={t} />
          <div className="mt-4 p-4 bg-white shadow-lg rounded-lg">
            {<GoBackButton />}
            <div className="flex max-sm:flex-wrap mt-4 justify-between">
              <div className="max-sm:w-full w-1/2 px-4 mb-4">
                <div className="flex items-center pb-5">
                  <button
                    onClick={() => {
                      setCurrentTeam("teamRecevant");
                    }}
                    style={{
                      zIndex: 100,
                      backgroundColor:
                        currentTeam === "teamRecevant" ? "blue" : "#f0f0f0",
                      marginRight: 10,
                      padding: 5,
                    }}
                  >
                    <div className="flex items-center justify-center space-x-2">
                      <Avatar
                        alt={teamRecevant?.name}
                        src={teamRecevant?.logo}
                        className="w-10 h-10 rounded-full object-cover"
                      />
                      <span
                        style={{
                          color:
                            currentTeam === "teamRecevant" ? "white" : "black",
                        }}
                        className="font-myteam font-semibold max-w-xs truncate"
                      >
                        {teamRecevant?.name}
                      </span>
                    </div>
                  </button>
                  <button
                    onClick={() => {
                      setCurrentTeam("teamVisiteuse");
                    }}
                    style={{
                      zIndex: 100,
                      backgroundColor:
                        currentTeam === "teamVisiteuse" ? "blue" : "#f0f0f0",
                      padding: 5,
                    }}
                  >
                    <div className="flex items-center justify-center space-x-2">
                      <span
                        style={{
                          color:
                            currentTeam === "teamVisiteuse" ? "white" : "black",
                        }}
                        className="font-myteam font-bold max-w-xs truncate"
                      >
                        {teamVisiteuse?.name}
                      </span>
                      <Avatar
                        src={teamVisiteuse?.logo}
                        alt={teamVisiteuse?.name}
                        className="w-10 h-10 rounded-full object-cover"
                      />
                    </div>
                  </button>
                </div>
                <div className="flex">
                  <TextField
                    label={t("SEARCH_BY_NAME")}
                    value={filter}
                    onChange={handleFilterChange}
                    variant="outlined"
                    className="mb-4 mx-2 w-1/2 h-16"
                  />
                  <Select
                    value={selectedRoleFilter}
                    onChange={handleRoleFilterChange}
                    displayEmpty
                    className="mb-4 mx-2 w-1/2 h-3/5"
                    variant="outlined"
                    style={{
                      backgroundColor: "#fafafa",
                    }}
                  >
                    <MenuItem value="" className="font-myteam">
                      {t("ALL_ROLES")}
                    </MenuItem>
                    {uniqueRoles.map((role) => (
                      <MenuItem key={role} value={role}>
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <StickyHeadTable
                  loading={loading}
                  columns={columns}
                  rows={rows}
                  error={error}
                  cursor={"cursor-pointer"}
                  headerColor={"black"}
                  headerLabelsColor={"white"}
                  initialRowsPerPage={25}
                  onRowClick={handlePlayerClick}
                />
              </div>
              <div className="max-sm:w-full w-1/2 flex mb-4">
                <div className="w-full h-full max-sm:w-full">
                  <div className="mb-4">
                    <label className="block mb-2 font-myteam font-bold ">
                      {t("PLACE_RESERVE")}
                    </label>
                    <select
                      style={{
                        backgroundColor: "#fafafa",
                      }}
                      onChange={handleSelectChange}
                      value={selectedOption}
                      className="w-full p-2 border border-gray-300 rounded"
                    >
                      {reserves.map((reserve) => (
                        <option
                          key={reserve.value}
                          id={reserve?.id}
                          value={reserve.value}
                        >
                          {reserve.label}
                        </option>
                      ))}
                      <option value="autres">{t("OTHER")}</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label className="block mb-2 font-myteam font-bold ">
                      {t("DESCRIPTION")}
                    </label>
                    <textarea
                      style={{
                        backgroundColor: "#fafafa",
                        minHeight: 200,
                        maxHeight:400
                      }}
                      value={motifText}
                      onChange={(e) => setMotifText(e.target.value)}
                      className="w-full max-h-32 p-2 border  border-gray-300 rounded"
                      readOnly={selectedOption !== "autres"}
                    />
                  </div>
                  <div className="flex justify-end">
                    <button
                      onClick={() => handleSendingReserve()}
                      className="bg-gray-200 py-2 px-4 font-myteam font-bold rounded"
                    >
                      {t("PALCE_RESERVATION")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ReservesPage;

import React from "react";
import languages from "../../locales/LanguagesSupport";
import { useTranslation } from "react-i18next";

export default function LanguageSelects() {
  const { i18n } = useTranslation();

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const selectedLang = languages.find((lang) => lang.code === i18n.language);
  return (
    <div className="flex justify-center font-myteam items-center">
      <img
        src={"../../assets/images/flags/langs.png"}
        alt={`Flag ${i18n.language.toUpperCase()}`}
        className="h-8 mr-2 ml-2"
      />
      <select
        style={{
          backgroundColor: "#fafafa",
        }}
        className="bg-transparent font-myteam text-black border-gray-600 border-2 py-1 px-2 rounded"
        value={i18n.language}
        onChange={(e) => handleChangeLanguage(e.target.value)}
      >
        {languages.map((lang) => (
          <option key={lang.code} value={lang.code}>
            {lang.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export const FETCH_MATCHES_REQUEST = "FETCH_MATCHES_REQUEST";
export const FETCH_MATCHES_SUCCESS = "FETCH_MATCHES_SUCCESS";
export const FETCH_MATCHES_FAILURE = "FETCH_MATCHES_FAILURE";

export const FETCH_TEAM_REQUEST = "FETCH_TEAM_REQUEST";
export const FETCH_TEAM_SUCCESS = "FETCH_TEAM_SUCCESS";
export const FETCH_TEAM_FAILURE = "FETCH_TEAM_FAILURE";

export const FETCH_COMPOSITION_REQUEST = "FETCH_COMPOSITION_REQUEST";
export const FETCH_COMPOSITION_FAILURE = "FETCH_COMPOSITION_FAILURE";
export const FETCH_COMPOSITION_SUCCESS = "FETCH_COMPOSITION_SUCCESS";
export const SAVE_SERVER_COMPOSITION_SUCCESS =
  "SAVE_SERVER_COMPOSITION_SUCCESS";

export const UPDATE_COMPOSITION_REQUEST = "UPDATE_COMPOSITION_REQUEST";
export const UPDATE_COMPOSITION_SUCCESS = "UPDATE_COMPOSITION_SUCCESS";
export const UPDATE_COMPOSITION_FAILURE = "UPDATE_COMPOSITION_FAILURE";

export const VALIDATE_COMPOSITION_REQUEST = "VALIDATE_COMPOSITION_REQUEST";
export const VALIDATE_COMPOSITION_SUCCESS = "VALIDATE_COMPOSITION_SUCCESS";
export const VALIDATE_COMPOSITION_FAILURE = "VALIDATE_COMPOSITION_FAILURE";
export const UPDATE_PLAYER_INFO = "UPDATE_PLAYER_INFO";

export const fetchMatchesRequest = () => ({
  type: FETCH_MATCHES_REQUEST,
});

export const fetchMatchesSuccess = (matches) => ({
  type: FETCH_MATCHES_SUCCESS,
  payload: matches,
});

export const fetchMatchesFailure = (error) => ({
  type: FETCH_MATCHES_FAILURE,
  payload: error,
});

export const fetchTeamRequest = () => ({
  type: FETCH_TEAM_REQUEST,
});

export const fetchTeamSuccess = (Team) => ({
  type: FETCH_TEAM_SUCCESS,
  payload: Team,
});

export const fetchTeamFailure = (error) => ({
  type: FETCH_TEAM_FAILURE,
  payload: error,
});

export const fetchCompositionRequest = () => ({
  type: FETCH_COMPOSITION_REQUEST,
});

export const fetchCompositionFailure = (error) => ({
  type: FETCH_COMPOSITION_FAILURE,
  payload: error,
});

export const updateCompositionRequest = () => ({
  type: UPDATE_COMPOSITION_REQUEST,
});

export const updateCompositionSuccess = (composition) => ({
  type: UPDATE_COMPOSITION_SUCCESS,
  payload: composition,
});
export const saveCompositionInServerSuccess = () => ({
  type: SAVE_SERVER_COMPOSITION_SUCCESS,
});
export const fetchCompositionSuccess = (composition) => ({
  type: FETCH_COMPOSITION_SUCCESS,
  payload: composition,
});
export const updateCompositionFailure = (error) => ({
  type: UPDATE_COMPOSITION_FAILURE,
  payload: error,
});
export const updatePlayerInfo = (payload) => ({
  type: UPDATE_PLAYER_INFO,
  payload: payload,
});


export const validateCompositionRequest = () => ({
  type: VALIDATE_COMPOSITION_REQUEST,
});
export const validateCompositionSuccess = () => ({
  type: VALIDATE_COMPOSITION_SUCCESS,
});
export const validateCompositionFailure = (error) => ({
  type: VALIDATE_COMPOSITION_FAILURE,
  payload: error,
});

import React, { useState } from "react";
import GlobalModal from "./GlobalModal";
import { Alert, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import DateTimePickers from "../layouts/DateTimePickers";
import dayjs from "dayjs";

function ArbitreFormModal({ isOpen, setOpen, t, match }) {
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <GlobalModal
      isOpen={isOpen}
      setOpen={setOpen}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <div className="bg-white w-full max-w-lg rounded-lg p-6 shadow-lg">
        <div className="flex justify-end">
          <button
            onClick={() => setOpen(false)}
            className="text-gray-500 hover:text-gray-700"
          >
            <Close />
          </button>
        </div>
        <h2 className="text-2xl font-myteam font-bold text-center mb-4">
          {t("Infos Arbitre")}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="text-center">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
              <div className="mt-1 block w-full p-2 rounded-md">
                <TextField
                  id="outlined-basic"
                  label="Lieu"
                  value="Lieu"
                  variant="outlined"
                />
              </div>
              <div className="mt-1 block w-full p-2 rounded-md">
                <TextField
                  id="outlined-basic"
                  label="Terrain"
                  defaultValue="Terrain"
                  variant="outlined"
                />
              </div>
              <div className="mt-1 block w-full p-2  rounded-md">
                <DateTimePickers
                  value={selectedDate}
                  onChange={handleDateChange}
                />
              </div>
              <div className="mt-1 block w-full p-2 rounded-md">
                <TextField
                  id="outlined-basic"
                  label="Medecin"
                  value="Medecin"
                  variant="outlined"
                />
              </div>
              <div className="mt-1 block w-full p-2 rounded-md">
                <TextField
                  id="outlined-basic"
                  label="Technicien lumiere"
                  value="Technicien lumiere"
                  variant="outlined"
                />
              </div>
              <div className="mt-1 block w-full p-2 rounded-md">
                <TextField
                  id="outlined-basic"
                  label="Directeur de securite"
                  value="Directeur de securite"
                  variant="outlined"
                />
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white font-myteam font-bold py-2 rounded-md hover:bg-blue-600"
          >
            {t("VALIDER INFOS ARBITRE")}
          </button>
        </form>
      </div>
    </GlobalModal>
  );
}

export default ArbitreFormModal;

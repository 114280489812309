import React from "react";
import ListOL from "../Lists/ListOL";

const TeamSection = ({
  match,
  team,
  teamLicences,
  teamsCompositionValidate,
  handleConnexion,
  t,
  teamType,
}) => (
  <div className="max-sm:w-full w-1/2 px-4 mb-4 h-full">
    <div className="flex items-start justify-between">
      <div className="flex font-myteam items-center uppercase text-lg font-semibold mb-4">
        <img
          alt={team?.name}
          src={team?.image}
          className="w-10 h-10 rounded mx-2"
        />
        {t(teamType)}
      </div>
      <div>
        {!teamsCompositionValidate.some(
          (teamValid) =>
            teamValid.teamSlug.toLowerCase() == team?.slug.toLowerCase()
        ) ? (
          <button
            onClick={() => handleConnexion(team?.id, team?.slug)}
            className="bg-green-500 font-myteam hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
            {t("CONNEXION")}
          </button>
        ) : (
        //   <button
        //     onClick={() => console.log("invalid team")}
        //     className="bg-red-500 font-myteam hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
        //   >
        //     {t("IN_VALIDER")}
        //   </button>
        <></>
        )}
      </div>
    </div>
    <div className="border-2 bg-slate-100 h-full rounded">
      {!teamsCompositionValidate.some(
        (teamValid) =>
          teamValid.teamSlug.toLowerCase() == team?.slug.toLowerCase()
      ) ? (
        <div className="flex justify-center">
          <div className="w-2/5 h-2/5 text-center p-10">
            <img alt={team?.name} src={team?.image} className="w-full h-full" />
            <span className="font-bold font-myteam text-gray-700 max-w-xs truncate text-center">
              {team?.name}
            </span>
          </div>
        </div>
      ) : (
        <ListOL items={teamLicences?.teamList} maxHeight="max-h-96" />
      )}
    </div>
  </div>
);

export default TeamSection;

import React, { useContext, useState } from "react";
import { Avatar } from "@mui/material";
import { addEventToState } from "../../../redux/actions/matchEventsActions";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { ContactSupportOutlined } from "@mui/icons-material";
import { AuthContext } from "../../../contexts/AuthContext";
import { useSelector } from "react-redux";
import { MobileContext } from "../../../dashboard/src/context/isMobileContext";
import ModalSelectPlayerMobile from "../../../dashboard/src/components/Modal/ModalSelectPlayerMobile";

const GoalsEvent = ({
  goalScorer,
  assistPlayer,
  setPlayerSelected,
  t,
  setScoore,
  Scoore,
  currentTeam,
  teamRecevant,
  teamVisiteuse,
  match,
  players,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(AuthContext);
  const isMobile = useContext(MobileContext);
  const [goalTime, setGoalTime] = useState({
    RegularTime: 0,
    extraTime: 0,
  });

  const [isMSCChecked, setIsMSCChecked] = useState(false);
  const [openModalSelectPlayerBut, setOpenModalSelectPlayerBut] =
    useState(false);
  const [openModalSelectPlayerAssist, setOpenModalSelectPlayerAssist] =
    useState(false);
  const [isPenalty, setIsPenalty] = useState(false);
  // const [adversaireGoal, setAdversaireGoal] = useState(false);
  const matchHasArtbiter = useSelector(
    (state) => state.matchReducer.matchHasArtbiter
  );

  const handleSelectModalPlayer = (player, etat) => {
    if (etat === "But" && assistPlayer?.id !== player?.id) {
      setPlayerSelected([player, assistPlayer]);
    } else if (etat === "Assist" && goalScorer?.id !== player?.id) {
      setPlayerSelected([goalScorer, player]);
    }
  };

  const handleValidateGoal = () => {
    const { RegularTime, extraTime } = goalTime;

    if (matchHasArtbiter && (!goalScorer || !RegularTime)) {
      const variant = "error";
      enqueueSnackbar(t("FILL_ALL_FIELDS"), { variant });
      return;
    }

    if (assistPlayer?.teamId && goalScorer?.teamId !== assistPlayer?.teamId) {
      enqueueSnackbar(t("PLAYERS_FROM_DIFFERENT_TEAMS"), {
        variant: "error",
      });
      return;
    }

    const isValidRegularTime =
      RegularTime === 45 ||
      RegularTime === 90 ||
      RegularTime === 105 ||
      RegularTime === 120;

    let teamId;
    let teamName;
    let teamLogo;
    if (isMSCChecked) {
      if (goalScorer?.teamId == teamRecevant?.id) {
        teamId = matchHasArtbiter
          ? teamVisiteuse.id
          : match?.match?.equipeAway?.id;
        teamName = matchHasArtbiter
          ? teamVisiteuse.name
          : match?.match?.equipeAway?.name;
        teamLogo = matchHasArtbiter
          ? teamVisiteuse.logo
          : match?.match?.equipeAway?.image;
      } else {
        teamId = matchHasArtbiter
          ? teamRecevant.id
          : match?.match?.equipeHome?.id;
        teamName = matchHasArtbiter
          ? teamRecevant.name
          : match?.match?.equipeHome?.name;
        teamLogo = matchHasArtbiter
          ? teamRecevant.logo
          : match?.match?.equipeHome?.image;
      }
    } else {
      if (goalScorer?.teamId == teamRecevant?.id) {
        teamId = teamRecevant.id;
        teamName = teamRecevant.name;
        teamLogo = teamRecevant.logo;
      } else {
        teamId = teamVisiteuse.id;
        teamName = teamVisiteuse.name;
        teamLogo = teamVisiteuse.logo;
      }
    }
    // if (!matchHasArtbiter && adversaireGoal) {
    //   if (user?.equipe_id == teamRecevant?.id) {
    //     teamId = teamVisiteuse.id;
    //     teamName = teamVisiteuse.name;
    //     teamLogo = teamVisiteuse.logo;
    //   } else {
    //     teamId = teamRecevant.id;
    //     teamName = teamRecevant.name;
    //     teamLogo = teamRecevant.logo;
    //   }
    // }
    const goalsEvent = {
      team: {
        id: teamId,
        name: teamName,
        logo: teamLogo,
      },
      goalScorer: goalScorer,
      assistPlayer: assistPlayer,
      type: isMSCChecked ? "own" : isPenalty ? "penalty" : "regular",
      eventName: "goalScorer",
      RegularTime,
      extraTime: isValidRegularTime ? extraTime : 0,
    };

    setScoore((prevScoore) => {
      const defaultScoore = {
        equipeAwayScoore: prevScoore?.equipeAwayScoore || 0,
        equipeHomeScoore: prevScoore?.equipeHomeScoore || 0,
      };

      if (isMSCChecked) {
        if (teamRecevant && teamRecevant?.id != teamId) {
          return {
            ...defaultScoore,
            equipeAwayScoore: defaultScoore.equipeAwayScoore + 1,
          };
        } else if (teamVisiteuse && teamVisiteuse?.id != teamId) {
          return {
            ...defaultScoore,
            equipeHomeScoore: defaultScoore.equipeHomeScoore + 1,
          };
        } else {
          return {
            ...defaultScoore,
            equipeHomeScoore: defaultScoore.equipeHomeScoore + 1,
          };
        }
      } else {
        if (teamRecevant?.id === teamId) {
          return {
            ...defaultScoore,
            equipeHomeScoore: defaultScoore.equipeHomeScoore + 1,
          };
        } else {
          return {
            ...defaultScoore,
            equipeAwayScoore: defaultScoore.equipeAwayScoore + 1,
          };
        }
      }
    });

    //console.log(t("GOAL_DATA"), goalsEvent);
    dispatch(addEventToState(goalsEvent));

    setPlayerSelected([]);
    setIsMSCChecked(false);
    setIsPenalty(false);
    setGoalTime({ RegularTime: 0, extraTime: 0 });
  };

  const handleChange = (field, value) => {
    setGoalTime((prev) => ({ ...prev, [field]: parseInt(value) }));
  };

  const handlePlayerClick = (player) => {
    setPlayerSelected((prevState) => {
      const isSelected = prevState.some((p) => p?.id === player?.id);
      if (isSelected) {
        return prevState.filter((p) => p?.id !== player?.id);
      } else if (prevState.length < 2) {
        return [...prevState, player];
      } else {
        return prevState;
      }
    });
  };

  const isExtraTimeDisabled = !(
    goalTime.RegularTime === 45 ||
    goalTime.RegularTime === 90 ||
    goalTime.RegularTime === 105 ||
    goalTime.RegularTime === 120
  );

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg text-center border border-gray-300">
      <h2 className="text-xl max-sm:text-lg font-bold font-myteam mb-4">
        {t("LOG_GOAL_EVENT")}
      </h2>
      {openModalSelectPlayerBut ? (
        <ModalSelectPlayerMobile
          players={players}
          isOpen={openModalSelectPlayerBut}
          callback={(ply) => handleSelectModalPlayer(ply, "But")}
          onClose={() => setOpenModalSelectPlayerBut(false)}
        />
      ) : null}
      {openModalSelectPlayerAssist ? (
        <ModalSelectPlayerMobile
          players={players}
          isOpen={openModalSelectPlayerAssist}
          callback={(ply) => handleSelectModalPlayer(ply, "Assist")}
          onClose={() => setOpenModalSelectPlayerAssist(false)}
        />
      ) : null}

      <div className="flex flex-wrap justify-around gap-4">
        <div className="flex justify-between mb-4">
          <div className="flex items-center space-x-4">
            <div className="text-center">
              <label
                htmlFor="goalScorer"
                className="block text-sm font-myteam font-medium text-gray-700 mb-1"
              >
                {t("GOAL_SCORER")}
              </label>
              <div className="space-y-2">
                {goalScorer ? (
                  <>
                    <Avatar
                      key={goalScorer.id}
                      alt={goalScorer.fullName}
                      src={goalScorer.image}
                      className="mx-auto cursor-pointer"
                      onClick={() => handlePlayerClick(goalScorer)}
                    />
                    <p>{goalScorer.fullName}</p>
                  </>
                ) : (
                  <div
                    className="flex font-myteam justify-center"
                    onClick={() =>
                      isMobile ? setOpenModalSelectPlayerBut(true) : undefined
                    }
                  >
                    <img
                      src="../../../assets/images/tshirt.png"
                      alt="T-shirt"
                      className="w-10 h-10 rounded bg-white p-1"
                    />
                  </div>
                )}
              </div>
            </div>
            {isPenalty || isMSCChecked ? null : (
              <div className=" p-4 text-center">
                <label
                  htmlFor="assistPlayer"
                  className="block text-sm font-myteam font-medium text-gray-700 mb-1"
                >
                  {t("ASSIST_PLAYER")}
                </label>
                <div className="space-y-2">
                  {assistPlayer ? (
                    <>
                      <Avatar
                        key={assistPlayer.id}
                        alt={assistPlayer.fullName}
                        src={assistPlayer.image}
                        className="mx-auto cursor-pointer"
                        onClick={() => handlePlayerClick(assistPlayer)}
                      />
                      <p>{assistPlayer.fullName}</p>
                    </>
                  ) : (
                    <div
                      className="flex font-myteam justify-center"
                      onClick={() =>
                        isMobile ? setOpenModalSelectPlayerAssist(true) : null
                      }
                    >
                      <img
                        src="../../../assets/images/tshirt.png"
                        alt="T-shirt"
                        className="w-10 h-10 rounded bg-white p-1"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <div>
          <label
            htmlFor="goalMinute"
            className="block text-sm font-myteam font-medium text-gray-700 mb-1"
          >
            {t("GOAL_MINUTE")}
          </label>

          <div className="flex items-center">
            <div className="flex w-max font-myteam flex-wrap items-center rounded-lg">
              <div className="p-2">
                <input
                  type="number"
                  value={goalTime.RegularTime}
                  min={0}
                  style={{
                    backgroundColor: "#fafafa",
                  }}
                  max={50}
                  onChange={(e) => handleChange("RegularTime", e.target.value)}
                  className="mt-1 text-center block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <label>+</label>
              <div className="p-2 font-myteam">
                <input
                  type="number"
                  disabled={isExtraTimeDisabled}
                  value={goalTime.extraTime}
                  min={0}
                  style={{
                    backgroundColor: "#fafafa",
                  }}
                  max={50}
                  onChange={(e) => handleChange("extraTime", e.target.value)}
                  className="mt-1 text-center block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-evenly m-4">
        {/* <div>
          <label
            htmlFor="goalMinute"
            className="text-sm px-2 font-myteam font-bold text-black"
          >
            {t("But pour l'adversaire")}
          </label>
          <input
            type="checkbox"
            name="temps"
            id="goalMinute"
            style={{
              backgroundColor: "white",
              fontSize: 18,
            }}
            className="text-center w-full border border-gray-300 rounded-md bg-white shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            checked={adversaireGoal}
            onChange={(e) => setAdversaireGoal(e.target.checked)}
          />
        </div> */}
        <div>
          <label
            htmlFor="goalMinute"
            className="text-sm px-2 font-myteam font-bold text-black"
          >
            {t("MSC")}
          </label>
          <input
            type="checkbox"
            name="temps"
            id="prolongations"
            style={{
              backgroundColor: "white",
              fontSize: 18,
            }}
            className="text-center w-full border border-gray-300 rounded-md bg-white shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            checked={isMSCChecked}
            onChange={(e) => setIsMSCChecked(e.target.checked)}
          />
        </div>
        <div>
          <label
            htmlFor="goalMinute"
            className="text-sm px-2 font-myteam font-bold text-black"
          >
            {t("PENALTY")}
          </label>
          <input
            type="checkbox"
            name="temps"
            id="prolongations"
            style={{
              backgroundColor: "white",
              fontSize: 18,
            }}
            className="text-center w-full border border-gray-300 rounded-md bg-white shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            checked={isPenalty}
            onChange={(e) => setIsPenalty(e.target.checked)}
          />
        </div>
      </div>
      <button
        onClick={handleValidateGoal}
        disabled={!goalScorer}
        className="w-1/2 px-4 disabled:bg-blue-300 py-2 font-myteam bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none mb-4"
      >
        {t("VALIDATE_GOAL")}
      </button>
    </div>
  );
};

export default GoalsEvent;

import React, { useState, useRef, Fragment, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Dialog, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import CropIcon from '@mui/icons-material/Crop';

const FileUpload = ({ title, onFileSelect, defaultImage, uploadType }) => {
    const { t } = useTranslation();
    const [files, setFiles] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [originalImage, setOriginalImage] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [crop, setCrop] = useState({ aspect: 1 });
    const [completedCrop, setCompletedCrop] = useState(null);
    const imageRef = useRef(null);
    const maxNumber = 1;
    const maxMbFileSize = 5;

    const coloredToast = (color, message) => {
        const toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            showCloseButton: true,
            customClass: {
                popup: `color-${color}`,
            },
        });
        toast.fire({
            title: message,
        });
    };

    const onChange = (fileList) => {
        const resizedFileList = fileList.map(file => {
            if (file.file.size > maxMbFileSize * 1024 * 1024) {
                coloredToast('danger', t("FILE_SIZE_TOO_LARGE",{maxMbFileSize}));
                return null;
            }
            return file;
        }).filter(item => !!item);

        if (resizedFileList.length > 0) {
            const reader = new FileReader();
            reader.onload = () => {
                if (resizedFileList[0].file?.type != 'application/pdf') {
                    setModalOpen(true);
                }
                if (resizedFileList[0].file?.type == 'application/pdf') {
                    setFiles(resizedFileList[0].file.name);
                    onFileSelect(resizedFileList[0].file);
                }
                setOriginalImage(reader.result);
            };
            reader.readAsDataURL(resizedFileList[0].file);
        }
    };

    const getCroppedImg = () => {
        if (!imageRef.current) {
            return;
        }
    
        // Set default crop dimensions to the full image size
        const crop = completedCrop || {
            x: 0,
            y: 0,
            width: imageRef.current.naturalWidth,
            height: imageRef.current.naturalHeight
        };
    
        const canvas = document.createElement('canvas');
        const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
        const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
        const ctx = canvas.getContext('2d');
    
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
    
        ctx.drawImage(
            imageRef.current,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
    
        canvas.toBlob(blob => {
            const croppedImageUrl = URL.createObjectURL(blob);
            onFileSelect(new File([blob], 'croppedImage.png', { type: 'image/png' }));
            setCroppedImage(croppedImageUrl);
            setModalOpen(false);
        }, 'image/png');
    };

    
    // const getCroppedImg = () => {
    //     if (!completedCrop || !imageRef.current) {
    //         return;
    //     }

    //     const canvas = document.createElement('canvas');
    //     const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
    //     const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
    //     const pixelRatio = window.devicePixelRatio;
    //     canvas.width = completedCrop.width * pixelRatio;
    //     canvas.height = completedCrop.height * pixelRatio;
    //     const ctx = canvas.getContext('2d');

    //     ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    //     ctx.imageSmoothingQuality = 'high';

    //     ctx.drawImage(
    //         imageRef.current,
    //         completedCrop.x * scaleX,
    //         completedCrop.y * scaleY,
    //         completedCrop.width * scaleX,
    //         completedCrop.height * scaleY,
    //         0,
    //         0,
    //         completedCrop.width,
    //         completedCrop.height
    //     );

    //     canvas.toBlob(blob => {
    //         const croppedImageUrl = URL.createObjectURL(blob);
    //         onFileSelect(new File([blob], 'croppedImage.png', { type: 'image/png' }));
    //         setCroppedImage(croppedImageUrl);
    //         setModalOpen(false);
    //     }, 'image/png');
    // };

    const handleClearStates = () => {
        setFiles("");
        setOriginalImage(null);
        setCroppedImage(null);
        setCompletedCrop(null);
    };

    const acceptFileType = uploadType === 'image' ? 'image/*' : 'image/*,.pdf';

    return (
        <div style={{ width: '100%' }}>
            <div className="flex flex-wrap justify-between items-center mb-4">
                <label className="text-lg font-bold">{title}</label>
            </div>
            <div>
                {files != "" ? <div className="relative h-56">
                    <button
                        type="button"
                        className="absolute top-0 right-0 bg-red-500 px-2 text-white font-bold w-content rounded flex items-center justify-center hover:bg-red-800 transition duration-300"
                        onClick={() => handleClearStates()}
                        title="Clear"
                    >
                        X
                    </button>
                    <div className={"flex justify-center h-56 border-2 border-dashed border-gray-300 rounded-lg items-center"} >{files}</div>
                </div> : croppedImage ? (
                    <div className="relative">
                        <button
                            type="button"
                            className="absolute top-0 right-0 bg-red-500 px-2 text-white font-bold w-content rounded flex items-center justify-center hover:bg-red-800 transition duration-300"
                            onClick={() => handleClearStates()}
                            title="Clear"
                        >
                            X
                        </button>
                        <img
                            src={croppedImage}
                            alt="Cropped"
                            className="max-w-full h-56 rounded-lg object-cover"
                        />
                        <button
                            type="button"
                            className="absolute bottom-0 left-0 bg-blue-500 py-2 px-4 text-white font-bold w-content rounded flex items-center justify-center hover:bg-blue-800 transition duration-300"
                            onClick={() => setModalOpen(true)}
                            title="Crop Image"
                        >
                            {<CropIcon />}
                        </button>
                    </div>
                ) : (
                    <div
                        className="flex justify-center h-56 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-gray-400"
                    >
                        <div style={uploadButtonStyles}>

                            <button style={buttonStyles} className='flex justify-center h-full items-center'>
                                <img
                                    src={defaultImage||"../assets/images/file-preview.png"}
                                    className={`${defaultImage ? "opacity-100" : "opacity-25"} h-36 w-36`}
                                    alt=""
                                />
                            </button>
                            <input type="file" accept={acceptFileType}
                                name="myfile" style={fileInputStyles} onChange={(e) => onChange(Array.from(e.target.files).map(file => ({ file })))} />
                        </div>
                    </div>
                )}
            </div>

            <Transition appear show={modalOpen} as={Fragment}>
                <Dialog as="div" open={modalOpen} onClose={() => setModalOpen(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div id="fadein_modal" className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Dialog.Panel className="panel border-0 p-0 rounded-lg overflow-hidden w-full max-w-lg my-8 text-black dark:text-white-dark animate__animated animate__fadeIn">
                                <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                    <h5 className="font-bold text-lg">{t("CROP_IMAGE")}</h5>
                                    <button onClick={() => setModalOpen(false)} type="button" className="text-white-dark hover:text-dark">
                                        X
                                    </button>
                                </div>
                                <div className="p-5">
                                    {originalImage && (
                                        <ReactCrop
                                            src={originalImage}
                                            crop={crop}
                                            onChange={newCrop => setCrop(newCrop)}
                                            onComplete={c => setCompletedCrop(c)}
                                            className='rounded-lg'
                                        >
                                            <img
                                                ref={imageRef}
                                                src={originalImage}
                                                alt="Cropped"
                                                style={{ maxWidth: '100%' }}
                                            />
                                        </ReactCrop>
                                    )}
                                    <div className="flex justify-between items-center mt-8">
                                        <button onClick={() => setModalOpen(false)} type="button" className="btn btn-outline-danger">
                                            {t("DISCARD")}
                                        </button>
                                        <button onClick={getCroppedImg} type="button" className="btn btn-primary ltr:ml-4 rtl:mr-4">
                                            {t("SAVE")}
                                        </button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};

export default FileUpload;

const uploadButtonStyles = {
    position: 'relative',
    overflow: 'hidden',
    display: 'inline-block',
};

const buttonStyles = {
    color: 'gray',
    backgroundColor: 'white',
    padding: '8px 20px',
    fontSize: '20px',
    fontWeight: 'bold',
};

const fileInputStyles = {
    fontSize: '100px',
    position: 'absolute',
    left: '0',
    top: '0',
    opacity: '0',
};

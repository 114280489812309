import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import { formatDate } from '../utils/Utils';
import { useTranslation } from 'react-i18next';
import sortBy from 'lodash/sortBy';
import MultiColumn from '../components/DataTables/MultiColumn';
import ModalFormRegistration from '../components/Modal/ModalFormRegistration';
import { deleteRegistrationsClub, getRegistrationsClub } from '../service/registrationService';
import EditNoteIcon from '@mui/icons-material/EditNote';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { PersonOff } from '@mui/icons-material';
import { MobileContext } from '../context/isMobileContext';

export default function RegistrationPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [originalRecords, setOriginalRecords] = useState([]);
    const [filteredRecords, setFilteredRecords] = useState([]);
    const [search, setSearch] = useState('');
    const [openModalCreateRegistration, setOpenModalCreateRegistration] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [loadData, setLoadData] = useState(false);
    const isMobile = useContext(MobileContext)

    const columns = [
        {
            accessor: 'firstName',
            title: t('USER'),
            sortable: false,
            render: ({ firstName, lastName, picture }) => (
                <div className="flex items-center w-max">
                    <img className="w-9 h-9 rounded-full object-cover ltr:mr-2 rtl:ml-2 object-cover" src={picture ? picture : "../../../assets/images/profil-default.jpg"} alt={lastName} />
                    <div>{firstName + ' ' + lastName}</div>
                </div>
            ),
        },
        {
            accessor: 'birthday',
            title: t('BIRTHDAY'),
            sortable: false,
            render: ({ birthday }) => <div>{formatDate(birthday)}</div>,
        },
        { accessor: 'categoryDivisions', title: t("CATEGORY") },
        {
            accessor: 'status',
            title: t('STATUS'),
            sortable: false,
            render: ({ status }) => (
                <div className="flex items-center justify-center text-center w-max">
                    {status == "en cours" ? <HourglassTopIcon className={`w-8 h-8 text-orange-400`} /> :
                        status == "brouillon" ? <EditNoteIcon className={`w-10 h-10 text-blue-600`} /> : <PersonOff className={`w-10 h-10 text-red-600`} />}
                </div>
            ),
        },
        {
            accessor: 'createdAt',
            title: t('CREATED_AT'),
            sortable: false,
            render: ({ createdAt }) => (
                <div className="flex items-center justify-center text-center w-max">
                    {formatDate(createdAt, 'dateTime')}
                </div>
            ),
        },
        {
            accessor: 'action',
            title: t('ACTION'),
            render: ({ user }) => (

                <div className="flex items-center space-x-3 justify-center">
                    {(user?.status == "brouillon" || user?.status == "refus") && <button
                        className="group relative p-2 bg-white border border-green-200 rounded-lg shadow-sm transition-all duration-300 ease-in-out hover:shadow-md hover:border-green-300 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50"
                        aria-label="Accept"
                        onClick={() => handleEdit(user)}
                    >
                        <BorderColorIcon className="w-5 h-5 text-green-500 group-hover:text-green-600 transition-colors duration-300" />
                        <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            {t("UPDATE")}
                        </span>
                    </button>}
                    <button
                        className="group relative p-2 bg-white border border-blue-200 rounded-lg shadow-sm transition-all duration-300 ease-in-out hover:shadow-md hover:border-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
                        onClick={() => navigate("details", { state: { playerData: user } })}
                        aria-label="View details"
                    >
                        <VisibilityIcon className="w-5 h-5 text-blue-500 group-hover:text-blue-600 transition-colors duration-300" />
                        <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            {t("VIEW")}
                        </span>
                    </button>
                    {user?.status == "brouillon" &&
                        <button
                            className="group relative p-2 bg-white border border-red-200 rounded-lg shadow-sm transition-all duration-300 ease-in-out hover:shadow-md hover:border-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
                            onClick={() => showAlert(user?.id)}
                            aria-label="View details"
                        >
                            <DeleteIcon className="w-5 h-5 text-red-500 group-hover:text-red-600 transition-colors duration-300" />
                            <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                {t("DELETE")}
                            </span>
                        </button>}
                </div>

                // <div className='flex justify-evenly items-center'>
                //     {
                //         (user?.status == "brouillon" || user?.status == "refus") && (
                //             <button className="flex items-center w-max text-green-500 hover:text-green-700" onClick={() => handleEdit(user)}>
                //                 <BorderColorIcon className="w-5 h-5 mr-1" />
                //             </button>
                //         )
                //     }
                //     <button className="flex items-center w-max text-blue-500 hover:text-blue-700" onClick={() => navigate("details", { state: { playerData: user } })}>
                //         <VisibilityIcon className="w-5 h-5 mr-1" />
                //     </button>
                //     {
                //         user?.status == "brouillon" && (
                //             <button className="flex items-center w-max text-red-500 hover:text-red-700" onClick={() => showAlert(user?.id)}>
                //                 <DeleteIcon className="w-5 h-5 mr-1" />
                //             </button>
                //         )
                //     }
                // </div>
            ),
        },
    ];
    
    const col = ['firstName', 'birthday', 'categoryDivisions', 'team', 'status', 'createdAt'];
    const header = [t('USER'), t('BIRTHDAY'), t('CATEGORY_DIVISIONS'), t('TEAM'), t('STATUS'), t('CREATED_AT')];

    useEffect(() => {
        async function fetchRegistrations() {
            setLoadData(false)
            try {
                const registrations = await getRegistrationsClub();
                if (Array.isArray(registrations?.data?.result)) {
                    const formattedData = registrations?.data?.result.map(reg => ({
                        id: reg.id,
                        firstName: reg.prenom,
                        lastName: reg.nom,
                        email: reg.email,
                        categoryDivisions: reg.categoryDivisions,
                        birthday: reg.date_naissance,
                        picture: reg.photoProfil || "../../../../../assets/images/profil-default.jpg",
                        status: reg.status,
                        action: reg.id,
                        user: reg,
                        createdAt: reg.createdAt
                    }));
                    const sortedData = sortBy(formattedData, 'createdAt').reverse();
                    setOriginalRecords(sortedData);
                    setFilteredRecords(sortedData);
                } else {
                    console.error("Unexpected data format:", registrations);
                }
            } catch (error) {
                console.error("Error fetching registrations:", error);
            }
        }
        fetchRegistrations();
    }, [loadData]);

    useEffect(() => {
        const normalizedSearch = search.trim().toLowerCase();
        setFilteredRecords(() => {
            return originalRecords.filter((item) => {
                const normalizedFirstName = item?.firstName?.trim().toLowerCase();
                const normalizedLastName = item?.lastName?.trim().toLowerCase();
                const normalizedEmail = item?.email?.trim().toLowerCase();
                const searchTerms = normalizedSearch.split(' ');
                return searchTerms.every(term =>
                    normalizedFirstName?.includes(term) ||
                    normalizedLastName?.includes(term) ||
                    normalizedEmail?.includes(term)
                );
            });
        });
    }, [search, originalRecords]);

    const showAlert = async (recordId) => {
        Swal.fire({
            icon: 'warning',
            title: t('ARE_YOU_SURE'),
            text: t("YOU_WONT_BE_ABLE_TO_REVERT_THIS"),
            showCancelButton: true,
            confirmButtonText: t('DELETE'),
            cancelButtonText: t('CANCEL'),
            padding: '2em',
            customClass: 'sweet-alerts',
        }).then(async (result) => {
            if (result.value) {
                try {
                    await deleteRegistrationsClub(recordId);
                    setOriginalRecords(prevRecords => prevRecords.filter(record => record.id !== recordId));
                    setFilteredRecords(prevRecords => prevRecords.filter(record => record.id !== recordId));
                    Swal.fire({
                        title: t('DELETED'),
                        text: t('YOUR_FILE_HAS_BEEN_DELETED'),
                        icon: 'success',
                        customClass: 'sweet-alerts',
                    });
                } catch (error) {
                    console.error("Error deleting registration:", error);
                }
            }
        });
    };

    const handleEdit = (user) => {
        setSelectedUser(user);
        setOpenModalCreateRegistration(true);
    };

    useEffect(() => {
        if (!openModalCreateRegistration) {
            setSelectedUser(null);
            setLoadData(true)
        }
    }, [openModalCreateRegistration, setOpenModalCreateRegistration])

    return (
        <>
            <div className='flex justify-end'>
                <button className='btn btn-primary text-end' onClick={() => setOpenModalCreateRegistration(true)}>{t('CREATE_NEW_REGISTRATION')}</button>
            </div>
            <div className={`bg-white shadow-md rounded-lg ${isMobile ? "p-1 mt-2" : "p-6 mt-4"}`}>
                <MultiColumn
                    title={t('LIST_REGISRATION')}
                    search={search}
                    setSearch={setSearch}
                    setInitialRecords={setFilteredRecords}
                    initialRecords={filteredRecords}
                    rowData={filteredRecords}
                    columns={columns}
                    enableExport={false}
                    col={col}
                    header={header} />
            </div>

            {
                openModalCreateRegistration ? <ModalFormRegistration
                    modal20={openModalCreateRegistration}
                    setModal20={setOpenModalCreateRegistration}
                    defaultValues={selectedUser}
                    setDefaultValues={setSelectedUser}
                /> : null
            }

        </>
    )
}

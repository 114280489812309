// const languages = [
//   { code: "en", name: "English", flag: "../../assets/images/flags/en.png" },
//   { code: "fr", name: "Français", flag: "../../assets/images/flags/fr.png" },
//   { code: "ar", name: "Arabic", flag: "../../assets/images/flags/ar.png" },
//   { code: "pt", name: "Português", flag: "../../assets/images/flags/pt.png" },
// ];
// export default languages;

import translationEN from "../locales/en/translation.json";
import translationFR from "../locales/fr/translation.json";
import translationAR from "../locales/ar/translation.json";
import translationPT from "../locales/pt/translation.json";

const languages = [
  {
    code: "en",
    name: "English",
    flag: "../../assets/images/flags/en.png",
    translation: translationEN,
  },
  {
    code: "fr",
    name: "Français",
    flag: "../../assets/images/flags/fr.png",
    translation: translationFR,
  },
  // { code: "ar", name: "Arabic", flag: "../../assets/images/flags/ar.png", translation: translationAR },
  {
    code: "pt",
    name: "Portuguese",
    flag: "../../assets/images/flags/pt.png",
    translation: translationPT,
  },
];

export default languages;

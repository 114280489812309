import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import { countriesData, sexeData } from '../../../utils/selectesData';
import {
  createRegistrationClub
} from '../../../service/registrationService';
import { useSnackbar } from 'notistack';


export default function Detailspersonnels({ setStep, step, defaultValues, registrationId, setRegistrationId, isForUpdate, setFilteredRecords, setOriginalRecords }) {
  const { t } = useTranslation();

  const savedState = JSON.parse(localStorage.getItem('detailsState')) || {};
  const [firstName, setFirstName] = useState(savedState.firstName || defaultValues?.prenom || '');
  const [lastName, setLastName] = useState(savedState.lastName || defaultValues?.nom || '');
  const [dateOfBirth, setDateOfBirth] = useState(savedState.dateOfBirth || defaultValues?.date_naissance || defaultValues?.licence?.date_naissance || null);
  const [sex, setSex] = useState(savedState.sex?.value || defaultValues?.sexe || defaultValues?.licence?.sexe || "HOMME");
  const [primaryNationality, setPrimaryNationality] = useState(savedState.primaryNationality?.value || defaultValues?.nationPrimary || defaultValues?.licence?.nationPrimary || "Seychelles");
  const [secondaryNationality, setSecondaryNationality] = useState(savedState.secondaryNationality?.value || defaultValues?.nationSecondary || defaultValues?.licence?.nationSecondary || "");
  const [birthCountry, setBirthCountry] = useState(savedState.birthCountry || defaultValues?.birthNation?.value || defaultValues?.licence?.birthNation || "Seychelles");
  const [birthRegion, setBirthRegion] = useState(savedState.birthRegion || defaultValues?.region || defaultValues?.licence?.region || '');
  const [city, setCity] = useState(savedState.city || defaultValues?.villeOfBirth || defaultValues?.licence?.villeOfBirth || '');
  const [cin, setCin] = useState(savedState.cin || defaultValues?.CIN_tuteur_legal || defaultValues?.licence?.CIN_tuteur_legal || '');

  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [dateOfBirthError, setDateOfBirthError] = useState('');
  const [sexError, setSexError] = useState('');
  const [primaryNationalityError, setPrimaryNationalityError] = useState('');
  const [secondaryNationalityError, setSecondaryNationalityError] = useState('');
  const [birthCountryError, setBirthCountryError] = useState('');
  const [birthRegionError, setBirthRegionError] = useState('');
  const [cityError, setCityError] = useState('');
  const [cinError, setCinError] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const handleClearError = () => {
    setFirstNameError('');
    setLastNameError('');
    setDateOfBirthError('');
    setSexError('');
    setPrimaryNationalityError('');
    setSecondaryNationalityError('');
    setBirthCountryError('');
    setBirthRegionError('');
    setCityError('');
    setCinError('');
  }
  useEffect(() => {
    const detailsState = {
      firstName,
      lastName,
      dateOfBirth,
      sex,
      primaryNationality,
      secondaryNationality,
      birthCountry,
      birthRegion,
      city,
      cin,
      step,
    };
    localStorage.setItem('detailsState', JSON.stringify(detailsState));
  }, [firstName, lastName, dateOfBirth, sex, primaryNationality, secondaryNationality, birthCountry, birthRegion, city, cin, step]);


  const handleSubmit = async () => {
    handleClearError();
    let formIsValid = true;

    if (!firstName || firstName == '') {
      setFirstNameError(t('MESSAGES_ERROR.FIRST_NAME_REQUIRED'));
      formIsValid = false;
    }

    if (!lastName || lastName == '') {
      setLastNameError(t('MESSAGES_ERROR.LAST_NAME_REQUIRED'));
      formIsValid = false;
    }

    if (!dateOfBirth || dateOfBirth == '') {
      setDateOfBirthError(t('MESSAGES_ERROR.DATE_OF_BIRTH_REQUIRED'));
      formIsValid = false;
    }

    if (!sex || sex == '') {
      setSexError(t('MESSAGES_ERROR.SEX_REQUIRED'));
      formIsValid = false;
    }

    if (!primaryNationality || primaryNationality == '') {
      setPrimaryNationalityError(t('MESSAGES_ERROR.PRIMARY_NATIONALITY_REQUIRED'));
      formIsValid = false;
    }

    if (!birthRegion || birthRegion == '') {
      setBirthRegionError(t('MESSAGES_ERROR.BIRTH_REGION_REQUIRED'));
      formIsValid = false;
    }

    if (!birthCountry || birthCountry == '') {
      setBirthCountryError(t('MESSAGES_ERROR.BIRTH_COUNTRY_REQUIRED'));
      formIsValid = false;
    }

    if (!city || city == '') {
      setCityError(t('MESSAGES_ERROR.CITY_REQUIRED'));
      formIsValid = false;
    }

    if (!cin || cin == '') {
      setCinError(t('MESSAGES_ERROR.CIN_REQUIRED'));
      formIsValid = false;
    }

    if (!formIsValid) {
      return;
    }

    try {
      const regID = await createRegistrationClub({
        firstName,
        lastName,
        birthYear: dateOfBirth,
        sex: t(sex?.value) || sex,
        primaryNationality: t(primaryNationality?.value) || primaryNationality,
        secondaryNationality: t(secondaryNationality?.value) || secondaryNationality,
        birthCountry: t(birthCountry?.value) || birthCountry,
        birthRegion,
        birthcity: city,
        cin
      }, step, registrationId || defaultValues?.id);
      if (regID?.data?.id && !isForUpdate) {
        setRegistrationId(regID?.data?.id);
        setStep(step + 1);
      }
      if (isForUpdate) {
        setStep(step + 1);
        enqueueSnackbar(t('DETAILS_PERSONNELS_UPDATED_SUCCESS'), { variant: 'success' });
      }

    } catch (error) {
      console.error('Error creating registration:', error);
    }
  };

  return (
    <form noValidate className="space-y-5 mx-4 my-4">
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
        <div>
          <label htmlFor="firstName">{t("FIRST_NAME")} <span className='text-red-500'>*</span> </label>
          <input
            id="firstName"
            type="text"
            placeholder={t("FIRST_NAME")}
            value={firstName}
            onChange={(e) => { setFirstName(e.target.value); handleClearError() }}
            className={`form-input ${firstNameError ? 'border-red-500' : ''}`}
          />
          {firstNameError && <p className="text-red-500">{firstNameError}</p>}
        </div>
        <div>
          <label htmlFor="lastName">{t('LAST_NAME')} <span className='text-red-500'>*</span></label>
          <input
            id="lastName"
            type="text"
            placeholder={t('LAST_NAME')}
            value={lastName}
            onChange={(e) => { setLastName(e.target.value); handleClearError() }}
            className={`form-input ${lastNameError ? 'border-red-500' : ''}`}
          />
          {lastNameError && <p className="text-red-500">{lastNameError}</p>}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
        <div>
          <label htmlFor="birthYear">{t('BIRTHDAY')} <span className='text-red-500'>*</span></label>
          <Flatpickr
            value={dateOfBirth}
            placeholder={t('BIRTHDAY')}
            options={{ dateFormat: 'Y-m-d' }}
            className={`form-input ${dateOfBirthError ? 'border-red-500' : ''}`}
            onChange={(dates) => { setDateOfBirth(dates[0]); handleClearError() }}
          />
          {dateOfBirthError && <p className="text-red-500">{dateOfBirthError}</p>}
        </div>
        <div>
          <label htmlFor="sex">{t("SEXE")} <span className='text-red-500'>*</span></label>
          <Select
            placeholder={t('SEXE')}
            options={sexeData}
            getOptionLabel={(option) => t(option.label)}
            defaultInputValue={sex}
            components={{ Option: ({ data, innerRef, innerProps }) => <div ref={innerRef} {...innerProps} className="flex items-center p-2 cursor-pointer hover:bg-blue-200"><span>{t(data.label)}</span></div> }}
            onChange={(selectedOption) => { setSex(selectedOption); handleClearError() }}
            className={`${sexError ? 'border-red-500' : ''}`}
          />
          {sexError && <p className="text-red-500">{sexError}</p>}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
        <div>
          <label htmlFor="primaryNationality">{t('FIRST_NATIONALITE')} <span className='text-red-500'>*</span></label>
          <Select
            placeholder={t('FIRST_NATIONALITE')}
            options={countriesData}
            getOptionLabel={(option) => t(option.label)}
            defaultInputValue={primaryNationality}
            components={{ Option: ({ data, innerRef, innerProps }) => <div ref={innerRef} {...innerProps} className="flex items-center p-2 cursor-pointer hover:bg-blue-200"><span>{t(data.label)}</span></div> }}
            onChange={(selectedOption) => { setPrimaryNationality(selectedOption); handleClearError() }}
            className={`${primaryNationalityError ? 'border-red-500' : ''}`}
          />
          {primaryNationalityError && <p className="text-red-500">{primaryNationalityError}</p>}
        </div>
        <div>
          <label htmlFor="secondaryNationality">{t('SECOND_NATIONALITE')}</label>
          <Select
            placeholder={t('SECOND_NATIONALITE')}
            options={countriesData}
            defaultInputValue={secondaryNationality}
            getOptionLabel={(option) => t(option.label)}
            components={{ Option: ({ data, innerRef, innerProps }) => <div ref={innerRef} {...innerProps} className="flex items-center p-2 cursor-pointer hover:bg-blue-200"><span>{t(data.label)}</span></div> }}
            onChange={(selectedOption) => { setSecondaryNationality(selectedOption); handleClearError() }}
          />
          {secondaryNationalityError && <p className="text-red-500">{secondaryNationalityError}</p>}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
        <div>
          <label htmlFor="birthCountry">{t('PAYS_NAISSANCE')} <span className='text-red-500'>*</span></label>
          <Select
            placeholder={t('PAYS_NAISSANCE')}
            options={countriesData}
            defaultInputValue={birthCountry}
            getOptionLabel={(option) => t(option.label)}
            components={{ Option: ({ data, innerRef, innerProps }) => <div ref={innerRef} {...innerProps} className="flex items-center p-2 cursor-pointer hover:bg-blue-200"><span>{t(data.label)}</span></div> }}
            onChange={(selectedOption) => { setBirthCountry(selectedOption); handleClearError() }}
            className={`${birthCountryError ? 'border-red-500' : ''}`}
          />
          {birthCountryError && <p className="text-red-500">{birthCountryError}</p>}
        </div>
        <div>
          <label htmlFor="birthRegion">{t("REGION_NAISSANCE")} <span className='text-red-500'>*</span></label>
          <input
            id="region"
            type="text"
            placeholder={t("REGION_NAISSANCE")}
            defaultValue={birthRegion}
            onChange={(e) => { setBirthRegion(e.target.value); handleClearError() }}
            className={`form-input ${birthRegionError ? 'border-red-500' : ''}`}
          />
          {birthRegionError && <p className="text-red-500">{birthRegionError}</p>}
        </div>
      </div>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
        <div>
          <label htmlFor="city">{t("CITY")} <span className='text-red-500'>*</span></label>
          <input
            id="city"
            type="text"
            placeholder={t("CITY")}
            value={city}
            onChange={(e) => { setCity(e.target.value); handleClearError() }}
            className={`form-input ${cityError ? 'border-red-500' : ''}`}
          />
          {cityError && <p className="text-red-500">{cityError}</p>}
        </div>
        <div>
          <label htmlFor="cin">{t('CIN')} <span className='text-red-500'>*</span></label>
          <input
            id="cin"
            type="text"
            placeholder={t('CIN')}
            value={cin}
            onChange={(e) => { setCin(e.target.value); handleClearError() }}
            className={`form-input ${cinError ? 'border-red-500' : ''}`}
          />
          {cinError && <p className="text-red-500">{cinError}</p>}
        </div>
      </div>
      <div className="flex justify-between">
        <button
          type="button"
          className={`btn ${isForUpdate ? "bg-green-500 text-white" : "btn-primary"} ${step === 1 ? 'hidden' : ''}`}
          onClick={() =>
            setStep(step === 1 ? 1 : step - 1)
          }
        >
          {t("BACK")}
        </button>
        <button
          type="button"
          className={`btn ${isForUpdate ? "bg-green-500 text-white" : "btn-primary"} ltr:ml-auto rtl:mr-auto`}
          onClick={() => {
            handleSubmit()
          }}
        >
          {step === 5 ? t("FINISH") : t("NEXT")}
        </button>
      </div>
    </form>
  );
}

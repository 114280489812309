import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SignatureCanvas from "react-signature-canvas";
import LoginModal from "../../modals/LoginModal";
import { loginSign, logoutSign } from "../../../api/services/authServices";
import axiosInstance from "../../../api/axiosConfig";
import { matchSignateurThunk } from "../../../redux/middleware/matchThunks";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { enqueueSnackbar } from "notistack";

const Signature = ({
  isArbiture,
  isSigned,
  role,
  teamId,
  matchId,
  handleSignatureUpdate,
  type,
  isForConfirmation = false,
}) => {
  const { t } = useTranslation();
  const sigCanvas = useRef({});
  const dispatch = useDispatch({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [teamConnected, setTeamConnected] = useState(null);

  const clearSignature = () => {
    sigCanvas.current.clear();
    handleSignatureUpdate(role, false);
  };
  useEffect(() => {
    const token = localStorage.getItem("myteamConnect_TeamSignedToken");
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken?.team?.id !== teamId) {
        logoutSign();
      }
    }
  }, []);

  const saveSignature = async () => {
    const isSign = sigCanvas.current?.toData();
    if (isSign?.length > 0) {
      const sign = sigCanvas.current.toDataURL();
      const signatureData = {
        match: matchId,
        ...(isArbiture ? {} : { equipe: teamConnected.equipe_id }),
        ...(isForConfirmation ? { equipe: isArbiture.equipe_id } : {}),
        signed: !isArbiture ? teamConnected.id : isArbiture?.id,
        type: type,
        sign: sign,
      };
      try {
        const signed = await dispatch(matchSignateurThunk(signatureData));
        if (signed) {
          handleSignatureUpdate(role, true);
          await logoutSign();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleLogin = async (email, password) => {
    await logoutSign();
    try {
      const connectedTeam = await loginSign(email, password);
      if (connectedTeam.equipe_id === teamId) {
        setTeamConnected(connectedTeam);
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
        enqueueSnackbar(t("MESSAGES_ERROR.NOT_YOUR_SECTION"), {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Login failed:", error.message);
      throw new Error(error.message);
    }
  };

  return (
    <div className="flex flex-col items-center p-4">
      <div className="bg-white">
        {(isLoggedIn && teamConnected) || isArbiture ? (
          <SignatureCanvas
            penColor="black"
            canvasProps={{
              width: 300,
              height: 200,
              className: "border-2 border-black",
            }}
            ref={sigCanvas}
          />
        ) : (
          <div
            style={{
              width: 300,
              height: 200,
              borderWidth: 2,
              borderColor: "black",
              borderStyle: "solid",
            }}
          ></div>
        )}
      </div>

      {(isLoggedIn && teamConnected) || isArbiture ? (
        <>
          <div className="flex items-center mt-4">
            {!isSigned && (
              <button
                className="px-4 py-2 font-myteam bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none"
                onClick={clearSignature}
              >
                {t("CLEAR")}
              </button>
            )}
            <button
              className={`px-4 py-2 ml-2 font-myteam text-white rounded focus:outline-none ${
                isSigned
                  ? "bg-gray-500 cursor-not-allowed"
                  : "bg-green-500 hover:bg-green-600"
              }`}
              onClick={saveSignature}
              disabled={isSigned}
            >
              {isSigned ? t("SIGNED") : t("SAVE")}
            </button>
          </div>
        </>
      ) : null}

      {!isLoggedIn && !isArbiture && (
        <button
          onClick={() => setLoginOpen(true)}
          className="px-4 py-2 mt-4 font-myteam bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none"
        >
          {t("LOGIN")}
        </button>
      )}
      <LoginModal
        isOpen={isLoginOpen}
        setOpen={setLoginOpen}
        handleLogin={handleLogin}
        t={t}
      />
    </div>
  );
};

export default Signature;

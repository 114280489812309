import React, { useContext, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import IconUser from '../Icon/IconUser';
import IconNotesEdit from '../Icon/IconNotesEdit';
import IconNotes from '../Icon/IconNotes';
import IconFile from '../Icon/IconFile';
import IconChecks from '../Icon/IconChecks';
import Coordonnes from '../Forms/registration/Coordonnes';
import Enregistrements from '../Forms/registration/Enregistrements';
import Documents from '../Forms/registration/Documents';
import Done from '../Forms/registration/Done';
import Detailspersonnels from '../Forms/registration/Detailspersonnels';
import { MobileContext } from '../../context/isMobileContext';


const ModalFormRegistration = ({
    setLoadData,
    modal20,
    setModal20,
    isForUpdate = false,
    defaultValues,
    setDefaultValues }) => {

    const { t } = useTranslation();
    const [activeTab4, setActiveTab4] = useState(1);
    const [registrationId, setRegistrationId] = useState(defaultValues?.licence?.id || null);
    const isMobile = useContext(MobileContext)

    const steps = [
        {
            id: 1,
            label: t('DETAILS_PERSONNELS'),
            icon: <IconUser />,
            content: <Detailspersonnels  registrationId={registrationId} setRegistrationId={setRegistrationId} defaultValues={defaultValues} setStep={setActiveTab4} step={activeTab4} isForUpdate={isForUpdate} />
        },
        {
            id: 2,
            label: t('COORDONNEES'),
            icon: <IconNotesEdit />,
            content: <Coordonnes t={t} registrationId={registrationId} setStep={setActiveTab4} step={activeTab4} defaultValues={defaultValues} isForUpdate={isForUpdate} />
        },
        {
            id: 3,
            label: t('ENREGISTREMENTS'),
            icon: <IconNotes />,
            content: <Enregistrements registrationId={registrationId} t={t} setStep={setActiveTab4} step={activeTab4} defaultValues={defaultValues} isForUpdate={isForUpdate} />
        },
        {
            id: 4,
            label: t('DOCUMENTS'),
            icon: <IconFile />,
            content: <Documents registrationId={registrationId} t={t} setStep={setActiveTab4} step={activeTab4} defaultValues={defaultValues} isForUpdate={isForUpdate} />
        },
        {
            id: 5,
            label: t('DONE'),
            icon: <IconChecks />,
            content: <Done t={t} setLoadData={setLoadData} registrationId={registrationId} setColseModal={setModal20} setStep={setActiveTab4} step={activeTab4} defaultValues={defaultValues} isForUpdate={isForUpdate} />
        },
    ];

    useEffect(() => {
        if (!modal20) {
            setDefaultValues(false);
            setActiveTab4(1)
            handleCloseModel()
        }
    }, [modal20, setModal20])

    const handleCloseModel = () => {
        setModal20(false)
        if (isForUpdate) {
            setLoadData(true);
        }
        localStorage?.removeItem("detailsState")
        localStorage?.removeItem("formStateCoordonnes")
        localStorage?.removeItem("formState")
        localStorage?.removeItem("formStateEnregistrement")
    }

    const activeStep = steps.find(step => step.id === activeTab4);
    return (
        <Transition appear show={modal20} as={Fragment}>
            <Dialog as="div" open={modal20} onClose={() => handleCloseModel()}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0" />
                </Transition.Child>
                <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-scroll">
                    <div className="flex items-start justify-center min-h-screen px-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="panel border-0 p-0 rounded-lg w-full max-w-5xl my-8 text-black dark:text-white-dark">
                                <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                    <h5 className="font-bold text-lg">
                                        {isForUpdate ? t('UPDATE_REGISTRATION') : t('CREATE_NEW_REGISTRATION')}
                                    </h5>
                                    <button
                                        onClick={() => handleCloseModel()}
                                        type="button"
                                        className="text-white-dark hover:text-dark"
                                    >
                                        X
                                    </button>
                                </div>
                                <div className={`flex justify-center text-center ${isMobile ? "p-2" : "p-10"}`}>
                                    <div className="inline-block w-full">
                                        <div className="relative z-[1]">
                                            <div
                                                className={`${activeTab4 === 1 ? 'w-[8%]' : activeTab4 === 2 ? 'w-[30%]' : activeTab4 === 3 ? 'w-[50%]' : activeTab4 === 4 ? 'w-[70%]' : activeTab4 === 5 ? 'w-[90%]' : ''} ${isForUpdate ? "bg-green-500" : "bg-primary"} w-[15%] h-1 absolute ltr:left-0 rtl:right-0 top-[30px] m-auto -z-[1] transition-[width]`}
                                            ></div>
                                            <ul className="mb-5 grid grid-cols-5">
                                                {steps.map((step) => (
                                                    <li
                                                        key={step.id}
                                                        className="mx-auto flex flex-col items-center"
                                                    >
                                                        <button
                                                            type="button"
                                                            className={`${activeTab4 === step.id
                                                                ? isForUpdate ? '!bg-green-500 text-white' : '!border-primary !bg-primary text-white'
                                                                : ''
                                                                } border-[3px] border-[#f3f2ee] bg-white dark:bg-[#253b5c] dark:border-[#1b2e4b] flex justify-center items-center w-16 h-16 rounded-full object-cover`}
                                                        >
                                                            {step.icon && (
                                                                <span className="mr-1">{step.icon}</span>
                                                            )}
                                                        </button>
                                                        {isMobile ? null : <span
                                                            className={`${activeTab4 === step.id ? isForUpdate ? "text-green-600" : 'text-primary ' : ''
                                                                } text-center block mt-2`}
                                                        >
                                                            {step.label}
                                                        </span>}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className='mb-5'>
                                            {activeStep?.content}
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default ModalFormRegistration;

export const getTeamById = (state, id) => {
  const teamsArray = Object.values(state);
  const foundTeam = teamsArray.find(team => team.id === id);
  return foundTeam || null;
};


export const loadStateFromLocalStorage = (satateNameToGet) => {
  try {
    const serializedState = localStorage.getItem(satateNameToGet);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    console.error('Could not load state', e);
    return undefined;
  }
};
export const saveStateToLocalStorage = (state,satateNameToSave) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(satateNameToSave, serializedState);
  } catch (e) {
    console.error('Could not save state', e);
  }
};

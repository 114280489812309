import React from "react";
import GlobalModal from "./GlobalModal";
import Signature from "../Forms/signature/Signature";
import { Avatar } from "@mui/material";

import SignateurAfterMatch from "../sections/SignateurAfterMatch";

export default function SignatureModal({
  isOpen,
  setOpen,
  teamRecevant,
  teamVisiteuse,
  t,
  matchId,
  isArbiture,
  signatures,
  setSignatures,
  match,
  type,
  equipeAwayScoore,
  equipeHomeScoore,
}) {
  const handleSignatureUpdate = (role, isSigned) => {
    setSignatures((prevSignatures) => ({
      ...prevSignatures,
      [role]: isSigned,
    }));
  };

  
  const signateurBeforeMatch = () => {
    return (
      <div className="bg-white w-max rounded p-5">
        <div className="flex flex-wrap items-center justify-end">
          <button
            className="px-3 flex justify-end py-1 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 focus:outline-none"
            onClick={() => setOpen(false)}
          >
            {t("CLOSE")}
          </button>
        </div>
        <h1 className="font-myteam text-center text-title font-bold m-3">
          {t("SIGNATURES_AVANT_MATCH")}
        </h1>
        <div className="flex items-center flex-wrap justify-center">
          <div>
            <div className="flex justify-center items-center">
              <Avatar
                src={teamRecevant?.teamLogo}
                className="w-10 h-10 object-cover"
              />
              <h1>{teamRecevant?.teamName}</h1>
            </div>
            <Signature
              t={t}
              role="teamRecevant"
              isSigned={signatures.teamRecevant}
              handleSignatureUpdate={handleSignatureUpdate}
              teamId={teamRecevant?.team_Id}
              matchId={matchId}
              type={type}
            />
          </div>
          <div>
            <div className="flex justify-center items-center">
              <Avatar
                src={"../../assets/images/referee.png"}
                className="w-10 h-10 object-cover"
              />
              <h1 className="font-myteam">{t("ARBITRE")}</h1>
            </div>
            <Signature
              t={t}
              role="arbiture"
              isSigned={signatures.arbiture}
              handleSignatureUpdate={handleSignatureUpdate}
              isArbiture={isArbiture}
              matchId={matchId}
              type={type}
            />
          </div>
          <div>
            <div className="flex justify-center items-center">
              <h1 className="font-myteam">{teamVisiteuse?.teamName}</h1>
              <Avatar
                src={teamVisiteuse?.teamLogo}
                className="w-10 h-10 object-cover"
              />
            </div>
            <Signature
              t={t}
              role="teamVisiteuse"
              isSigned={signatures.teamVisiteuse}
              handleSignatureUpdate={handleSignatureUpdate}
              teamId={teamVisiteuse?.team_Id}
              matchId={matchId}
              type={type}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <GlobalModal
      isOpen={isOpen}
      setOpen={setOpen}
      style={[
        {
          backgroundColor: "white",
          padding: "10px",
          height: "100%",
        },
        type != "after" && {
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
        },
      ]}
    >
      {type == "after" ? (
        <SignateurAfterMatch
          setOpen={setOpen}
          t={t}
          teamRecevant={teamRecevant}
          signatures={signatures}
          handleSignatureUpdate={handleSignatureUpdate}
          matchId={matchId}
          teamVisiteuse={teamVisiteuse}
          match={match}
          isArbiture={isArbiture}
          type={type}
          equipeAwayScoore={equipeAwayScoore}
          equipeHomeScoore={equipeHomeScoore}
        />
      ) : (
        signateurBeforeMatch()
      )}
    </GlobalModal>
  );
}

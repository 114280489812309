import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Grid,
  Divider,
  Stack,
  Box,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import EventIcon from "@mui/icons-material/Event";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useTranslation } from "react-i18next";

const MatchRefusalDisplay = ({ matchData, onClose }) => {
  const {
    equipeHome,
    equipeAway,
    match_date,
    match_time,
    location,
    commentaire,
  } = matchData;
  const { t } = useTranslation();

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <Card className="bg-red-50 border p-5 border-red-200 rounded-lg shadow-md">
      <CardContent>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          className="mb-4 cursor-pointer"
          onClick={onClose}
        >
          <CancelIcon className="text-red-500" />
          <Typography variant="h6" className="text-red-500 font-bold">
            {t("MATCH_REFUSED")}
          </Typography>
        </Stack>

        <Grid container className="my-4">
          <TeamInfo team={equipeHome} />
          <Grid item xs={2} className="flex items-center justify-center">
            <Typography variant="h6" className="text-gray-400 font-bold">
              {t("VS")}
            </Typography>
          </Grid>
          <TeamInfo team={equipeAway} />
        </Grid>

        {/* Match Date and Location */}
        <Box className="my-4">
        <InfoRow
            icon={<EventIcon className="text-gray-400" />}
            text={t("MATCH_DATE_TIME", {
              date: formatDate(match_date),
              time: match_time,
            })}
          />
          {location && (
            <InfoRow
              icon={<LocationOnIcon className="text-gray-400" />}
              text={`${t("LOCATION")}: ${location}`}
            />
          )}
        </Box>

        <Divider className="my-4" />

        <Typography
          variant="subtitle1"
          className="font-semibold text-gray-700 mb-2"
        >
          {t("REASON_FOR_REFUSAL")}:
        </Typography>
        <Typography variant="body2" className="text-gray-600 italic">
          {commentaire}
        </Typography>
      </CardContent>
    </Card>
  );
};

const TeamInfo = ({ team }) => {
  return (
    <Grid item xs={5} className="text-center">
      <Avatar
        src={team.image}
        alt={team.name}
        className="w-16 h-16 mx-auto mb-2 border border-gray-300"
      />
      <Typography variant="subtitle1" className="font-semibold text-gray-800">
        {team.name}
      </Typography>
    </Grid>
  );
};

const InfoRow = ({ icon, text }) => (
  <Stack direction="row" spacing={1} alignItems="center">
    {icon}
    <Typography variant="body2" className="text-gray-600">
      {text}
    </Typography>
  </Stack>
);

export default MatchRefusalDisplay;

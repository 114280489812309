// components/RoleBasedRoute.js
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Loading from "../components/Loadings/Loading";

const RoleBasedRoute = ({ allowedRoles, redirectPath }) => {
  const [role, setRole] = useState("");
  
  useEffect(() => {
    const token = localStorage.getItem("myteamConnectToken");
    if (token) {
      const decodedToken = jwtDecode(token);
      setRole(decodedToken?.role);
    }
  }, []);

  if (!role) {
    return <Loading />;
  }
  if (!allowedRoles.includes(role)) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default RoleBasedRoute;

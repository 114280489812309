import React, { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { jwtDecode } from "jwt-decode";

const UserRoleContext = createContext();

export const UserRoleProvider = ({ children }) => {
  //const { user } = useContext(AuthContext);
  const [role, setRole] = useState();

  //   useEffect(() => {
  //     console.log(user);
  //     if (user && user !== null) {
  //       setRole(user.role);
  //     }
  //   }, [user]);

  useEffect(() => {
    const token = localStorage.getItem("myteamConnectToken");
    if (token) {
      const decodedToken = jwtDecode(token);
      setRole(decodedToken?.role);
    }
  }, []);

  return (
    <UserRoleContext.Provider value={{ role }}>
      {children}
    </UserRoleContext.Provider>
  );
};

export const useUserRole = () => useContext(UserRoleContext);

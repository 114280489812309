import axiosInstance from "../../../api/axiosConfig";

const createRegistrationClub = async (
  _request = {},
  tab = 1,
  registrationId = null
) => {
  let url = `inscriptions-non-validees/create`;
  let request = {};
  let requestFormData = new FormData();

  switch (tab) {
    case 1:
      request = {
        firstName: _request?.firstName,
        lastName: _request?.lastName,
        dob: new Date(_request?.birthYear),
        sexe: _request?.sex,
        nationPrimary: _request?.primaryNationality,
        nationSecondary: _request?.secondaryNationality,
        birthNation: _request?.birthCountry,
        region: _request?.birthRegion,
        villeOfBirth: _request?.birthcity,
        cin: _request?.cin,
        stepper: 1,
        userId: 123,
      };
      break;
    case 2:
      request = {
        adresse1: _request?.address1,
        adresse2: _request?.address2,
        ville: _request?.city,
        etat: _request?.state,
        codePostal: _request?.postalCode,
        numTel: _request?.phoneNumber,
        email: _request?.email,
        pays: _request?.pays,
        stepper: 2,
        userId: 123,
      };
      break;
    case 3:
      request = {
        role: _request?.role,
        sport: _request?.sport,
        niveau: _request?.level,
        equipeId: +_request?.club,
        cateAge: _request?.category,
        category_age: _request?.category_age,
        category_divisions: _request?.category_divisions,
        stepper: 3,
        userId: 123,
      };
      break;
    case 4:
      request = {
        fullName: _request.fullName,
        playerTeamId: 737,
        stepper: 4,
        userId: 123,
        photoProfil: _request.FilePhotoProfile,
        photoIdentite: _request.FilePhotoIdentite,
        certificationMedical: _request.FileCertificationMedical,
        registrationId,
      };
      Object.entries(request).forEach(([key, value]) => {
        if (
          ![null, "", "null"].includes(String(value)) &&
          value instanceof File
        ) {
          requestFormData.append(key, value);
        } else {
          requestFormData.append(key, value);
        }
      });
      registrationId = null;
      request = requestFormData;
      break;
    default:
      request = {
        stepper: 0,
        userId: 123,
      };
      break;
  }

  if (registrationId) request = { registrationId, ...request };
  const config =
    registrationId || request instanceof FormData
      ? {
          headers: {
            "Content-Type": registrationId
              ? "application/json"
              : "multipart/form-data",
          },
        }
      : {};
  return axiosInstance.post(url, request, config);
};

const getPlayer = async (filter = {}, playerId) => {
  let url = `inscriptions-non-validees/read`;

  const response = await axiosInstance.post(url, filter);
  return response.data.result?.find((e) => e?.id === playerId);
};

const getRegistrationsClub = (filter = {}) => {
  let url = `inscriptions-non-validees/read`;
  return axiosInstance.post(url, filter);
};

const getRegistrationsDtn = (filter = {}) => {
  let url = `licence/read-qualification`;
  return axiosInstance.post(url, filter);
};

const deleteRegistrationsDtn = (id) => {
  let url = `inscriptions-non-validees/delete`;
  return axiosInstance.post(url, {
    registrationId: id,
    status: "brouillon",
  });
};

const deleteRegistrationsClub = (id) => {
  let url = `inscriptions-non-validees/delete`;

  return axiosInstance.post(url, {
    registrationId: id,
    status: "brouillon",
  });
};

const deleteValideLicence = (userLicenceId) => {
  let url = `licence/delete`;
  return axiosInstance.post(url,{
    licence_id:userLicenceId
  });
};

const validateRegistrationsClub = (id) => {
  let url = `licence/validate`;

  return axiosInstance.post(url, {
    registrationId: id,
  });
};
const updatePlayerImage = (formData) => {
  let url = `licence/update-player`;

  return axiosInstance.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const refusRegistrations = (id, comment) => {
  let url = `licence/refus`;
  return axiosInstance.post(url, {
    registrationId: id,
    comment,
  });
};

const getLicencesPlayer = (type = "All") => {
  let url = `licence/read`;
  return axiosInstance.post(url, { type });
};

export {
  createRegistrationClub,
  getPlayer,
  getRegistrationsClub,
  getRegistrationsDtn,
  deleteRegistrationsDtn,
  deleteRegistrationsClub,
  validateRegistrationsClub,
  refusRegistrations,
  getLicencesPlayer,
  deleteValideLicence,
  updatePlayerImage,
};

export const formation_3142 = [
  { top: "13%", left: "45%", post: "GK" }, // Goalkeeper
  { top: "30%", left: "25%", post: "CB" }, // Center Back 1
  { top: "30%", left: "45%", post: "CB" }, // Center Back 2
  { top: "30%", left: "65%", post: "CB" }, // Center Back 3
  { top: "50%", left: "45%", post: "CDM" }, // Central Midfielder
  { top: "70%", left: "10%", post: "RM" }, // Central Midfielder
  { top: "58%", left: "25%", post: "CM" }, // Central Midfielder
  { top: "58%", left: "65%", post: "CM" }, // Central Midfielder
  { top: "70%", left: "80%", post: "LM" }, // Central Midfielder
  { top: "104%", left: "30%", post: "ST" }, // Forward 1
  { top: "104%", left: "60%", post: "ST" }, // Forward 2
];

export const formation_3412 = [
  { top: "13%", left: "45%", post: "GK" }, // Goalkeeper
  { top: "30%", left: "25%", post: "CB" }, // Center Back 1
  { top: "30%", left: "45%", post: "CB" }, // Center Back 2
  { top: "30%", left: "65%", post: "CB" }, // Center Back 3
  { top: "80%", left: "45%", post: "CAM" }, // Central Midfielder
  { top: "70%", left: "10%", post: "RM" }, // Central Midfielder
  { top: "55%", left: "30%", post: "CM" }, // Central Midfielder
  { top: "55%", left: "60%", post: "CM" }, // Central Midfielder
  { top: "70%", left: "80%", post: "LM" }, // Central Midfielder
  { top: "104%", left: "30%", post: "ST" }, // Forward 1
  { top: "104%", left: "60%", post: "ST" }, // Forward 2
];

export const formation_3421 = [
  { top: "13%", left: "45%", post: "GK" }, // Goalkeeper
  { top: "30%", left: "25%", post: "CB" }, // Center Back 1
  { top: "30%", left: "45%", post: "CB" }, // Center Back 2
  { top: "30%", left: "65%", post: "CB" }, // Center Back 3
  { top: "85%", left: "25%", post: "RF" }, // CAM
  { top: "85%", left: "65%", post: "LF" }, //CAM
  { top: "70%", left: "10%", post: "RM" }, // Central Midfielder
  { top: "55%", left: "30%", post: "CM" }, // Central Midfielder
  { top: "55%", left: "60%", post: "CM" }, // Central Midfielder
  { top: "70%", left: "80%", post: "LM" }, // Central Midfielder
  { top: "104%", left: "45%", post: "ST" }, // Forward 2
];

export const formation_343 = [
  { top: "13%", left: "45%", post: "GK" }, // Goalkeeper
  { top: "30%", left: "25%", post: "CB" }, // Center Back 1
  { top: "30%", left: "45%", post: "CB" }, // Center Back 2
  { top: "30%", left: "65%", post: "CB" }, // Center Back 3
  { top: "95%", left: "25%", post: "RW" }, // CAM
  { top: "95%", left: "65%", post: "LW" }, //CAM
  { top: "70%", left: "10%", post: "RM" }, // Central Midfielder
  { top: "55%", left: "30%", post: "CM" }, // Central Midfielder
  { top: "55%", left: "60%", post: "CM" }, // Central Midfielder
  { top: "70%", left: "80%", post: "LM" }, // Central Midfielder
  { top: "104%", left: "45%", post: "ST" }, // Forward 2
];

export const formation_3511 = [
  { top: "13%", left: "45%", post: "GK" }, // Goalkeeper
  { top: "30%", left: "25%", post: "CB" }, // Center Back 1
  { top: "30%", left: "45%", post: "CB" }, // Center Back 2
  { top: "30%", left: "65%", post: "CB" }, // Center Back 3
  { top: "58%", left: "45%", post: "CM" }, // Central Midfielder
  { top: "70%", left: "10%", post: "RM" }, // Central Midfielder
  { top: "58%", left: "25%", post: "CDM" }, // Central Midfielder
  { top: "58%", left: "65%", post: "CDM" }, // Central Midfielder
  { top: "70%", left: "80%", post: "LM" }, // Central Midfielder
  { top: "85%", left: "45%", post: "CF" }, // Forward 1
  { top: "104%", left: "45%", post: "ST" }, // Forward 2
];

export const formation_352 = [
  { top: "13%", left: "45%", post: "GK" }, // Goalkeeper
  { top: "30%", left: "25%", post: "CB" }, // Center Back 1
  { top: "30%", left: "45%", post: "CB" }, // Center Back 2
  { top: "30%", left: "65%", post: "CB" }, // Center Back 3
  { top: "58%", left: "45%", post: "CM" }, // Central Midfielder
  { top: "70%", left: "10%", post: "RM" }, // Central Midfielder
  { top: "58%", left: "25%", post: "CDM" }, // Central Midfielder
  { top: "58%", left: "65%", post: "CDM" }, // Central Midfielder
  { top: "70%", left: "80%", post: "LM" }, // Central Midfielder
  { top: "104%", left: "30%", post: "ST" }, // Forward 1
  { top: "104%", left: "60%", post: "ST" }, // Forward 2
];

export const formation_41212 = [
  { top: "13%", left: "45%", post: "GK" }, // Goalkeeper
  { top: "40%", left: "10%", post: "RB" }, // Center Back 1
  { top: "30%", left: "30%", post: "CB" }, // Center Back 2
  { top: "30%", left: "60%", post: "CB" }, // Center Back 3
  { top: "40%", left: "80%", post: "LB" }, // Center Back 4
  { top: "50%", left: "45%", post: "CDM" }, // Defensive Midfielder
  { top: "70%", left: "20%", post: "RM" }, // Midfielder 1
  { top: "70%", left: "70%", post: "LM" }, // Midfielder 2
  { top: "85%", left: "45%", post: "CAM" }, // Attacking Midfielder
  { top: "100%", left: "20%", post: "ST" }, // Forward 1
  { top: "100%", left: "70%", post: "ST" }, // Forward 2
];

export const formation_4123 = [
  { top: "13%", left: "45%", post: "GK" }, // Goalkeeper
  { top: "40%", left: "10%", post: "RB" }, // Center Back 1
  { top: "30%", left: "30%", post: "CB" }, // Center Back 2
  { top: "30%", left: "60%", post: "CB" }, // Center Back 3
  { top: "40%", left: "80%", post: "LB" }, // Center Back 4
  { top: "50%", left: "45%", post: "CDM" }, // Defensive Midfielder
  { top: "70%", left: "25%", post: "CM" }, // Midfielder 1
  { top: "70%", left: "65%", post: "CM" }, // Midfielder 2
  { top: "105%", left: "45%", post: "ST" }, // Attacking Midfielder
  { top: "95%", left: "15%", post: "RW" }, // Forward 1
  { top: "95%", left: "75%", post: "LW" }, // Forward 2
];

export const formation_4132 = [
  { top: "13%", left: "45%", post: "GK" }, // Goalkeeper
  { top: "40%", left: "10%", post: "RB" }, // Center Back 1
  { top: "30%", left: "30%", post: "CB" }, // Center Back 2
  { top: "30%", left: "60%", post: "CB" }, // Center Back 3
  { top: "40%", left: "80%", post: "LB" }, // Center Back 4
  { top: "50%", left: "45%", post: "CDM" }, // Defensive Midfielder
  { top: "72%", left: "15%", post: "RM" }, // Midfielder 1
  { top: "72%", left: "75%", post: "LM" }, // Midfielder 2
  { top: "70%", left: "45%", post: "CM" }, // Attacking Midfielder
  { top: "100%", left: "30%", post: "ST" }, // Forward 1
  { top: "100%", left: "60%", post: "ST" }, // Forward 2
];

export const formation_4141 = [
  { top: "13%", left: "45%", post: "GK" }, // Goalkeeper
  { top: "40%", left: "10%", post: "RB" }, // Center Back 1
  { top: "30%", left: "30%", post: "CB" }, // Center Back 2
  { top: "30%", left: "60%", post: "CB" }, // Center Back 3
  { top: "40%", left: "80%", post: "LB" }, // Center Back 4
  { top: "50%", left: "45%", post: "CDM" }, // Defensive Midfielder
  { top: "72%", left: "13%", post: "RM" }, // Midfielder 1
  { top: "72%", left: "78%", post: "LM" }, // Midfielder 2
  { top: "70%", left: "55%", post: "CM" }, // Attacking Midfielder
  { top: "70%", left: "35%", post: "CM" }, // Forward 1
  { top: "100%", left: "45%", post: "ST" }, // Forward 2
];

export const formation_4213 = [
  { top: "13%", left: "45%", post: "GK" },  
  { top: "40%", left: "10%", post: "RB" },  
  { top: "30%", left: "30%", post: "CB" },
  { top: "30%", left: "60%", post: "CB" },
  { top: "40%", left: "80%", post: "LB" },
  { top: "50%", left: "28%", post: "CDM" },
  { top: "72%", left: "45%", post: "CAM" },
  { top: "100%", left: "45%", post: "ST" },
  { top: "50%", left: "62%", post: "CDM" },
  { top: "90%", left: "20%", post: "RW" },
  { top: "90%", left: "70%", post: "LW" },
];

export const formation_4222 = [
  { top: "13%", left: "45%", post: "GK" },  
  { top: "40%", left: "10%", post: "RB" },  
  { top: "30%", left: "30%", post: "CB" },
  { top: "30%", left: "60%", post: "CB" },
  { top: "40%", left: "80%", post: "LB" },
  { top: "50%", left: "35%", post: "CDM" },
  { top: "100%", left: "35%", post: "ST" },
  { top: "100%", left: "55%", post: "ST" },
  { top: "50%", left: "55%", post: "CDM" },
  { top: "70%", left: "18%", post: "CAM" },
  { top: "70%", left: "74%", post: "CAM" },
];

export const formation_4231 = [
  { top: "13%", left: "45%", post: "GK" },  
  { top: "40%", left: "10%", post: "RB" },  
  { top: "30%", left: "30%", post: "CB" },
  { top: "30%", left: "60%", post: "CB" },
  { top: "40%", left: "80%", post: "LB" },
  { top: "50%", left: "35%", post: "CDM" },
  { top: "100%", left: "45%", post: "ST" },
  { top: "70%", left: "45%", post: "CAM" },
  { top: "50%", left: "55%", post: "CDM" },
  { top: "70%", left: "20%", post: "CAM" },
  { top: "70%", left: "70%", post: "CAM" },
];


export const formation_4312 = [
  { top: "13%", left: "45%", post: "GK" },  
  { top: "40%", left: "10%", post: "RB" },  
  { top: "30%", left: "30%", post: "CB" },
  { top: "30%", left: "60%", post: "CB" },
  { top: "40%", left: "80%", post: "LB" },
  { top: "50%", left: "45%", post: "CDM" },
  { top: "100%", left: "30%", post: "ST" },
  { top: "100%", left: "60%", post: "ST" },
  { top: "55%", left: "65%", post: "CDM" },
  { top: "55%", left: "25%", post: "CDM" },
  { top: "75%", left: "45%", post: "CAM" },
];

export const formation_532 = [
  { top: "13%", left: "45%", post: "GK" },  
  { top: "35%", left: "10%", post: "RWB" },  
  { top: "25%", left: "25%", post: "CB" },
  { top: "25%", left: "65%", post: "CB" },
  { top: "35%", left: "80%", post: "LWB" },
  { top: "55%", left: "25%", post: "RM" },
  { top: "55%", left: "65%", post: "LM" },
  { top: "55%", left: "45%", post: "CM" },
  { top: "90%", left: "35%", post: "ST" },
  { top: "90%", left: "55%", post: "ST" },
  { top: "30%", left: "45%", post: "CB" },
];

export const formation_541 = [
  { top: "13%", left: "45%", post: "GK" },  
  { top: "35%", left: "10%", post: "RWB" },  
  { top: "25%", left: "25%", post: "CB" },
  { top: "25%", left: "65%", post: "CB" },
  { top: "35%", left: "80%", post: "LWB" },
  { top: "95%", left: "45%", post: "ST" },
  { top: "63%", left: "15%", post: "RM" },
  { top: "63%", left: "75%", post: "LM" },
  { top: "55%", left: "55%", post: "CM" },
  { top: "55%", left: "35%", post: "CM" },
  { top: "30%", left: "45%", post: "CB" },
];

export const formation_424 = [
  { top: "13%", left: "45%", post: "GK" },  
  { top: "40%", left: "10%", post: "RB" },  
  { top: "30%", left: "30%", post: "CB" },
  { top: "30%", left: "60%", post: "CB" },
  { top: "40%", left: "80%", post: "LB" },
  { top: "100%", left: "55%", post: "ST" },
  { top: "85%", left: "20%", post: "RW" },
  { top: "85%", left: "70%", post: "LW" },
  { top: "55%", left: "60%", post: "CM" },
  { top: "55%", left: "30%", post: "CM" },
  { top: "100%", left: "35%", post: "ST" },
];


export const formation_4321 = [
  { top: "13%", left: "45%", post: "GK" },  
  { top: "40%", left: "10%", post: "RB" },  
  { top: "30%", left: "30%", post: "CB" },
  { top: "30%", left: "60%", post: "CB" },
  { top: "40%", left: "80%", post: "LB" },
  { top: "55%", left: "45%", post: "CM" },
  { top: "90%", left: "30%", post: "ST" },
  { top: "90%", left: "60%", post: "ST" },
  { top: "55%", left: "65%", post: "CM" },
  { top: "55%", left: "25%", post: "CM" },
  { top: "100%", left: "45%", post: "ST" },
];

export const formation_433 = [
  { top: "13%", left: "45%", post: "GK" },  
  { top: "40%", left: "10%", post: "RB" },  
  { top: "30%", left: "30%", post: "CB" },
  { top: "30%", left: "60%", post: "CB" },
  { top: "40%", left: "80%", post: "LB" },
  { top: "55%", left: "45%", post: "CM" },
  { top: "85%", left: "15%", post: "RW" },
  { top: "85%", left: "75%", post: "LW" },
  { top: "55%", left: "65%", post: "CM" },
  { top: "55%", left: "25%", post: "CM" },
  { top: "100%", left: "45%", post: "ST" },
];

export const formation_442 = [
  { top: "13%", left: "45%", post: "GK" },  
  { top: "40%", left: "10%", post: "RB" },  
  { top: "30%", left: "30%", post: "CB" },
  { top: "30%", left: "60%", post: "CB" },
  { top: "40%", left: "80%", post: "LB" },
  { top: "100%", left: "55%", post: "ST" },
  { top: "65%", left: "15%", post: "RM" },
  { top: "65%", left: "75%", post: "LM" },
  { top: "55%", left: "55%", post: "CM" },
  { top: "55%", left: "35%", post: "CM" },
  { top: "100%", left: "35%", post: "ST" },
];

export const formation_451 = [
  { top: "13%", left: "45%", post: "GK" },  
  { top: "40%", left: "10%", post: "RB" },  
  { top: "30%", left: "30%", post: "CB" },
  { top: "30%", left: "60%", post: "CB" },
  { top: "40%", left: "80%", post: "LB" },
  { top: "95%", left: "45%", post: "ST" },
  { top: "63%", left: "15%", post: "RM" },
  { top: "63%", left: "75%", post: "LM" },
  { top: "63%", left: "60%", post: "CM" },
  { top: "63%", left: "30%", post: "CM" },
  { top: "50%", left: "45%", post: "CDM" },
];


export const formationA8_331 = [
  { top: "13%", left: "45%", post: "GK" }, // Goalkeeper
  { top: "35%", left: "20%", post: "CB" }, // Center Back 1
  { top: "35%", left: "45%", post: "CB" }, // Center Back 2
  { top: "35%", left: "70%", post: "CB" }, // Center Back 3
  { top: "70%", left: "20%", post: "RW" }, // CAM
  { top: "70%", left: "45%", post: "LW" }, // CAM
  { top: "70%", left: "70%", post: "RM" }, // Central Midfielder
  { top: "104%", left: "45%", post: "ST" }, // Forward 2
];

export const formationA8_313 = [
  { top: "13%", left: "45%", post: "GK" },
  { top: "40%", left: "20%", post: "CB" },
  { top: "35%", left: "45%", post: "CB" },
  { top: "40%", left: "70%", post: "CB" },
  { top: "90%", left: "20%", post: "RW" },
  { top: "100%", left: "45%", post: "LW" },
  { top: "90%", left: "70%", post: "RM" },
  { top: "70%", left: "45%", post: "ST" },
];

export const formationA8_232 = [
  { top: "13%", left: "45%", post: "GK" }, // Goalkeeper
  { top: "35%", left: "25%", post: "CB" }, // Center Back 1
  { top: "35%", left: "65%", post: "CB" }, // Center Back 2
  { top: "65%", left: "15%", post: "RW" }, // CAM
  { top: "65%", left: "45%", post: "LW" }, // CAM
  { top: "65%", left: "75%", post: "RM" }, // Central Midfielder
  { top: "100%", left: "30%", post: "ST" }, // Forward 1
  { top: "100%", left: "60%", post: "ST" }, // Forward 2
];

export const formationA8_322 = [
  { top: "13%", left: "45%", post: "GK" }, // Goalkeeper
  { top: "35%", left: "20%", post: "CB" }, // Center Back 1
  { top: "35%", left: "45%", post: "CB" }, // CB
  { top: "35%", left: "70%", post: "CB" }, // Center Back 2
  { top: "65%", left: "25%", post: "LW" }, // CAM
  { top: "65%", left: "65%", post: "RM" }, // Central Midfielder
  { top: "100%", left: "30%", post: "ST" }, // Forward 1
  { top: "100%", left: "60%", post: "ST" }, // Forward 2
];
export const formationA8_241 = [
  { top: "13%", left: "45%", post: "GK" }, // Goalkeeper
  { top: "35%", left: "25%", post: "CB" }, // Center Back 1
  { top: "35%", left: "65%", post: "CB" }, // Center Back 2

  { top: "70%", left: "10%", post: "RW" }, // Center Back 3
  { top: "60%", left: "30%", post: "CAM" }, // CAM
  { top: "60%", left: "55%", post: "CAM" }, // CAM
  { top: "70%", left: "80%", post: "RM" }, // Central Midfielder

  { top: "95%", left: "45%", post: "ST" }, // Forward 2
];
export const formatDate = (date, format = "full") => {
  if (!date||date=="Invalid date") return "-";

  const dt = new Date(date);

  const formatTwoDigits = (value) => (value < 10 ? "0" + value : value);

  switch (format) {
    case "full":
      const fullMonth = formatTwoDigits(dt.getMonth() + 1);
      const fullDay = formatTwoDigits(dt.getDate());
      return `${fullDay}/${fullMonth}/${dt.getFullYear()}`;

    case "year":
      return dt.getFullYear().toString();

    case "month":
      return formatTwoDigits(dt.getMonth() + 1).toString();

    case "dateTime":
      const dateTimeMonth = formatTwoDigits(dt.getMonth() + 1);
      const dateTimeDay = formatTwoDigits(dt.getDate());
      const hours = formatTwoDigits(dt.getHours());
      const minutes = formatTwoDigits(dt.getMinutes());
      const seconds = formatTwoDigits(dt.getSeconds());
      return `${dateTimeDay}/${dateTimeMonth}/${dt.getFullYear()} ${hours}:${minutes}`;

    default:
      const defaultMonth = formatTwoDigits(dt.getMonth() + 1);
      const defaultDay = formatTwoDigits(dt.getDate());
      return `${defaultDay}/${defaultMonth}/${dt.getFullYear()}`;
  }
};
export const formatDateByDayName = (matchDate) => {
  const langUsed = localStorage?.getItem("i18nextLng") || "en";

  const localeMap = {
    en: "en-GB",
    fr: "fr-GB",
    pt: "pt-GB",
  };

  const locale = localeMap[langUsed] || "en-GB";

  const formattedDate = matchDate.toLocaleDateString(locale, {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const formattedTime = matchDate.toLocaleTimeString(locale, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  return `${formattedDate}, ${formattedTime}`;
};
export function splitUrl(url) {
  const parts = url?.split("/") || url;
  const fileNameWithExtension = parts.pop();
  const fileNameParts = fileNameWithExtension.split(".");
  const fileName = fileNameParts[0];
  const extension = fileNameParts[1];
  return {
    path: parts.join("/"),
    fileName: fileName + "." + extension,
    extension: extension,
  };
}

import React from 'react';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import { useTranslation } from 'react-i18next';
import { handleExportMatchSheetExcel } from '../../controller/matchController';

const ExportMatchSheetExcelButton = ({ match }) => {
    const { t } = useTranslation();

    return <button
        className="group relative p-2 bg-white border border-green-200 rounded-lg shadow-sm transition-all duration-300 ease-in-out hover:shadow-md hover:border-green-300 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50"
        aria-label="View details"
        onClick={()=>handleExportMatchSheetExcel(match)}
    >
        <VerticalAlignBottomIcon className="w-5 h-5 text-green-500 group-hover:text-green-600 transition-colors duration-300" />
        <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            {t("EXPORT_MATCH_SHEET")}
        </span>
    </button>
};

export default ExportMatchSheetExcelButton;

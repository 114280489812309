import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { getCardsAndGoalStats } from '../../service/statisticService';
import SmallLoading from '../../../../components/Loadings/SmallLoading';
import NoDataFound from '../Layouts/NoDataFound';

export default function TeamStats({category}) {
    const { t } = useTranslation()
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getStats = async () => {
        try {
            const response = await getCardsAndGoalStats(category);
            setData(response.data)
        } catch (error) {
            console.log(error)
        }finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getStats();
    }, [category])

    return (
        <aside className="widget widget--sidebar card card--has-table widget-team-stats">
            <div className="widget__title card__header">
                <h4>{t('TEAM_STATS_TITLE')}</h4>
            </div>
            {loading ? <div className="widget__content card__content">
                <SmallLoading /></div> : !loading && data?.length == 0 ? <div className='h-20'>
                    <NoDataFound />
                </div> :
                <div className="widget__content card__content">
                    <ul className="team-stats-box">
                        <li className="team-stats__item team-stats__item--clean">
                            <div className="team-stats__icon team-stats__icon--circle">
                                <div className="team-stats__icon-primary"><i className="icon-svg icon-soccer-ball" /></div>
                            </div>
                            <div className="team-stats__value">{data?.goalsPerGame?.toFixed(2) ||"0.00"}</div>
                            <div className="team-stats__label">{t('GOALS_PER_GAME')}</div>
                        </li>
                        <li className="team-stats__item team-stats__item--clean">
                            <div className="team-stats__icon team-stats__icon--circle team-stats__icon--assists">
                                <div className="team-stats__icon-primary"><i className="icon-svg icon-soccer-ball" /></div><span className="team-stats__icon-secondary">A</span>
                            </div>
                            <div className="team-stats__value">{data?.assistsPerGame?.toFixed(2) ||"0.00"}</div>
                            <div className="team-stats__label">{t('GOAL_ASSISTS_PER_GAME')}</div>
                        </li>
                        <li className="team-stats__item team-stats__item--clean">
                            <div className="team-stats__icon team-stats__icon--circle">
                                <div className="team-stats__icon-primary"><i className="icon-svg icon-yellow-card" style={{ fontSize: "25px" }} /></div>
                            </div>
                            <div className="team-stats__value">{data?.YellowCardsPerGame?.toFixed(2) ||"0.00"}</div>
                            <div className="team-stats__label">{t('TOTAL_YELLOW_CARD_PER_GAME')}</div>
                        </li>
                        <li className="team-stats__item team-stats__item--clean">
                            <div className="team-stats__icon team-stats__icon--circle">
                                <div className="team-stats__icon-primary text-center"><i className="icon-svg icon-red-card" style={{ fontSize: "25px" }} /></div>
                            </div>
                            <div className="team-stats__value">{data?.redCardsPerGame?.toFixed(2)||"0.00"}</div>
                            <div className="team-stats__label">{t('TOTAL_RED_CARD_PER_GAME')}</div>
                        </li>
                    </ul>
                </div>}
        </aside>)
}

import axios from "axios";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
const REACT_APP_API_VERSION = process.env.REACT_APP_API_VERSION;

const axiosInstance = axios.create({
  baseURL: `${REACT_APP_API_URL}${REACT_APP_API_VERSION}`,
  headers: {
    "Content-Type": "application/json",
    Authorization:
      "Bearer " + localStorage.getItem("myteamConnectToken") || null,
  },
  withCredentials: true,
});

export default axiosInstance;

import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import "../../soccer/imports";
import { divisionsData } from '../../utils/selectesData';
import { AuthContext } from '../../../../contexts/AuthContext';
import { getClassementsTeams } from '../../service/matcheService';
import SmallLoading from '../../../../components/Loadings/SmallLoading';
import NoDataFound from '../../components/Layouts/NoDataFound';
import { MobileContext } from '../../context/isMobileContext';

export default function ClassementPage() {
  const { t } = useTranslation();
  const [divisions, SetDivisions] = useState("U17")
  const { user } = useContext(AuthContext)
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const isMobile = useContext(MobileContext)

  const fetchRank = async (getCategory) => {
    try {
      const response = await getClassementsTeams(getCategory)
      const rank = response.data;
      setTeams(rank?.map(club => (
        {
          rank: club?.rank,
          id: club?.equipe_id,
          name: club?.name,
          played: club?.matchesPlayed,
          win: club?.won,
          lose: club?.lost,
          drawn: club?.draw,
          goalsFor: club?.butMarque,
          goalsAgainst: club?.butRecu,
          goalsDiff: club?.butDifference,
          totalPoints: club?.points,
          imageUrl: club?.image,
        }
      )));

    } catch (error) {
      console.error("Error fetching rank:", error);
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    setLoading(true)
    fetchRank({
      category_divisions: divisions
    })
  }, [divisions])

  const calculateGoalsDiff = (goalsFor, goalsAgainst) => {
    return goalsFor - goalsAgainst;
  };

  const calculateTotalPoints = (win, drawn) => {
    return (win * 3) + drawn;
  };

  const calculatePointsDiff = (team) => {
    const maxPoints = Math.max(...teams.map(t => calculateTotalPoints(t.win, t.drawn)));
    return calculateTotalPoints(team.win, team.drawn) - maxPoints;
  };

  useEffect(() => {
    const updatedTeams = teams.map(team => ({
      ...team,
      goalsDiff: calculateGoalsDiff(team.goalsFor, team.goalsAgainst),
      totalPoints: calculateTotalPoints(team.win, team.drawn),
      pointsDiff: calculatePointsDiff(team)
    }));
    setTeams(updatedTeams);
  }, []);

  return (
    <div className="site-wrapper clearfix">
      <div className="site-content">
        <div className="container">
          <div className="card card--has-table">
            <div className="card__header">
              <h4>{t("CLASSEMENT")} 2024</h4>
              <div className={`flex flex-wrap items-center ${isMobile && 'justify-end'}`}>
                <label className="post-filter__label mx-2"> {t("FILTER_BY_CATEGORY_DIVISIONS")} : </label>
                <select
                  className="cs-select border border-spacing-2 p-2  cs-skin-border"
                  defaultValue={'U17'}
                  onChange={(e) => SetDivisions(e.target.value)}>
                  <option value={0} disabled="disabled">{t('COMPETITIVE_DIVISIONS')}</option>
                  {
                    divisionsData.map(division => (
                      ["U13", "U15", "U17"].includes(division?.value) &&
                      <option key={division.value} value={division.value}>{division.label}</option>
                    ))
                  }
                </select>
              </div>
            </div>
            <div className="card__content">
              <div className="table-responsive">
                {
                  loading ?
                    <div className='m-10'> <SmallLoading /></div> : teams?.length == 0 ? <div className='m-10'><NoDataFound /></div> :
                      <table className="table table-hover table-standings table-standings--full table-standings--full-soccer">
                        <thead>
                          <tr>
                            <th className="team-standings__pos">{t('POS')}</th>
                            <th className="team-standings__team">{t('TEAMS')}</th>
                            <th className="team-standings__played">{t('PLAYED')}</th>
                            <th className="team-standings__win">{t('WON')}</th>
                            <th className="team-standings__lose">{t('LOST')}</th>
                            <th className="team-standings__drawn">{t('DRAWN')}</th>
                            <th className="team-standings__goals-for">{t('GOALS_FOR')}</th>
                            <th className="team-standings__goals-against">{t('GOALS_AGAINST')}</th>
                            <th className="team-standings__goals-diff">{t('GOALS_DIFF')}</th>
                            <th className="team-standings__total-points">{t('TOTAL_POINTS')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {teams.map((team, index) => (
                            <tr key={team.id} className={user?.equipe_id == team.id ? 'bg-blue-400 text-white' : ""} >
                              <td className="team-standings__pos font-bold">{team.rank}</td>
                              <td className="team-standings__team">
                                <div className="team-meta">
                                  {team?.imageUrl && <img className="w-9 h-9 rounded-full object-cover ltr:mr-2 rtl:ml-2 object-cover" src={team.imageUrl} alt={team.name} />}
                                  <div className="team-meta__info">
                                    <h6 className="team-meta__name font-bold">{team.name}</h6>
                                  </div>
                                </div>
                              </td>
                              <td className="team-standings__played font-bold">{team.played}</td>
                              <td className="team-standings__win font-bold">{team.win}</td>
                              <td className="team-standings__lose font-bold">{team.lose}</td>
                              <td className="team-standings__drawn font-bold">{team.drawn}</td>
                              <td className="team-standings__goals-for font-bold">{team.goalsFor}</td>
                              <td className="team-standings__goals-against font-bold">{team.goalsAgainst}</td>
                              <td className="team-standings__goals-diff font-bold">{team.goalsDiff}</td>
                              <td className="team-standings__total-points font-bold">{team.totalPoints}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next';
import { getPointHistoryStats } from '../../../service/statisticService';
import SmallLoading from '../../../../../components/Loadings/SmallLoading';
import NoDataFound from '../../Layouts/NoDataFound';

export default function PointsHistory({ category }) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [optsData, setOptsData] = useState(null);

    const fetchOptions = async () => {
        try {
            const response = await getPointHistoryStats(category);
            setOptsData(response?.data || []);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOptions();
    }, [category]);

    const options = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985',
                },
            },
        },
        legend: {
            data: [t('MES_POINTS'), t('MIXED_CHART_AVERAGE_TOURNAMENT')],
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: optsData?.map(item => item.date),
            },
        ],
        yAxis: [
            {
                type: 'value',
            },
        ],
        series: [
            {
                name: t('MES_POINTS'),
                type: 'line',
                stack: 'Total',
                areaStyle: { normal: {} },
                data: optsData?.map(item => item.points),
                itemStyle: {
                    color: '#A2C579',
                },
            },
            {
                name: t('MIXED_CHART_AVERAGE_TOURNAMENT'),
                type: 'line',
                stack: 'Total',
                data: optsData?.map(item => item.averagePoints),
                itemStyle: {
                    color: '#4361ee',
                },
            },
        ],
    };

    return (
        <React.Fragment>
            <div className="card__header card__header--has-legend">
                <h4>{t('POINTS_HISTORY')}</h4>
                <div id="gamesPoinstsLegendSoccer" className="chart-legend" />
            </div>
            {console.log("loading : ", loading)}
            {loading ? <div className='card__content'><SmallLoading /></div> :
                !optsData ? <div className='h-20'>
                    <NoDataFound />
                </div> : <div className="card__content">
                    <ReactECharts option={options} style={{ height: 300 }} className="rounded-lg bg-white dark:bg-black overflow-hidden" />
                </div>}

        </React.Fragment>
    );
}

import { Avatar } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

export default function MatchScore({
  match,
  penaltyHomeScore,
  penaltyAwayScore,
  MatchWithPenaltys,
}) {
  const events = useSelector((state) => state.matchEventsReducer.events);

  const equipeAwayId = match?.match?.equipeAway?.id;
  const equipeHomeId = match?.match?.equipeHome?.id;

  const calculateScore = (teamId) => {
    const total = events
      .filter((event) => event.eventName === "goalScorer")
      .filter((event) => event.team?.id === teamId);
    return total.length;
  };

  const penaltysEvent = events?.filter(
    (evnt) => evnt?.eventName == "match_stats" && evnt?.status == "penalties"
  )[0]?.match_stats?.penalty_shootout_scores;

  const equipeAwayScore = calculateScore(equipeAwayId);
  const equipeHomeScore = calculateScore(equipeHomeId);

  return (
    <div className="alc-event-header alc-event-header--layout-2">
      <div className="alc-event-header__top">
        <div className="container flex justify-center">
          <div className="flex items-center">
            <img
              src={
                match?.match?.Competition?.image ||
                match?.competition?.logo ||
                match?.Competition?.image
              }
              className="rounded-full object-cover w-10 h-10"
            />
            <h6 className="title px-2 text-md">
              {match?.match?.Competition?.name ||
                match?.competition?.name ||
                match?.Competition?.name}
            </h6>

            <h2>
              (
              {`${
                match?.match?.category_divisions || match?.category_divisions
              } - ${match?.match?.round || match?.round}`}
              )
            </h2>
          </div>
        </div>
      </div>
      <div className="alc-event-header__content">
        <div className="container">
          <div className="alc-event-header__content-inner">
            <div className="alc-event-competitors">
              <div
                className={`alc-event-competitors__item alc-event-team ${
                  (match?.but_home > match?.but_away ||
                    match?.equipeHome?.scoore > match?.equipeAway?.scoore ||
                    equipeHomeScore > equipeAwayScore) &&
                  "alc-event-team--winner"
                }`}
              >
                <div className="alc-event-team__logo-alt">
                  <div className="alc-event-team__logo-alt-inner">
                    <img
                      src={
                        match?.match?.equipeHome?.image ||
                        match?.equipeHome?.logo ||
                        match?.equipeHome?.image
                      }
                      alt={
                        match?.match?.equipeHome?.name ||
                        match?.equipeHome?.name ||
                        match?.equipeHome?.name
                      }
                      className="alc-event-team__logo-alt-img"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
                <div className="alc-event-team__details">
                  <h4 className="alc-event-team__name">
                    {match?.match?.equipeHome?.name || match?.equipeHome?.name}
                  </h4>
                  <div className="alc-event-team__info">
                    {match?.match?.equipeHome?.slug || match?.equipeHome?.slug}
                  </div>
                </div>
                <figure className="alc-event-team__logo">
                  <img
                    src={
                      match?.match?.equipeHome?.image ||
                      match?.equipeHome?.logo ||
                      match?.equipeHome?.image
                    }
                    alt={
                      match?.match?.equipeHome?.name || match?.equipeHome?.name
                    }
                    className=" rounded-full object-cover w-40 h-40"
                  />
                </figure>
                <div className="alc-event-team__score-wrap">
                  <div className="alc-event-team__score">
                    {match?.equipeHome?.scoore ||
                      equipeHomeScore ||
                      match?.but_home ||
                      0}
                  </div>
                </div>
              </div>
              <div
                className={`alc-event-competitors__item alc-event-team ${
                  (match?.but_home < match?.but_away ||
                    match?.equipeHome?.scoore < match?.equipeAway?.scoore ||
                    equipeHomeScore < equipeAwayScore) &&
                  "alc-event-team--winner"
                }`}
              >
                <div className="alc-event-team__logo-alt alc-event-team__logo-alt--color-alt">
                  <div className="alc-event-team__logo-alt-inner">
                    <img
                      src={
                        match?.match?.equipeAway?.image ||
                        match?.equipeAway?.logo ||
                        match?.equipeAway?.image
                      }
                      alt={
                        match?.match?.equipeAway?.name ||
                        match?.equipeAway?.name
                      }
                      className="alc-event-team__logo-alt-img w-full"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </div>
                <div className="alc-event-team__details">
                  <h4 className="alc-event-team__name">
                    {match?.match?.equipeAway?.name || match?.equipeAway?.name}
                  </h4>
                  <div className="alc-event-team__info">
                    {match?.match?.equipeAway?.slug || match?.equipeAway?.slug}
                  </div>
                </div>
                <figure className="alc-event-team__logo">
                  <img
                    src={
                      match?.match?.equipeAway?.image ||
                      match?.equipeAway?.logo ||
                      match?.equipeAway?.image
                    }
                    alt={
                      match?.match?.equipeAway?.name || match?.equipeAway?.name
                    }
                    className=" rounded-full object-cover w-40 h-40"
                  />
                </figure>
                <div className="alc-event-team__score-wrap">
                  <div className="alc-event-team__score">
                    {match?.equipeAway?.scoore ||
                      equipeAwayScore ||
                      match?.but_away ||
                      0}
                  </div>
                </div>
              </div>
              <div className="alc-event-competitors__divider" />
            </div>
            {MatchWithPenaltys &&
            (penaltyAwayScore != 0 ||
              penaltyHomeScore != 0 ||
              penaltysEvent) ? (
              <div className="text-center">
                <h1 className="font-bold">
                  (
                  {penaltysEvent ? (
                    <span>{penaltysEvent[equipeHomeId] || 0}</span>
                  ) : (
                    <span>{penaltyHomeScore || 0}</span>
                  )}
                  {" - "}
                  {penaltysEvent ? (
                    <span>{penaltysEvent[equipeAwayId] || 0}</span>
                  ) : (
                    <span>{penaltyAwayScore || 0}</span>
                  )}
                  )
                </h1>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>

    // <div className="text-center items-center p-4">
    //   <div className="flex items-center justify-center space-x-2">
    //     <Avatar
    //       alt={match?.match?.equipeAway?.name}
    //       src={match?.match?.equipeAway?.image}
    //       className="w-10 h-10 rounded-full object-cover"
    //     />
    //     <span className="font-myteam font-bold text-gray-700 max-w-xs truncate">
    //       {match?.match?.equipeAway.slug}
    //     </span>
    //     <span className="mx-2 font-myteam font-bold">
    //       {equipeAwayScore} - {equipeHomeScore}
    //     </span>
    //     <span className="font-myteam font-bold text-gray-700 max-w-xs truncate">
    //       {match?.match?.equipeHome.slug}
    //     </span>
    //     <Avatar
    //       alt={match?.match?.equipeHome.name}
    //       src={match?.match?.equipeHome.image}
    //       className="w-10 h-10 rounded-full object-cover"
    //     />
    //   </div>
    // </div>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { formatDate } from '../utils/Utils';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import sortBy from 'lodash/sortBy';
import MultiColumn from '../components/DataTables/MultiColumn';
import { deleteValideLicence, getLicencesPlayer } from '../service/registrationService';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Check } from '@mui/icons-material';
import Select from 'react-select';
import { customOption, customOptionWithAll, divisionsData } from '../utils/selectesData';
import { getAllClubs } from '../service/matcheService';
import { useUserRole } from '../context/UserRoleContext';
import { AuthContext } from '../../../contexts/AuthContext';
import ModalEditPlayerImage from '../components/Modal/ModalEditPlayerImage';
import { MobileContext } from '../context/isMobileContext';
import ModalFormRegistration from '../components/Modal/ModalFormRegistration';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';


export default function PlayersLicences() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);
    const [originalRecords, setOriginalRecords] = useState([]);
    const [filteredRecords, setFilteredRecords] = useState([]);
    const [search, setSearch] = useState('');
    const [loadData, setLoadData] = useState(false);
    const [loading, setLoading] = useState(true);
    const [uniqueTeams, setUniqueTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [selectedDivsCategory, setSelectedDivsCategory] = useState(null);
    const { role } = useUserRole();
    const [openModalCreateRegistration, setOpenModalCreateRegistration] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [openModalEditImage, setOpenModalEditImage] = useState(false);
    const isMobile = useContext(MobileContext);

    const fetchClubs = async () => {
        try {
            const response = await getAllClubs();
            const clubs = response.data;

            const uniqueTeamsMap = new Map();
            clubs.equipe?.forEach(item => {
                if (!uniqueTeamsMap.has(item.name)) {
                    uniqueTeamsMap.set(item.name, { id: item.id.toString(), image: item.image });
                }
            });

            const uniqueTeams = Array.from(uniqueTeamsMap, ([name, { id, image }]) => ({
                value: id,
                label: name,
                logo: image
            }));

            setUniqueTeams([{ value: 'all', label: t('ALL') }, ...uniqueTeams]);
        } catch (error) {
            console.error("Error fetching clubs:", error);
        }
    };
    useEffect(() => {
        if (role?.toLowerCase() == "dtn") {
            fetchClubs()
        }
    }, []);

    const columns = [
        {
            accessor: 'category_divisions',
            title: t('CATEGORY'),
            sortable: false,
            render: ({ categoryDivisions }) => (
                <div className="flex items-center justify-center text-center font-bold text-black w-max">
                    <p>#{categoryDivisions}</p>
                </div>
            ),
        },
        {
            accessor: 'firstName',
            title: t('USER'),
            sortable: false,
            render: ({ firstName, lastName, picture, licence, user }) => (
                <div onClick={() => { setOpenModalEditImage(true); setSelectedPlayer(user) }} className="cursor-pointer">
                    <div className="flex items-center w-max">
                        <img className="w-9 h-9 rounded-full object-cover ltr:mr-2 rtl:ml-2 object-cover" src={picture} alt={lastName} />
                        <div>
                            <h2 className='font-bold text-black'>{firstName + ' ' + lastName}</h2>
                            <span className='badge bg-green-600 rounded-full object-cover'>
                                {licence}
                            </span>
                        </div>
                    </div>

                </div>
            ),
        },
        {
            accessor: 'birthday',
            title: t('BIRTHDAY'),
            sortable: false,
            render: ({ birthday }) => <div>{formatDate(birthday)}</div>
            ,
        },
        {
            accessor: 'equipe', title: t("TEAM"), render: ({ equipe }) =>
                <div className="flex items-center w-max" key={equipe?.slug || ""}>
                    <img className="w-9 h-9 rounded-full object-cover ltr:mr-2 rtl:ml-2 object-cover" src={equipe?.image} alt={equipe?.image} />
                    <div>{equipe?.name}</div>
                </div>
        },
        {
            accessor: 'status',
            title: t('STATUS'),
            sortable: false,
            render: ({ status }) => (
                <div className="flex items-center justify-center text-center w-max">
                    {status == "validee" && <Check className={`w-8 h-8 text-green-400`} />}
                </div>
            ),
        },
        {
            accessor: 'createdAt',
            title: t('CREATED_AT'),
            sortable: false,
            render: ({ createdAt }) => (
                <div className="flex items-center justify-center text-center w-max">
                    {formatDate(createdAt, 'dateTime')}
                </div>
            ),
        },
        {
            accessor: 'action',
            title: t('ACTION'),
            render: ({ user }) => (
                <div className="flex items-center space-x-3">
                    {
                        role?.toLowerCase() == "dtn" &&
                        <button
                            className="group relative p-2 bg-white border border-green-200 rounded-lg shadow-sm transition-all duration-300 ease-in-out hover:shadow-md hover:border-green-300 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50"
                            aria-label="Accept"
                            onClick={() => handleEdit(user)}
                        >
                            <BorderColorIcon className="w-5 h-5 text-green-500 group-hover:text-green-600 transition-colors duration-300" />
                            <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                {t("UPDATE")}
                            </span>
                        </button>}
                    <button
                        className="group relative p-2 bg-white border border-blue-200 rounded-lg shadow-sm transition-all duration-300 ease-in-out hover:shadow-md hover:border-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
                        aria-label="View details"
                        onClick={() => navigate("/dashboard/registration/details", { state: { playerData: user } })}
                    >
                        <VisibilityIcon className="w-5 h-5 text-blue-500 group-hover:text-blue-600 transition-colors duration-300" />
                        <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            {t("VIEW")}
                        </span>
                    </button>
                    {
                        role?.toLowerCase() == "dtn" &&
                        <button
                            className="group relative p-2 bg-white border border-red-200 rounded-lg shadow-sm transition-all duration-300 ease-in-out hover:shadow-md hover:border-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
                            onClick={() => showAlert(user?.licence?.id)}
                            aria-label="View details"
                        >
                            <DeleteIcon className="w-5 h-5 text-red-500 group-hover:text-red-600 transition-colors duration-300" />
                            <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                {t("DELETE")}
                            </span>
                        </button>
                    }
                </div>
            ),
        },
    ];

    const col = ['firstName', 'birthday', 'categoryDivisions', 'equipeName', 'status', 'createdAt'];
    const header = [t('USER'), t('BIRTHDAY'), t('CATEGORY_DIVISIONS'), t('TEAM'), t('STATUS'), t('CREATED_AT')];


    const showAlert = async (licenceId) => {
        Swal.fire({
            icon: 'warning',
            title: t('ARE_YOU_SURE'),
            text: t("YOU_WONT_BE_ABLE_TO_REVERT_THIS"),
            showCancelButton: true,
            confirmButtonText: t('DELETE'),
            cancelButtonText: t('CANCEL'),
            padding: '2em',
            customClass: 'sweet-alerts',
        }).then(async (result) => {
            if (result.value) {
                try {
                    await deleteValideLicence(licenceId);
                    setOriginalRecords((prvState) => {
                        return prvState.filter((item) => item.user?.licence_id != licenceId)
                    })
                    setFilteredRecords((prvState) => {
                        return prvState.filter((item) => item.user?.licence_id != licenceId)
                    })
                    Swal.fire({
                        title: t('DELETED'),
                        text: t('YOUR_FILE_HAS_BEEN_DELETED'),
                        icon: 'success',
                        customClass: 'sweet-alerts',
                    });
                } catch (error) {
                    console.error("Error deleting registration:", error);
                }
            }
        });
    };

    async function fetchRegistrations() {
        setLoadData(false)
        setLoading(true);
        try {
            const registrations = await getLicencesPlayer();
            // console.log("registrations : ",registrations)
            if (Array.isArray(registrations?.data?.result)) {
                const formattedData = registrations?.data?.result.map(reg => ({
                    id: reg.id,
                    firstName: reg.prenom,
                    lastName: reg.nom,
                    equipeId: reg.equipe_id,
                    email: reg?.licence.email,
                    birthday: reg?.licence.date_naissance,
                    picture: reg.licence.photoProfil,
                    equipe: reg.equipe,
                    equipeName: reg.equipe?.name,
                    status: reg?.licence.status,
                    action: reg.id,
                    user: reg,
                    categoryDivisions: reg?.licence?.categoryDivisions,
                    createdAt: reg.createdAt,
                    licence: reg?.licence?.licenceId
                }));
                const sortedData = sortBy(formattedData, 'createdAt').reverse();
                setOriginalRecords(sortedData);
                setFilteredRecords(sortedData);
            } else {
                console.error("Unexpected data format:", registrations);
            }
        } catch (error) {
            console.error("Error fetching registrations:", error);
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchRegistrations();
    }, [loadData]);

    useEffect(() => {
        const normalizedSearch = search.trim().toLowerCase();
        setFilteredRecords(() => {
            return originalRecords.filter((item) => {
                const normalizedFirstName = item?.firstName?.trim().toLowerCase();
                const normalizedLastName = item?.lastName?.trim().toLowerCase();
                const normalizedEmail = item?.email?.trim().toLowerCase();
                const searchTerms = normalizedSearch?.split(' ');
                return searchTerms.every(term =>
                    normalizedFirstName?.includes(term) ||
                    normalizedLastName?.includes(term) ||
                    normalizedEmail?.includes(term)
                );
            });
        });
    }, [search, originalRecords]);
    useEffect(() => {
        const newData = originalRecords.filter((item) => {
            const teamMatches = selectedTeam && selectedTeam?.value != "all" ? item?.equipeId == selectedTeam.value : true;
            const divisionMatches = selectedDivsCategory && selectedDivsCategory?.value !== "all" ? item?.categoryDivisions == selectedDivsCategory.value : true;
            return teamMatches && divisionMatches;
        });
        setFilteredRecords(newData);
    }, [selectedTeam, selectedDivsCategory, originalRecords]);

    const resetFilter = () => {
        setSelectedTeam(null)
        setSelectedDivsCategory(null)
    }

    const handleEdit = (user) => {
        setSelectedUser(user);
        setOpenModalCreateRegistration(true);
    };

    return (
        <div className={`bg-white shadow-md rounded-lg mt-6 ${isMobile ? "p-1" : "p-6"}`}>
            {
                (openModalEditImage) ? <ModalEditPlayerImage
                    SelectedPlayer={selectedPlayer}
                    modal20={openModalEditImage}
                    setModal20={setOpenModalEditImage}
                    setSelectedPlayer={setSelectedPlayer}
                    setLoadData={setLoadData}
                    setFilteredRecords={setFilteredRecords}
                /> : null
            }
            <div className="flex flex-col items-start">
                <div className="flex flex-wrap justify-end w-full items-center">
                    <div className='flex-auto justify-self-end'>
                        <h1 className={`font-bold mb-4 ${isMobile ? "text-center text-xl" : "text-2xl "}`}>{t('LIST_REGISRATION_ACCEPTED')}</h1>
                    </div>
                    <Select
                        placeholder={t("FILTER_BY_CATRGORY")}
                        options={divisionsData}
                        getOptionLabel={(option) => <span>{option.label == 'ALL' ? t(option?.label) : option.label}</span>}
                        className="p-2 rounded-md w-64 shadow-sm focus:z-9999 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
                        value={selectedDivsCategory}
                        components={{
                            Option: ({ data, innerRef, innerProps }) => customOptionWithAll({ data, innerRef, innerProps, t })
                        }}
                        onChange={(selectedOption) => setSelectedDivsCategory(selectedOption)}
                    />

                    {role == "dtn" && <> <Select
                        placeholder={t("FILTER_BY_TEAM")}
                        options={uniqueTeams}
                        className="p-2 rounded-md w-64 shadow-sm focus:z-9999 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-transparent"
                        value={selectedTeam}
                        onChange={(selectedOption) => setSelectedTeam(selectedOption)}
                        components={{ Option: customOption }}
                    />
                    </>}
                    <div className="flex items-center justify-end md:justify-end">
                        <button type="button" className="btn btn-primary" onClick={() => resetFilter()}>
                            {t("RESET_FILTER")}
                        </button>
                    </div>
                </div>
            </div>
            <MultiColumn
                search={search}
                PDF_TITLE={t('LIST_REGISRATION_ACCEPTED')}
                USER_NAME={`${user?.nom} ${user?.prenom}`}
                setSearch={setSearch}
                setInitialRecords={setFilteredRecords}
                initialRecords={filteredRecords}
                rowData={filteredRecords}
                columns={columns}
                enableExport={true}
                col={col}
                header={header}
                loading={loading} />
            {
                openModalCreateRegistration ? <ModalFormRegistration
                    modal20={openModalCreateRegistration}
                    setModal20={setOpenModalCreateRegistration}
                    defaultValues={selectedUser}
                    setLoadData={setLoadData}
                    setDefaultValues={setSelectedUser}
                    isForUpdate={true}
                /> : null
            }
        </div>
    )
}


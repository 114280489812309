import React from "react";
import MatchScoore from "../../layouts/MatchScoore";
import ArbitreSection from "../ArbitreSection";
import { useSelector } from "react-redux";

export default function Rencontre({
  match,
  t,
  equipeAwayScoore,
  equipeHomeScoore,
  setActiveTab,
}) {
  const events = useSelector((state) => state.matchEventsReducer.events);
    
  return (
    <div className=" mx-50 p-6 bg-gray-100 rounded-lg shadow-md">
      <div className="flex justify-center mb-4">
        <ArbitreSection match={match} t={t} />
      </div>
      <div className="border border-gray-300 p-6 bg-white rounded-lg mb-4">
        <h1 className="text-center font-bold font-myteam text-3xl text-gray-800 mb-4">
          {t("FINAL_SCORE")}
        </h1>
        <MatchScoore
          match={match}
          equipeAwayScoore={equipeAwayScoore}
          equipeHomeScoore={equipeHomeScoore}
          MatchWithPenaltys={events?.some(
            (evnt) =>
              evnt?.eventName == "match_stats" && evnt?.status == "penalties"
          )}
          t={t}
        />
      </div>
      <div className="border border-gray-300 p-6 bg-white rounded-lg">
        <button
          onClick={() => setActiveTab(2)}
          className="w-full bg-blue-500 hover:bg-blue-700 text-white font-myteam py-2 rounded-md transition duration-300"
        >
          {t("MORE_DETAILS")}
        </button>
      </div>
    </div>
  );
}

// src/i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import languages from '../locales/LanguagesSupport';

const languageDetector = new LanguageDetector();

languageDetector.addDetector({
  name: 'customLocalStorageDetector',
  lookup() {
    return localStorage.getItem('i18nextLng');
  },
  cacheUserLanguage(lng) {
    localStorage.setItem('i18nextLng', lng);
  },
});

const resources = languages.reduce((acc, lang) => {
  acc[lang.code] = { translation: lang.translation };
  return acc;
}, {});

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'fr',
    debug: true,
    resources,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false, 
    },
    detection: {
      order: ['customLocalStorageDetector', 'path', 'navigator'],
      caches: ['customLocalStorageDetector'],
    },
  });

export default i18n;
import React from 'react'
import Formation from '../../../../components/LineUp/Formation'
import { useTranslation } from 'react-i18next'
import NoDataFound from '../Layouts/NoDataFound';

export default function LineUps(props) {
    const { t } = useTranslation()

    const teamHome = props.match.equipeHome;
    const teamAway = props.match.equipeAway;
    const teamHomeEvents = teamHome.id == props?.events[0].equipe_id ? props?.events[0] : props?.events[1];
    const teamAwayEvents = teamAway.id == props?.events[0].equipe_id ? props?.events[0] : props?.events[1];

    return (
        <div className='my-5'>
            <div className="flex justify-center">
                <div className="col-6 w-1/2 mx-1 h-full">
                    <div className="widget card widget--sidebar widget-lineup">
                        <div className="widget__title card__header">
                            <div className='flex items-center'>
                                <img src={teamHome?.image} alt={`${teamHome?.name} `} className="w-8 h-8 mx-2 rounded-full object-cover" />
                                <h4 className='font-bold'> {teamHome?.name}</h4>
                            </div>
                            <h4>{teamHomeEvents?.formation || ''}</h4>
                        </div>
                        {
                            teamHomeEvents ?
                                <>
                                    <div className="widget__content card__content flex items-start">
                                        <div className='w-1/2'>
                                            <h2 className='font-bold m-2'>
                                                {t('SUBSTITUTE_PLAYERS')}
                                            </h2>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="lineup__num">{t("NBR")}</th>
                                                        <th className="lineup__pos">{t("POS")}</th>
                                                        <th className="lineup__name">{t("FULL_NAME")}</th>
                                                        <th className="lineup__info" />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        teamHomeEvents.ListReplacment?.map(player => (
                                                            <tr key={player.id}>
                                                                <td className="lineup__num">{player?.shirtNmbr || "-"}</td>
                                                                <td className="lineup__pos">{player?.post || "-"}</td>
                                                                <td className="lineup__name flex items-center">
                                                                    <img src={player.image} alt={`${player.nom} ${player.prenom}`} className="w-8 h-8 mx-2 rounded-full object-cover" />
                                                                    {player.nom + " " + player.prenom}
                                                                </td>
                                                                <td className="lineup__info">
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="w-1/2 h-[600px]">
                                            <div className='h-[350px]'>
                                                <Formation
                                                    players={teamHomeEvents.ListPlayer}
                                                    showFilterOptions={false}
                                                    defaultFormation={
                                                        teamHomeEvents.formation
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </> : (
                                    <NoDataFound/>
                                )
                        }
                    </div>
                </div>
                <div className="col-6 w-1/2 mx-1">

                    <div className="widget card widget--sidebar widget-lineup">
                        <div className="widget__title card__header">
                            <div className='flex items-center'>
                                <img src={teamAway?.image} alt={`${teamAway?.name} `} className="w-8 h-8 mx-2 rounded-full object-cover" />
                                <h4 className='font-bold'> {teamAway?.name}</h4>
                            </div>
                            <h4>{teamAwayEvents.formation || ''}</h4>
                        </div>
                        {
                            teamAwayEvents ?
                            <div className="widget__content card__content flex items-start">
                                <div className="w-1/2 h-[600px]">
                                    <div className='h-[350px]'>
                                        <Formation
                                            players={teamAwayEvents.ListPlayer}
                                            showFilterOptions={false}
                                            defaultFormation={
                                                teamAwayEvents.formation
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='w-1/2'>
                                    <h2 className='font-bold m-2'>
                                        {t('SUBSTITUTE_PLAYERS')}
                                    </h2>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="lineup__num">{t("NBR")}</th>
                                                <th className="lineup__pos">{t("POS")}</th>
                                                <th className="lineup__name">{t("FULL_NAME")}</th>
                                                <th className="lineup__info" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                teamAwayEvents.ListReplacment?.map(player => (
                                                    <tr key={player.id}>
                                                        <td className="lineup__num">{player?.shirtNmbr || "-"}</td>
                                                        <td className="lineup__pos">{player?.post || "-"}</td>
                                                        <td className="lineup__name flex items-center">
                                                            <img src={player.image} alt={`${player.nom} ${player.prenom}`} className="w-8 h-8 mx-2 rounded-full object-cover" />
                                                            {player.nom + " " + player.prenom}
                                                        </td>
                                                        <td className="lineup__info">
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>:
                            <NoDataFound/>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

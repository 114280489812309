import React, { useContext, useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import ErrorCode from "../components/Errors/ErrorCode";
import PrepareMatchPage from "../pages/PrepareMatchPage";
import FuilleMatchPage from "../pages/FuilleMatchPage";
import ReservesPage from "../pages/ReservesPage";
import MatchEventsPage from "../pages/MatchEventsPage";
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/LoginPage";
import { useSelector } from "react-redux";
import { AuthContext } from "../contexts/AuthContext";
import RoleBasedRoute from "./RoleBasedRoute";
import ProtectedRoute from "../dashboard/src/utils/ProtectedRoute";
import NotfoundPage from "../components/Errors/pages/NotfoundPage";
import MatchEventsConfirmationPage from "../pages/MatchEventsConfirmationPage";

export default function FrontOfficeRoutes() {
  const error = useSelector((state) => state.matchReducer.error);
  const { logout } = useContext(AuthContext);

  useEffect(() => {
    if (error && error == "Error: Unauthorized") {
      logout();
    }
  }, [error]);

  return (
    <Routes>
      <Route path="*" element={<NotfoundPage />} />
      <Route
        element={
          <RoleBasedRoute
            allowedRoles={["arbitre", "owner"]}
            redirectPath="/dashboard"
          />
        }
      >
        <Route path="/matches" element={<HomePage />} />
      </Route>
      <Route path="/" element={<Navigate to={"/matches"} />} />
      <Route
        path="/match/prepare"
        element={
          <ProtectedRoute
            allowedRoles={["owner", "arbitre", "club"]}
            element={PrepareMatchPage}
          />
        }
      />
      <Route
        path="/match/waiting-confirmation"
        element={
          <ProtectedRoute
            allowedRoles={["owner", "arbitre", "club"]}
            element={MatchEventsConfirmationPage}
          />
        }
      />
      <Route
        path="/match/fuille"
        element={
          <ProtectedRoute
            allowedRoles={["owner", "arbitre", "club"]}
            element={FuilleMatchPage}
          />
        }
      />
      <Route
        path="/match/reserve"
        element={
          <ProtectedRoute
            allowedRoles={["owner", "arbitre", "club"]}
            element={ReservesPage}
          />
        }
      />
      <Route
        path="/match/events"
        element={
          <ProtectedRoute
            allowedRoles={["owner", "arbitre", "club"]}
            element={MatchEventsPage}
          />
        }
      />
      <Route path="/login" element={<LoginPage />} />
    </Routes>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';

export default function DispalySignateur({sign, team }) {
    const {t}=useTranslation()
    const date = new Date(sign?.createdAt);

    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date
        .getHours()
        .toString()
        .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;

    return (
        <div className="flex flex-col items-center bg-white p-6 border rounded-lg shadow-md max-w-sm mx-auto">
            <div className="flex items-center mb-4">
                <img
                    src={team?.image}
                    alt={team?.name||team?.slug}
                    className="w-10 h-10 object-contain mr-4"
                />
                <h2 className="text-xl font-bold text-gray-800">{team?.name || team?.slug}</h2>
            </div>
            <img
                src={sign?.sign}
                alt="Signature"
                className="max-w-full h-auto mb-4"
            />
            <p className="text-sm text-gray-600">
                {t('DATE_SIGNATEUR')}: {formattedDate}
            </p>
        </div>
    );
}
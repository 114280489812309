import * as React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";
import { Alert, AlertTitle } from "@mui/material";
import { useTranslation } from "react-i18next";
import NoDataFound from "../../dashboard/src/components/Layouts/NoDataFound";

const StickyHeadTable = ({
  columns,
  rows,
  loading,
  initialRowsPerPage,
  headerLabelsColor,
  headerColor,
  error,
  onRowClick,
  cursor,
  textAlign,
  rowActive,
  ReloadTable,
  setReloadTable,
}) => {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    initialRowsPerPage || 10
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  React.useEffect(() => {
    if (ReloadTable) {
      handleChangePage("",0)
      setReloadTable(false);
    }
    //setReloadTable(false);
  }, [ReloadTable]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const rowsArray = Array.isArray(rows) ? rows : [];

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer
        sx={{ maxHeight: 540 }}
        style={{
          textAlign: "center",
        }}
      >
        {error && (
          <Alert severity="error">
            <AlertTitle className="font-myteam">
              {t("MESSAGES_ERROR.ERROR")}
            </AlertTitle>
            {error}
          </Alert>
        )}
        <Table
          stickyHeader
          aria-label="sticky table"
          style={{
            textAlign: "center",
          }}
        >
          <TableHead
            style={{
              zIndex: 0,
            }}
          >
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  className="font-myteam"
                  align={textAlign}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: headerColor ? headerColor : "",
                    color: headerLabelsColor ? headerLabelsColor : "",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : rowsArray?.length == 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <NoDataFound />
                </TableCell>
              </TableRow>
            ) : (
              rowsArray
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, rowIndex) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={rowIndex}
                    className={"font-myteam" + cursor ? cursor : ""}
                    onClick={() => (onRowClick ? onRowClick(row) : "")}
                    style={
                      row?.playerSelected
                        ? {
                            backgroundColor: "#87CBB9",
                            color: "white",
                            fontWeight: "bold",
                          }
                        : // : rowActive == 2 &&
                          //   (row.isTitulaire || row.isTitulaire == 1)
                          // ? {backgroundColor: "#87CBB9", color: "white"}
                          {}
                    }
                  >
                    {columns.map((column, colIndex) => (
                      <TableCell key={colIndex} align={textAlign}>
                        {typeof column.format === "function"
                          ? column.format(row[column.id], row)
                          : row[column.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rowsArray.length}
        rowsPerPage={rowsPerPage}
        page={page}
        className="font-myteam"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

StickyHeadTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      minWidth: PropTypes.number,
      align: PropTypes.oneOf(["right", "left", "center"]),
      format: PropTypes.func,
    })
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool.isRequired,
  initialRowsPerPage: PropTypes.number,
  headerLabelsColor: PropTypes.string,
  headerColor: PropTypes.string,
  error: PropTypes.string,
  onRowClick: PropTypes.func.isRequired,
};

export default StickyHeadTable;

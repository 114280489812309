import React from 'react';
import { useTranslation } from 'react-i18next';

export default function NoDataFound() {
    const { t } = useTranslation()
    return (
        <div className="flex flex-col items-center justify-center h-full">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-24 h-24 text-gray-400 mb-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 8v4l3 3m6-3a9 9 0 11-6.14-8.36M12 4.5v.01"
                />
            </svg>
            <p className="text-gray-600 text-lg">{t("NO_DATA_FOUND")}</p>
        </div>
    );
}

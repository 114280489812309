import { matchSheetExcel } from "../service/matcheService";

const handleExportMatchSheetExcel = async (match) => {
    try {
        const response = await matchSheetExcel(match?.id);
        const contentDisposition = response.headers['content-disposition'];
        let fileName = `MatchSheet_${match?.equipeHome?.name}_${match?.equipeAway?.name}_${match?.category_divisions}_${match?.round}.xlsx`
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch.length === 2) {
            fileName = fileNameMatch[1];
          }
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    } catch (error) {
        console.error('Error exporting Excel file', error);
    }
};

export {
    handleExportMatchSheetExcel,
}
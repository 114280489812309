import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ErrorCode from '../ErrorCode';

export default function NotfoundPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const goBack = () => {
        navigate("/");
    };

    const url = location.pathname;
    return (
        <ErrorCode
            code="404"
            title={t('MESSAGES_ERROR.PAGE_NOT_FOUND_TITLE')}
            message={t('MESSAGES_ERROR.PAGE_NOT_FOUND_MESSAGE', { url })}
            callback={goBack}
            btnText={t('BACK')}
            btnStyle={{
                backgroundColor: '#007bff',
                color: '#fff',
                padding: '10px 20px',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                fontSize: '16px',
                transition: 'background-color 0.3s ease',
            }}
        />
    );
}

import React, { Suspense, useContext, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './tailwind.css';
import DefaultLayout from './components/Layouts/DefaultLayout';
import DtnPage from './pages/DtnPage';
import DashboardPage from './pages/DashboardPage';
import MatchesPage from './pages/MatchesPage';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/material_blue.css';
import '@mantine/core/styles.layer.css';
import 'mantine-datatable/styles.layer.css';
import RegistrationPage from './pages/RegistrationPage';
import 'file-upload-with-preview/dist/file-upload-with-preview.min.css';
import ProfilePage from './pages/ProfilePage';
import UnauthorizedPage from '../../components/Errors/pages/UnauthorizedPage';
import ProtectedRoute from './utils/ProtectedRoute';
import { UserRoleProvider } from './context/UserRoleContext';
import MatchesClubList from './pages/MatchesClubList';
import PlayersLicences from './pages/PlayersLicences';
import { useSelector } from 'react-redux';
import { AuthContext } from '../../contexts/AuthContext';
import ClassementPage from './pages/ClassementTeams/ClassementPage';
import MatchDetails from './components/Layouts/MatchDetails';

export default function BackOfficeMain() { 
    const error = useSelector((state) => state.matchReducer.error);
    const { logout } = useContext(AuthContext);
  
    useEffect(() => {
      if (error && error == "Error: Unauthorized") {
        logout();
      }
    }, [error]);

    return (
        <UserRoleProvider>
            <Suspense>
                <DefaultLayout>
                    <Routes>
                        <Route path="/" element={<Navigate to="statistics" />} />
                        <Route path="/statistics" element={<ProtectedRoute allowedRoles={['owner', "dtn", 'club']} element={DashboardPage} />} />
                        <Route path="/list-registration-requests" element={<ProtectedRoute allowedRoles={['owner', "dtn"]} element={DtnPage} />} />
                        <Route path="/list-matches" element={<ProtectedRoute allowedRoles={['owner', 'dtn']} element={MatchesPage} />} />
                        <Route path="/registration" element={<ProtectedRoute allowedRoles={['owner', 'club']} element={RegistrationPage} />} />
                        <Route path="/list-accepted-registrations" element={<ProtectedRoute allowedRoles={['owner', 'club',"dtn"]} element={PlayersLicences} />} />
                        <Route path="/matches" element={<ProtectedRoute allowedRoles={['owner', 'club']} element={MatchesClubList} />} />
                        <Route path="/classement" element={<ProtectedRoute allowedRoles={['owner', 'dtn','club']} element={ClassementPage} />} />
                        <Route path="/registration/details" element={<ProtectedRoute allowedRoles={["owner", 'club', 'dtn']} element={ProfilePage} />} />
                        <Route path="/match/details" element={<ProtectedRoute allowedRoles={["owner", 'club', 'dtn']} element={MatchDetails} />} />
                    </Routes>
                </DefaultLayout>
            </Suspense>
        </UserRoleProvider>
    );
}
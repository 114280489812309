import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const ProtectedRoute = ({ element: Component, allowedRoles, ...rest }) => {
  const [role, setRole] = useState("");
  useEffect(() => {
    const token = localStorage.getItem("myteamConnectToken");
    if (token) {
      const decodedToken = jwtDecode(token);
      setRole(decodedToken?.role);
    }
  }, []);

  if (!role) {
    return <p>Loading ...</p>;
  }

  return allowedRoles.includes(role) ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/unauthorized" />
  );
};

export default ProtectedRoute;

import * as React from "react";
const IconPlayerIn = (props) => (
  <svg
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 8.5 13"
    style={{
      enableBackground: "new 0 0 8.5 13",
      width: props.w,
    }}
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path
        fill="#1CBA85"
        d="M6.2,4.8c-0.3,0-0.6,0-0.9,0c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6,0.2-0.7,0.5C4.4,5.4,4.4,5.5,4.4,5.7 c0,0.5,0,0.9,0,1.4c0,0.7,0,1.5,0,2.2c0,0.3-0.1,0.7-0.3,1c-0.3,0.5-0.7,0.9-1.2,1.2c-0.1,0.1-0.2,0.1-0.4,0.1 c0.2-0.3,0.4-0.5,0.5-0.8c0.2-0.5,0.3-0.9,0.4-1.4c0-0.2,0-0.4,0-0.7c0-1.3,0-2.6,0-3.9c0-0.1,0-0.1-0.1-0.1c-0.6,0-1.2,0-1.7,0 c-0.1,0-0.2,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2c0.8-1,1.6-2,2.4-3c0,0,0.1-0.1,0.1-0.1c0,0,0,0.1,0,0.1c0.4,0.5,0.8,1,1.2,1.6 c0.4,0.5,0.8,1,1.1,1.5C6.4,4.6,6.4,4.6,6.2,4.8z"
      />
      <path
        fill="#149262"
        d="M2.6,11.6c0.1,0,0.2-0.1,0.4-0.1c0.5-0.3,0.9-0.7,1.2-1.2c0.1-0.3,0.2-0.6,0.3-1c0.1-0.7,0-1.5,0-2.2 c0-0.5,0-0.9,0-1.4c0-0.2,0-0.3,0.1-0.5c0.1-0.3,0.4-0.4,0.7-0.5c0.1,0,0.1,0,0.2,0c0,0.5,0,1,0,1.5c0,0.9,0,1.9,0,2.8 c0,0.6-0.2,1.1-0.5,1.6c-0.4,0.6-1,0.9-1.7,1.1c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.2,0-0.2-0.1C2.5,11.8,2.5,11.7,2.6,11.6 C2.5,11.7,2.6,11.7,2.6,11.6L2.6,11.6z"
      />
      <path
        fill="#149262"
        d="M6.2,4.8c0.1-0.1,0.1-0.1,0-0.3c-0.4-0.5-0.8-1-1.1-1.5C4.8,2.4,4.4,1.9,4,1.4c0,0,0-0.1,0-0.1 C4,1.1,4.1,1,4.3,0.9c0.1-0.2,0.2-0.2,0.4,0C5,1.4,5.4,1.9,5.8,2.5c0.5,0.7,1,1.3,1.5,2c0,0.1,0.1,0.1,0.1,0.2 c0,0.1-0.1,0.1-0.2,0.1C6.8,4.8,6.5,4.8,6.2,4.8z"
      />
    </g>
  </svg>
);
export default IconPlayerIn;

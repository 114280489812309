import {
  CalendarMonth,
  MapSharp,
} from "@mui/icons-material";
import React from "react";
import TopicIcon from '@mui/icons-material/Topic';

export default function MatchSelected({ match, t }) {
  return (
    <div className="bg-white p-6 max-w-md mx-auto my-2">
      <div className="flex w-auto items-center text-center justify-center text-sm text-gray-600">
        <InfoItem
          icon={<TopicIcon size={16} />}
          text={`${match?.match.category_divisions} - ${match?.round}`}
        />
      </div>
      <div className="flex justify-between items-center">
        <TeamInfo team={match?.match?.equipeHome} isHome={true} />
        <div className="text-2xl font-bold text-gray-700">{t("VS")}</div>
        <TeamInfo team={match?.match?.equipeAway} />
      </div>
      <div className="border-t border-gray-200 pt-4">
        <MatchInfo match={match?.match} />
      </div>
    </div>
  );
}

const TeamInfo = ({ team, isHome = false }) => (
  <div className={`flex flex-col items-center`}>
    <img
      src={team?.image}
      alt={team?.name}
      className="w-16 h-16"
    />
    <span className="font-bold text-gray-800 text-sm max-w-[120px] truncate text-center">
      {team?.name}
    </span>
  </div>
);

const MatchInfo = ({ match }) => (
  <div className="flex items-center justify-between space-y-2 text-sm text-gray-600">
    <InfoItem
      icon={<CalendarMonth size={16} />}
      text={`${match?.match_date} ${match?.match_time}`}
    />
    <InfoItem icon={<MapSharp size={16} />} text={match?.location} />
  </div>
);

const InfoItem = ({ icon, text, className = "" }) => (
  <div className={`flex items-center space-x-2 ${className}`}>
    {icon}
    <span>{text}</span>
  </div>
);

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MantineProvider } from '@mantine/core';
import { toggleLayout, toggleMenu, toggleNavbar } from '../../redux/actions/backOfficeActions';

function App({ children }) {
    const themeConfig = useSelector((state) => state.themeConfig);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(toggleMenu(localStorage.getItem('menu') || themeConfig.menu));
        dispatch(toggleLayout(localStorage.getItem('layout') || themeConfig.layout));
        dispatch(toggleNavbar(localStorage.getItem('navbar') || themeConfig.navbar));
    }, [dispatch, themeConfig.menu, themeConfig.layout, themeConfig.navbar]);

    return (
        <MantineProvider>
            <div className={`${(themeConfig.sidebar && 'toggle-sidebar') || ''} ${themeConfig.menu} ${themeConfig.layout} ${themeConfig.rtlClass} main-section antialiased relative font-myteam text-sm font-normal`}>
                {children}
            </div>
        </MantineProvider>
    );
}

export default App;

import {
  createReservesTemplate,
  fetchReservesTemplate,
} from "../../api/services/matchReservesService";

export const getReservesTeamplate = async (category, type) => {
  try {
    const res = await fetchReservesTemplate(category, type);
    return res?.templates;
  } catch (error) {
    throw new Error("Failed to fetch reserves template");
  }
};

export const createReservesTeamplate = async (payload) => {
  try {
    const res = await createReservesTemplate(payload);
    return res;
  } catch (error) {
    throw new Error("Failed to create reserve");
  }
};
